import { ColumnConfig, Popup, Scheduler } from '@bryntum/scheduler';
import { BryntumScheduler } from '@bryntum/scheduler-react';
import '@bryntum/scheduler/scheduler.material.css';
import { faArrowCircleRight, faEyeSlash, faFilter, faPlusCircle, faSearch, faSms, faSync, faTimes, faTrash, faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, Input, InputAdornment, LinearProgress, Tooltip } from '@mui/material';
import { DatePicker, DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { IntlProvider, load, loadMessages, LocalizationProvider } from '@progress/kendo-react-intl';
import { AxiosResponse } from 'axios';
import clsx from 'clsx';
import { debounce, each, find, groupBy, map, orderBy, remove, uniq } from 'lodash';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ReactDOM from 'react-dom';
import { renderToString } from 'react-dom/server';
import { FieldDescriptor } from 'src/shared/components/TextSearchFieldsSelector/models/FieldDescriptor';
import { TextSearchFieldsSelectorComponent } from 'src/shared/components/TextSearchFieldsSelector/TextSearchFieldsSelectorComponent';
import { PagedResult } from 'src/shared/models/PagedResult';
import { WebAppActionResult, WebAppActionResultEx } from 'src/shared/models/WebAppActionResult';
import VehicleGroupCountersComponent from 'src/shared/TransportIndicators/components/VehicleGroupCountersComponent';
import { VehicleGroupCountersLightModel } from 'src/shared/TransportIndicators/services/dataContracts/queryStack/VehicleGroupCountersLightModel';
import { TransportIndicatorsApiClient } from 'src/shared/TransportIndicators/services/TransportIndicatorsApiClient';
import { InternalVehiclesChoiceLightModel } from 'src/shared/VehiclesSelector/services/dataContracts/queryStack/InternalVehiclesChoiceLightModel';
import { TransportPurchasePriceDatesLightModel } from 'src/shared/VehiclesSelector/services/dataContracts/queryStack/TransportPurchasePriceDatesLightModel';
import { VehiclesSelectorDrawerView } from 'src/shared/VehiclesSelector/VehiclesSelectorDrawerView';
import ToastService from 'src/ToastService';
import { SettingsProvider } from '../../SettingsProvider';
import { ScaleLoaderComponent } from '../../shared/components/Common/ScaleLoaderComponent';
import SimpleDialog from '../../shared/components/Common/SimpleDialog';
import { CancelDeliveryTripDialogComponent } from '../../shared/components/DeliveryTripsCancellation/CancelDeliveryTripDialogComponent';
import { PlanningVehicleStatusComponent } from '../../shared/components/PlanningVehicleStatus/PlanningVehicleStatusComponent';
import { DeliveryTripCancellationReason } from '../../shared/models/DeliveryTripCancellationReason';
import { DeliveryTripStatus } from '../../shared/models/DeliveryTripStatus';
import { LogisticsUnitChoice } from '../../shared/models/LogisticsUnitChoice';
import BusinessErrors from '../../utils/BusinessErrors';
import BusinessWarnings from '../../utils/BusinessWarnings';
import '../../utils/Date';
import { AppModule, LocalStorage, SessionStorage } from '../../utils/Storage';
import Utilities from '../../utils/Utilities';
import { TransportFlowHeaderComponent } from '../TransportFlow/components/TransportFlowHeaderComponent';
import { creationMode, duplicateMode, TransportFlowForm, updateMode } from '../TransportFlow/TransportFlowForm';
import { AddVehicleComponent } from './components/AddVehicleComponent';
import { AttachZephyrFlowManuallyComponent } from './components/AttachZephyrFlowManuallyComponent';
import { CancelPlanningVehicleDialogComponent } from './components/CancelPlanningVehicleDialogComponent';
import { DriverSmsNotificationsDialogComponent } from './components/DriverSmsNotificationsDialogComponent';
import { DuplicateVehicleComponent } from './components/DuplicateVehicleComponent';
import ExpeditionScheduler from './components/ExpeditionScheduler';
import { FlowManagementDrawerComponent } from './components/FlowManagementDrawerComponent';
import { getCostsArePublishedOrderValue, PlanningVehicleCosts } from './components/PlanningVehicleCosts';
import { SmsNotificationsDialogComponent } from './components/SmsNotificationsDialogComponent';
import { TransportMarginsComponent } from './components/TransportMarginsComponent';
import { TripDetailPopupComponent } from './components/TripDetailPopupComponent';
import { UpdateSpecificPriceOfDeliveryTripsComponent } from './components/UpdateSpecificPriceOfDeliveryTripsComponent';
import { DeliveryTripLongIdentifierRequestArgsExtended } from './models/DeliveryTripLongIdentifierRequestArgsExtended';
import { DriverNotificationModel } from './models/DriverNotificationModel';
import { EventModelData } from './models/EventModelData';
import { MarginsModel } from './models/MarginsModel';
import { PlanningVehicleTripsLightModelExtended } from './models/PlanningVehicleTripsLightModelExtended';
import { PriceKind } from './models/PriceKind';
import { PurchaseCostUsedQuantitiesKindEnum } from './models/PurchaseCostUsedQuantitiesKindEnum';
import { SortDescriptor } from './models/SortDescriptor';
import { TransportFlowLightModelExtended } from './models/TransportFlowLightModelExtended';
import { TripDragToNewLoadingTimeRequestArgsExtended } from './models/TripDragToNewLoadingTimeRequestArgsExtended';
import { TripsMoveToNewPlanningVehicleRequestArgsExtended } from './models/TripsMoveToNewPlanningVehicleRequestArgsExtended';
import './OperationalMonitoringStyles.scss';
import { AssignContractualPricedTransporterVehicleTypeRequestArgs } from './services/dataContracts/controller/AssignContractualPricedTransporterVehicleTypeRequestArgs';
import { AssignDailyPlanningVehicleRequestArgs } from './services/dataContracts/controller/AssignDailyPlanningVehicleRequestArgs';
import { AssignDeliveryTripsStatusArgs } from './services/dataContracts/controller/AssignDeliveryTripsStatusArgs';
import { AssignGenericVehicleTypeRequestArgs } from './services/dataContracts/controller/AssignGenericVehicleTypeRequestArgs';
import { AssignInternalVehicleRequestArgs } from './services/dataContracts/controller/AssignInternalVehicleRequestArgs';
import { AssignTransporterVehicleTypeRequestArgs } from './services/dataContracts/controller/AssignTransporterVehicleTypeRequestArgs';
import { CancelPlanningVehicleRequestArgs } from './services/dataContracts/controller/CancelPlanningVehicleRequestArgs';
import { DeliveryTripChangeRequestArgs } from './services/dataContracts/controller/DeliveryTripChangeRequestArgs';
import { DuplicatePlanningVehicleRequestArgs } from './services/dataContracts/controller/DuplicatePlanningVehicleRequestArgs';
import { ReinstatePlanningVehicleRequestArgs } from './services/dataContracts/controller/ReinstatePlanningVehicleRequestArgs';
import { RemoveDeliveryTripsArgs } from './services/dataContracts/controller/RemoveDeliveryTripsArgs';
import { RemoveSpecificPriceFromDeliveryTripsRequestArgs } from './services/dataContracts/controller/RemoveSpecificPriceFromDeliveryTripsRequestArgs';
import { SendDriverFreeSmsRequestArgs } from './services/dataContracts/controller/SendDriverFreeSmsRequestArgs';
import { SendSmsNotificationsRequestArgs } from './services/dataContracts/controller/SendSmsNotificationsRequestArgs';
import { SendSmsNotificationsRequestArgsItem } from './services/dataContracts/controller/SendSmsNotificationsRequestArgsItem';
import { TextSearchField } from './services/dataContracts/controller/TextSearchField';
import { TripDragToNewLoadingTimeRequestArgs } from './services/dataContracts/controller/TripDragToNewLoadingTimeRequestArgs';
import { TripMoveToNewPlanningVehicleRequestArgs } from './services/dataContracts/controller/TripMoveToNewPlanningVehicleRequestArgs';
import { TripResizeToNewDeliveryTimeRequestArgs } from './services/dataContracts/controller/TripResizeToNewDeliveryTimeRequestArgs';
import { TripsMoveToNewPlanningVehicleRequestArgs } from './services/dataContracts/controller/TripsMoveToNewPlanningVehicleRequestArgs';
import { UpdateSpecificPriceOfDeliveryTripsRequestArgs } from './services/dataContracts/controller/UpdateSpecificPriceOfDeliveryTripsRequestArgs';
import { CancellationReason } from './services/dataContracts/queryStack/CancellationReason';
import { DriverSmsNotification } from './services/dataContracts/queryStack/DriverSmsNotification';
import { PlanningVehicleTripsLightModel } from './services/dataContracts/queryStack/PlanningVehicleTripsLightModel';
import { PurchasePriceLightModel } from './services/dataContracts/queryStack/PurchasePriceLightModel';
import { TripLightModel } from './services/dataContracts/queryStack/TripLightModel';
import { VehicleTypeLightModel } from './services/dataContracts/queryStack/VehicleTypeLightModel';
import { OperationalMonitoringApiClient } from './services/OperationalMonitoringApiClient';

const weekData = require('cldr-core/supplemental/weekData.json');
const caGregorian = require('cldr-dates-full/main/fr/ca-gregorian.json');
const dateFields = require('cldr-dates-full/main/fr/dateFields.json');
const timeZoneNames = require('cldr-dates-full/main/fr/timeZoneNames.json');
const esMessages = require('../../config/fr.json');
loadMessages(esMessages, 'fr-FR');

load(
    weekData,
    caGregorian,
    dateFields,
    timeZoneNames
);

interface OrderedColumnConfig {
    columnConfig: any,
    orderIndex: number
}

interface OrderedField {
    field: string,
    orderIndex: number
}

enum actionType {
    moveTripsWithSpecificPrice = "moveTripsWithSpecificPrice",
    dropWithSpecificPrice = "dropWithSpecificPrice",
    dropRemove = "dropRemove",
    moveTripRemove = "moveTripRemove",
    deleteTrips = "delete",
    deleteTripsHideFlow = "deleteHideFlow"
}

enum TransportServiceKind {
    delivery = "Delivery",
    removal = "Removal",
    jobsiteVehicle = "JobsiteVehicle",
}

interface IStateFragment {

}

interface ExpeditionsStateFragment extends IStateFragment {
    events: EventModelData[],
    resources: PlanningVehicleTripsLightModelExtended[],
    eventsVersion: number,
    resourcesWithDriversAndMobilePhoneNumber: PlanningVehicleTripsLightModelExtended[],
    existsResourcesToReceiveSmsNotification: boolean,
    existsEmptyVehicles: boolean,
    activePage: number,
    totalItems: number,
    lastTimeStampGetExpeditions: number,
    isSmsNotificationDialogOpened: boolean,
    refreshToOpenSmsModal: boolean,
    needGrouping: boolean,
    transportMargins: MarginsModel,
    deliveryTripsSelected: Array<EventModelData>,
    sorters: SortDescriptor[],
    scrollLeftSaved: number
}

interface VehicleGroupCountersStateFragment extends IStateFragment {
    vehicleGroupCounters: VehicleGroupCountersLightModel,
    lastTimeStampGetVehicleGroupCounters: number
}

interface VehicleTypesStateFragment extends IStateFragment {
    vehicleTypes: Array<VehicleTypeLightModel>
}

interface TransportFLowsStateFragment extends IStateFragment {
    isFlowManagementDrawerOpened: boolean,
    transportFlowList: Array<TransportFlowLightModelExtended>,
    isNegativeFilterActived: boolean,
    hasNegativeFlows: boolean,
    isNegativeRemainingQtyFilterSelected: boolean
}

interface OperationalMonitoringViewState extends ExpeditionsStateFragment, VehicleGroupCountersStateFragment, TransportFLowsStateFragment, VehicleTypesStateFragment {
    loadingDataScheduler: boolean,
    pageLength: number,
    pageNumber: number,
    searchText: string,
    flowSearchtext: string,
    date: Date,
    isVehiclesSelectorOpen: boolean,
    addedDaysNumberChoice: number,
    byVehicleTypeGroupIdsFilterValue: string[],
    byVehicleTypeGroupIdsFilterChanged: boolean,
    selectedVehicle: PlanningVehicleTripsLightModelExtended,
    getUnusedReservationsChanged: boolean,
    isDialogCreatePlanningVehicleOpened: boolean,
    isDialogDuplicatePlanningVehicleOpened: boolean,
    isDialogUpdateSpecificPriceOpened: boolean,
    deliveryTripsSelectedToUpdateSpecificPricesHasSpecificOrNegotiatedPriceOverriden: boolean,
    dialogTitle: string,
    disableCreatePlanningVehicles: boolean,
    disableDuplicatePlanningVehicles: boolean,
    vehicleTypes: VehicleTypeLightModel[],
    showConfirmationRemoveTripsModal: boolean,
    showConfirmationRemoveAllPlanningVehicleTripsModal: boolean,
    showConfirmationSchedulerTripsAction: boolean,
    showConfirmationRemoveEmptyPlanningVehiclesModal: boolean,
    isTransportFlowFormViewOpened: boolean,
    flowDialogMode: string,
    flowDialogTransportFlowId: string,
    transportFlowBusinessId: string,
    scrollLeftSaved: number,
    isFilterSelected: boolean,
    selectedFlow: string,
    refreshDailyVehicles: boolean,
    refreshContractualVehicles: boolean,
    showConfirmationPlanningVehicleModal: boolean,
    confirmationMessage: string[],
    planningVehicleIdToRemove: string,
    planningIdToRemove: number,
    actionToBeConfirmed: string,
    planningVehicleHasOrderLine: boolean,
    planningVehicleHasAtLeastOneDeliveryTrip: boolean,
    vehicleId: number,
    selectedVehicleTypeId: string,
    selectedVehicleTypeGroupId: string,
    transporterId: string,
    equipmentId: string,
    licencePlate: string
    driverId: number,
    internalVehiclesChoicesLightModel: InternalVehiclesChoiceLightModel[]
    transportPuchasesPriceDatesLightModel: TransportPurchasePriceDatesLightModel[]
    dailyPlanningVehicleId: string,
    contractualPurchasePriceId: number,
    removeTripsMode: string,
    pendingTripsToDragToNewLoadingTime: Array<TripDragToNewLoadingTimeRequestArgsExtended>,
    pendingTripsToMoveToNewPlanningVehicle: Array<TripsMoveToNewPlanningVehicleRequestArgsExtended>,
    action: string,
    collapsedSenderSiteGroup: Set<string>,
    collapsedReceiverSiteGroup: Set<string>,
    collapsedBeneficiariesGroup: Set<string>,
    filtersSelected: Array<TextSearchField>,
    lastTimeStampFilteredSelected: number,
    isSummaryOfSentSmsMessagesModalOpened: boolean,
    numberOfFailedSendingSms: number,
    numberOfSuccessfullySendingSms: number,
    sendingSmsLoading: boolean,
    isDriverSmsNotificationsDialogOpened: boolean,
    driverSmsPhoneNumberIsMobile: boolean,
    driverSmsNotifications: DriverSmsNotification[],
    selectedDriverName: string,
    sendDriverFreeSmsRequestArgs: SendDriverFreeSmsRequestArgs,
    isCancelPlanningVehicleDialogOpened: boolean,
    isCancelDeliveryTripsDialogOpened: boolean,
    isAttachZephyrFlowManuallyOpened: boolean,
    planningVehicleIdToCancel: string,
    existenceOfInProgressOrFinishedTripHasBeenConfirmed: boolean,
    showConfirmationCancelingPlanningVehicleAction: boolean,
    isConfirmValidationExistingSpecificOrNegotiatedPriceDialogOpened: boolean,
    specificPriceToUpdate: number,
    specificPriceKindToUpdate: string,
    actionUpdateSpecificPricesPending: boolean
}

interface OperationalMonitoringViewProperties {
    role: string,
    userName: string,
    userDisplayName: string,
    userPhoneNumber: string,
    logisticsUnits: Array<LogisticsUnitChoice>,
    logisticsUnitIds: Array<string>
}

const CollapsedGroupKeyPrefix = 'expeditions_collapsed';
const SenderSitesGroupName = 'senderSites';
const ReceiverSitesGroupName = 'receiverSites';
const BeneficiariesGroupName = 'beneficiaries';
const DefaultGroupName = 'default';
const GroupingNameStorageKey = 'expeditions_GroupingLabel';
const LabelSelectionStorageKey = 'expeditions_labelSelection';
const PlanningVehiclesGridOrderIndexes = 'planningVehiclesGrid_orderIndexes';
const PlanningVehiclesGridWidth = 'planningVehiclesGrid_width';
const resizableAtTheEnd = 'end';

enum ConfirmableAction {
    RemovePlanningVehicle = "RemovePlanningVehicle",
    AssignGenericVehicleType = "AssignGenericVehicleType",
    AssignTransporterVehicleType = "AssignTransporterVehicleType",
    AssignInternalVehicle = "AssignInternalVehicle",
    AssignDailyPlanningVehicle = "AssignDailyPlanningVehicle",
    AssignContractualPricedTransporterVehicle = "AssignContractualPricedTransporterVehicle"
}

enum PlanningVehiclesGridField {
    isNightWork = 'isNightWork',
    confirmationStatusOrderValue = 'confirmationStatusOrderValue',
    label = 'label',
    type = 'type',
    contractNumber = 'contractNumber',
    vehicleNumber = 'vehicleNumber',
    transporter = 'transporter',
    totalCosts = 'totalCosts',
    sellPrice = 'sellPrice',
    grossMargin = 'grossMargin',
    driverFullName = 'driverFullName',
    driverPhoneNumber = 'driverPhoneNumber',
    driverSmsIcon = 'driverSmsIcon',
    occupancyRate = 'occupancyRate',
    planningVehicleRemarks = 'planningVehicleRemarks',
    loadUtilizationRate = 'loadUtilizationRate',
    costsArePublishedOrderValue = 'costsArePublishedOrderValue'
}
export const PrefixesOfDatedLocalStorageKeys = [`${CollapsedGroupKeyPrefix}.${SenderSitesGroupName}.`, `${CollapsedGroupKeyPrefix}.${ReceiverSitesGroupName}.`, `${CollapsedGroupKeyPrefix}.${BeneficiariesGroupName}.`];

const ModuleKey = AppModule.OperationalMonitoring;

export class OperationalMonitoringView extends React.Component<OperationalMonitoringViewProperties, OperationalMonitoringViewState> {
    _isMounted: boolean;
    defaultValue: Date = SessionStorage.ActiveStartDate;
    selectedValue = "tripNumber";
    groupingName: string = DefaultGroupName;
    inputSearchTextRef: React.RefObject<HTMLInputElement>;
    inputSearchFlowRef: React.RefObject<HTMLInputElement>;
    schedulerRef: React.RefObject<BryntumScheduler>;
    datePickerRef: React.RefObject<DatePicker>;
    showHiddenFlows = false;
    planningVehiclesGridWidth: number;
    planningVehiclesGridColumns: Array<any>;

    EXPEDITIONS_CONTENT_DIV = 'expeditions-content';
    availablePurchasePrices: Array<PurchasePriceLightModel>;
    isLogisticianUser = (this.props.role === "LOG" || this.props.role === 'ADM');
    subGridConfigs = null;

    filterOptions: Array<FieldDescriptor<TextSearchField>> = [
        { label: 'N° Flux', value: TextSearchField.transportFlowBusinessId },
        { label: 'N° commande', value: TextSearchField.zephyrOrderNumber },
        { label: 'Bénéficiaire', value: TextSearchField.beneficiaryName },
        { label: 'Payeur Flux', value: TextSearchField.transportFlowPayer },
        { label: 'Site départ', value: TextSearchField.senderSite },
        { label: 'Site arrivée', value: TextSearchField.receiverSite },
        { label: 'Agence départ', value: TextSearchField.senderAgency },
        { label: 'Agence arrivée', value: TextSearchField.receiverAgency },
        { label: 'CP départ', value: TextSearchField.pickupAddressZipCode },
        { label: 'CP arrivée', value: TextSearchField.deliveryAddressZipCode },
        { label: 'Ville départ', value: TextSearchField.pickupAddressCity },
        { label: 'Ville arrivée', value: TextSearchField.deliveryAddressCity },
        { label: 'Produit/chargement', value: TextSearchField.product },
        { label: 'Transporteur', value: TextSearchField.transporterLabel },
        { label: 'Type de camion', value: TextSearchField.expectedVehicleType },
        { label: 'Immatriculation camion', value: TextSearchField.licencePlate },
        { label: 'Chauffeur', value: TextSearchField.driverFullName },
        { label: 'Commentaire', value: TextSearchField.planningVehicleRemarks }
    ];

    //Par défaut le gantt est positionné à 6h00
    static readonly TimelineDefaultScrollLeft = 600;
    static readonly defaultPlanningVehicleGridWidth = 480;

    constructor(props: OperationalMonitoringViewProperties) {
        super(props);
        this.inputSearchTextRef = React.createRef();
        this.inputSearchFlowRef = React.createRef();
        this.schedulerRef = React.createRef<BryntumScheduler>();
        this.datePickerRef = React.createRef();
        const currentNumericDate = this.defaultValue.toNumericDate();
        const planningVehicleGridWidth = LocalStorage.GetItem(ModuleKey, PlanningVehiclesGridWidth);
        this.planningVehiclesGridWidth = planningVehicleGridWidth ? Number(planningVehicleGridWidth) : OperationalMonitoringView.defaultPlanningVehicleGridWidth;
        this.subGridConfigs = { locked: { width: this.planningVehiclesGridWidth } };

        this.state = {
            events: [],
            resources: [],
            eventsVersion: 0,
            resourcesWithDriversAndMobilePhoneNumber: [],
            existsResourcesToReceiveSmsNotification: false,
            existsEmptyVehicles: false,
            activePage: 1,
            pageLength: 10000,
            pageNumber: 0,
            totalItems: 1,
            searchText: '',
            flowSearchtext: '',
            date: this.defaultValue,
            isVehiclesSelectorOpen: false,
            isFlowManagementDrawerOpened: false,
            vehicleGroupCounters: { missingX4Count: 0, missingX4GrueCount: 0, missingX6Count: 0, missingX6GrueCount: 0, missingX8Count: 0, missingX8GrueCount: 0, missingSemiCount: 0, missingOthersTypeCount: 0, x4Count: 0, x4GrueCount: 0, x6Count: 0, x6GrueCount: 0, x8Count: 0, x8GrueCount: 0, semiCount: 0, othersTypeCount: 0 },
            lastTimeStampGetExpeditions: Date.now(),
            lastTimeStampGetVehicleGroupCounters: Date.now(),
            transportFlowList: [],
            addedDaysNumberChoice: 0,
            byVehicleTypeGroupIdsFilterValue: null,
            byVehicleTypeGroupIdsFilterChanged: false,
            selectedVehicle: null,
            getUnusedReservationsChanged: false,
            transportMargins: {
                overheadExpenses: 0,
                grossMargin: 0,
                netMargin: 0,
                averageLoadUtilizationRate: 0,
            },
            isDialogCreatePlanningVehicleOpened: false,
            isDialogDuplicatePlanningVehicleOpened: false,
            isDialogUpdateSpecificPriceOpened: false,
            deliveryTripsSelectedToUpdateSpecificPricesHasSpecificOrNegotiatedPriceOverriden: false,
            dialogTitle: '',
            disableCreatePlanningVehicles: false,
            disableDuplicatePlanningVehicles: false,
            vehicleTypes: [],
            deliveryTripsSelected: [],
            showConfirmationRemoveTripsModal: false,
            showConfirmationRemoveAllPlanningVehicleTripsModal: false,
            showConfirmationRemoveEmptyPlanningVehiclesModal: false,
            showConfirmationSchedulerTripsAction: false,
            isTransportFlowFormViewOpened: false,
            flowDialogMode: "",
            flowDialogTransportFlowId: null,
            transportFlowBusinessId: null,
            scrollLeftSaved: OperationalMonitoringView.TimelineDefaultScrollLeft,
            loadingDataScheduler: false,
            isFilterSelected: false,
            selectedFlow: null,
            refreshDailyVehicles: false,
            refreshContractualVehicles: false,
            showConfirmationPlanningVehicleModal: false,
            planningVehicleIdToRemove: null,
            planningIdToRemove: null,
            confirmationMessage: [],
            actionToBeConfirmed: null,
            planningVehicleHasOrderLine: false,
            planningVehicleHasAtLeastOneDeliveryTrip: false,
            vehicleId: null,
            selectedVehicleTypeId: null,
            selectedVehicleTypeGroupId: null,
            transporterId: null,
            equipmentId: null,
            licencePlate: null,
            driverId: null,
            internalVehiclesChoicesLightModel: null,
            transportPuchasesPriceDatesLightModel: null,
            dailyPlanningVehicleId: null,
            contractualPurchasePriceId: null,
            removeTripsMode: null,
            pendingTripsToDragToNewLoadingTime: [],
            pendingTripsToMoveToNewPlanningVehicle: [],
            action: null,
            isNegativeFilterActived: false,
            hasNegativeFlows: false,
            isNegativeRemainingQtyFilterSelected: false,
            collapsedSenderSiteGroup: this.getCollapsedSiteGroup(currentNumericDate, SenderSitesGroupName),
            collapsedReceiverSiteGroup: this.getCollapsedSiteGroup(currentNumericDate, ReceiverSitesGroupName),
            collapsedBeneficiariesGroup: this.getCollapsedSiteGroup(currentNumericDate, BeneficiariesGroupName),
            filtersSelected: this.filterOptions.map(x => x.value),
            lastTimeStampFilteredSelected: Date.now(),
            isSmsNotificationDialogOpened: false,
            refreshToOpenSmsModal: false,
            isSummaryOfSentSmsMessagesModalOpened: false,
            numberOfFailedSendingSms: 0,
            numberOfSuccessfullySendingSms: 0,
            sendingSmsLoading: false,
            isDriverSmsNotificationsDialogOpened: false,
            driverSmsPhoneNumberIsMobile: false,
            driverSmsNotifications: [],
            selectedDriverName: '',
            sendDriverFreeSmsRequestArgs: null,
            needGrouping: true,
            sorters: [],
            isCancelPlanningVehicleDialogOpened: false,
            isCancelDeliveryTripsDialogOpened: false,
            isAttachZephyrFlowManuallyOpened: false,
            planningVehicleIdToCancel: null,
            existenceOfInProgressOrFinishedTripHasBeenConfirmed: false,
            showConfirmationCancelingPlanningVehicleAction: false,
            isConfirmValidationExistingSpecificOrNegotiatedPriceDialogOpened: false,
            specificPriceToUpdate: 0,
            specificPriceKindToUpdate: "",
            actionUpdateSpecificPricesPending: false
        };

        const storedLabelSelection = LocalStorage.GetItem(ModuleKey, LabelSelectionStorageKey);
        if (storedLabelSelection != null)
            this.selectedValue = storedLabelSelection;

        const storedGroupingName = LocalStorage.GetItem(ModuleKey, GroupingNameStorageKey);
        if (storedGroupingName != null)
            this.groupingName = storedGroupingName;

        const planningVehiclesGrid = LocalStorage.GetItem(ModuleKey, PlanningVehiclesGridOrderIndexes);
        const planningVehiclesGridParsed: Map<string, OrderedField> = planningVehiclesGrid ? JSON.parse(planningVehiclesGrid) : new Map<string, OrderedField>();

        this.planningVehiclesGridColumns = this.BuildColumns(planningVehiclesGridParsed);
    }

    BuildColumns = (columns: Map<string, OrderedField>): Array<any> => {
        const mapColumns: Map<string, OrderedColumnConfig> = new Map<string, OrderedColumnConfig>([
            [PlanningVehiclesGridField.isNightWork, {
                orderIndex: 0,
                columnConfig: {
                    text: 'J/N',
                    field: PlanningVehiclesGridField.isNightWork,
                    width: 50,
                    minWidth: 50,
                    editor: false,
                    htmlEncode: false,
                    renderer: ({ record }) => {
                        return record.data?.isNightWork == true ? `<div class='night-work'></div>`
                            : record.data?.isNightWork == false ? `<div class='day-work'></div>` : '';
                    }
                }
            }],
            [PlanningVehiclesGridField.confirmationStatusOrderValue, {
                orderIndex: 1,
                columnConfig: {
                    text: 'Statut',
                    field: PlanningVehiclesGridField.confirmationStatusOrderValue,
                    width: 60,
                    minWidth: 60,
                    editor: false,
                    htmlEncode: false,
                    renderer: ({ record }) => {
                        const data = record.data;
                        return <PlanningVehicleStatusComponent
                            isCanceled={data.isCanceled}
                            transporterConfirmationDate={data.transporterConfirmationDate}
                            transporterOrderId={data.transporterOrderId}
                            transporterIsInternal={data.transporterIsInternal}
                            cancellationReason={data.cancellationReason}
                            cancellationRemarks={data.cancellationRemarks}
                            purchaseIsCanceled={data.purchaseIsCanceled}
                            saleIsCanceled={data.saleIsCanceled}
                            cancellationOrderSentDate={data.cancellationOrderSentDate}
                            cancellationOrderConfirmationDate={data.cancellationOrderConfirmationDate}
                        />
                    }
                }
            }],
            [PlanningVehiclesGridField.label, {
                orderIndex: 2,
                columnConfig: {
                    text: 'Camion',
                    field: PlanningVehiclesGridField.label,
                    width: 75,
                    editor: false
                }
            }],
            [PlanningVehiclesGridField.type, {
                orderIndex: 3,
                columnConfig: {
                    text: 'Type',
                    field: PlanningVehiclesGridField.type,
                    width: 125,
                    editor: false
                }
            }],
            [PlanningVehiclesGridField.contractNumber, {
                orderIndex: 4,
                columnConfig: {
                    text: 'PAC',
                    field: PlanningVehiclesGridField.contractNumber,
                    width: 50,
                    editor: false
                }
            }],
            [PlanningVehiclesGridField.vehicleNumber, {
                orderIndex: 5,
                columnConfig: {
                    text: 'N°',
                    field: PlanningVehiclesGridField.vehicleNumber,
                    width: 40,
                    minWidth: 40,
                    editor: false
                }
            }],
            [PlanningVehiclesGridField.transporter, {
                orderIndex: 6,
                columnConfig: {
                    text: 'Transporteur',
                    field: PlanningVehiclesGridField.transporter,
                    width: 200,
                    editor: false
                }
            }],
            [PlanningVehiclesGridField.totalCosts, {
                orderIndex: 7,
                columnConfig: {
                    type: 'template',
                    text: 'Coût total',
                    field: PlanningVehiclesGridField.totalCosts,
                    width: 70,
                    editor: false,
                    hidden: this.isLogisticianUser ? false : true,
                    template: ({ record }) => {
                        const classVehicle = `quantities-used-${!record.data.kindOfValuesAccounted
                            ? "old"
                            : record.data.kindOfValuesAccounted === PurchaseCostUsedQuantitiesKindEnum.MixOfPlannedAndDelivered
                                ? "mixed"
                                : "planned"}`;
                        return `<div class=${classVehicle}>${record.data.totalCosts?.toCurrencyString()}</div>`;
                    }
                }
            }],
            [PlanningVehiclesGridField.sellPrice, {
                orderIndex: 8,
                columnConfig: {
                    type: 'template',
                    text: 'Vente planif.',
                    field: PlanningVehiclesGridField.sellPrice,
                    width: 70,
                    editor: false,
                    hidden: this.isLogisticianUser ? false : true,
                    template: ({ record }) => {
                        const classVehicle = record.data?.transporterId ? 'vehicle-transporter' : 'vehicle-generic';
                        return `<div class='${classVehicle}'>${record.data?.sellPrice ? record.data?.sellPrice.toCurrencyString() : ''}</div>`;
                    }
                }
            }],
            [PlanningVehiclesGridField.grossMargin, {
                orderIndex: 9,
                columnConfig: {
                    type: 'template',
                    text: 'Marge brute',
                    field: PlanningVehiclesGridField.grossMargin,
                    width: 70,
                    editor: false,
                    hidden: this.isLogisticianUser ? false : true,
                    template: ({ record }) => {
                        const classVehicle = record.data?.grossMargin < 0 ? 'value-negatif-marge' : '';
                        return `<div class='${classVehicle}'>${record.data?.grossMargin ? record.data?.grossMargin.toCurrencyString() : ''}</div>`;
                    }
                }
            }],
            [PlanningVehiclesGridField.driverFullName, {
                orderIndex: 10,
                columnConfig: {
                    text: 'Chauffeur',
                    field: PlanningVehiclesGridField.driverFullName,
                    width: 200,
                    editor: false
                }
            }],
            [PlanningVehiclesGridField.driverPhoneNumber, {
                orderIndex: 11,
                columnConfig: {
                    text: 'Tel chauffeur',
                    field: PlanningVehiclesGridField.driverPhoneNumber,
                    width: 85,
                    minWidth: 85,
                    editor: false,
                    htmlEncode: false,
                    renderer: ({ record }) => {
                        return record.data?.driverPhoneNumber == null ? "" : `<span class="phone-number-ref"><a href='callTo:${Utilities.formatPhoneNumber(record.data?.driverPhoneNumber)}' data-rel="external">${Utilities.formatPhoneNumber(record.data?.driverPhoneNumber)}</a></span>`
                    }
                }
            }],
            [PlanningVehiclesGridField.driverSmsIcon, {
                orderIndex: 12,
                columnConfig: {
                    type: 'action',
                    text: 'SMS',
                    field: PlanningVehiclesGridField.driverSmsIcon,
                    width: 50,
                    minWidth: 50,
                    hidden: this.isLogisticianUser ? false : true,
                    actions: [{
                        cls: 'b-fa b-fa-sms has-notified',
                        visible: ({ record }) => {
                            const data = record.data;
                            const isHeaderRow = data?.meta?.specialRow;
                            return !isHeaderRow && data?.driverId !== null && data?.smsNotifiedFlows !== null && data?.smsNotifiedFlows?.length > 0;
                        },
                        onClick: ({ record }) => {
                            const data = record.data;
                            this.getDriverSmsNotifications(data?.planningVehicleId, data?.driverId, data?.driverFullName, data?.planningId, data?.driverPhoneNumber, data?.driverPhoneNumberIsMobile);
                        },
                        tooltip: ""
                    }, {
                        cls: 'b-fa b-fa-sms',
                        visible: ({ record }) => {
                            const data = record.data;
                            const isHeaderRow = data?.meta?.specialRow;
                            return !isHeaderRow && data?.driverId !== null && (data?.smsNotifiedFlows === null || data?.smsNotifiedFlows?.length === 0) && data?.driverPhoneNumberIsMobile;
                        },
                        onClick: ({ record }) => {
                            const data = record.data;
                            this.getDriverSmsNotifications(data?.planningVehicleId, data?.driverId, data?.driverFullName, data?.planningId, data?.driverPhoneNumber, data?.driverPhoneNumberIsMobile);
                        },
                        tooltip: ""
                    },
                    {
                        cls: 'b-fa b-fa-sms sms-invalid-number',
                        visible: ({ record }) => {
                            const data = record.data;
                            const isHeaderRow = data?.meta?.specialRow;
                            return !isHeaderRow && data?.driverId !== null && !data?.driverPhoneNumberIsMobile && data?.driverPhoneNumber !== null && data?.driverPhoneNumber?.trim() !== "";
                        },
                        tooltip: "Impossible d'envoyer un SMS à un numéro de téléphone fixe."
                    }]
                },
            }],
            [PlanningVehiclesGridField.occupancyRate, {
                orderIndex: 13,
                columnConfig: {
                    text: 'Occup',
                    field: PlanningVehiclesGridField.occupancyRate,
                    width: 50,
                    minWidth: 35,
                    editor: false,
                }
            }],
            [PlanningVehiclesGridField.loadUtilizationRate, {
                orderIndex: 14,
                columnConfig: {
                    type: 'template',
                    text: '% charge',
                    field: PlanningVehiclesGridField.loadUtilizationRate,
                    width: 50,
                    minWidth: 35,
                    editor: false,
                    template: ({ record }) => {
                        const loadUtilizationRate = record.data?.loadUtilizationRate ? `${record.data?.loadUtilizationRate}%` : '';
                        return `<div>${loadUtilizationRate}</div>`;
                    }
                }
            }],
            [PlanningVehiclesGridField.planningVehicleRemarks, {
                orderIndex: 15,
                columnConfig: {
                    headerRenderer: () => {
                        return `<div class='remarks'>Commentaire<div class='pen pen-icon'></div></div>`;
                    },
                    field: PlanningVehiclesGridField.planningVehicleRemarks,
                    width: 300,
                    editor: this.isLogisticianUser ? true : false,
                    finalizeCellEdit: ({ record, value }) => {
                        OperationalMonitoringApiClient.UpdatePlanningVehicleRemarks(record.data.planningId, record.data.planningVehicleId, value)
                            .then((res) => {
                                const data = res?.data;
                                const errors = BusinessErrors.Get(data);
                                if (errors.length > 0) {
                                    ToastService.showErrorToast("", errors);

                                    const scheduler = () => this.schedulerRef.current?.instance as Scheduler;
                                    const scrollTop: number = scheduler().scrollTop;
                                    this.legacyHandleRefresh(scrollTop);
                                }
                                else {
                                    //Mettre à jour le nouveau commentaire dans la ressource du camion
                                    const resourcesData = [...this.state.resources];
                                    const planningVehicle = resourcesData.find(r => r.planningVehicleId === record.data.planningVehicleId);
                                    planningVehicle.planningVehicleRemarks = value;
                                    this.setState({
                                        resources: resourcesData
                                    });
                                }
                            });
                        return true;
                    }
                }
            }],
            [PlanningVehiclesGridField.costsArePublishedOrderValue, {
                orderIndex: 16,
                columnConfig: {
                    text: 'Préfac',
                    field: PlanningVehiclesGridField.costsArePublishedOrderValue,
                    width: 50,
                    minWidth: 50,
                    editor: false,
                    htmlEncode: false,
                    renderer: ({ record }) => {
                        return <PlanningVehicleCosts
                            costsArePublished={record.data?.costsArePublished}
                            costsAreAgreed={record.data?.costsAreAgreed}
                            costsDisagreementReason={record.data?.costsDisagreementReason}
                        />
                    }
                }
            }]
        ]);

        columns.forEach(c => {
            const column: OrderedColumnConfig = mapColumns.get(c.field);
            if (column) {
                column.orderIndex = c.orderIndex;
            }
        });

        return Array.from(mapColumns.values()).sort((a, b) => a.orderIndex - b.orderIndex).map(x => x.columnConfig);
    }

    getVehicleTypesStateFragment = async (): Promise<VehicleTypesStateFragment> => {
        let stateFragment: VehicleTypesStateFragment = null;

        return await OperationalMonitoringApiClient.GetVehicleTypes()
            .then(res => {
                if (this._isMounted) {
                    stateFragment = stateFragment ?? this.initStateFragment(this.createDefaultVehicleTypesStateFragment, this.state);
                    stateFragment.vehicleTypes = res.data;
                }
                return stateFragment;
            });
    }

    async componentDidMount() {
        this._isMounted = true;

        if (this.isLogisticianUser) {
            const newState: OperationalMonitoringViewState = { ...this.state };
            await Promise.all([
                this.getExpeditionsWithOverheadExpensesSumStateFragment(),
                this.getVehicleGroupCountersStateFragment(this.state.date),
                this.getVehicleTypesStateFragment()
            ]).then((results) => {
                this.copyStateFragments(newState, results[0], results[1], results[2]);
                const fromTime = newState.date.stripTime();
                this.updateSchedulerInstanceAfterDataRefresh(fromTime);
                this.setState(newState);
            });
        }
        else {
            this.getExpeditions(this.state.pageLength, this.state.pageNumber, "", this.state.filtersSelected, this.state.date, null, 0);
        }

        const storedLabelSelection = LocalStorage.GetItem(ModuleKey, LabelSelectionStorageKey);
        if (storedLabelSelection != null) {
            this.applyStylesForButtonsTrips(storedLabelSelection);
        }

        //Par défaut l'affichage du Gantt débutera à 06h00 (le scroll permettra si besoin d'afficher les créneaux horaires précédents).
        //600 :tickWidth(100) du Scheduler.preset.ViewPreset (customPreset) * 6(affichage débutera à 6h00 du matin)
        const scheduler = () => this.schedulerRef.current?.instance as Scheduler;
        scheduler()?.scrollHorizontallyTo(OperationalMonitoringView.TimelineDefaultScrollLeft, { animate: false });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    shouldComponentUpdate(nextProps: OperationalMonitoringViewProperties, nextState: OperationalMonitoringViewState) {
        if (this.state.lastTimeStampGetVehicleGroupCounters > nextState.lastTimeStampGetVehicleGroupCounters
            || this.state.lastTimeStampGetExpeditions > nextState.lastTimeStampGetExpeditions
            || this.state.lastTimeStampFilteredSelected > nextState.lastTimeStampFilteredSelected
        )
            return false;
        return true;
    }

    componentDidUpdate(prevProps: OperationalMonitoringViewProperties, prevState: OperationalMonitoringViewState) {
        if (this.state.lastTimeStampFilteredSelected > prevState.lastTimeStampFilteredSelected) {
            if (this.state.filtersSelected.length == 0) {
                ToastService.showErrorToast("Veuillez séléctionner au moins un filtre.");
            }
            //Ne lancer la relancer la recherche que s'il existe au moins 3 caractères dans le champs de recherche
            else if (this.state.searchText.length >= 3) {
                this.getExpeditions(this.state.pageLength, this.state.pageNumber, this.state.searchText, this.state.filtersSelected, this.state.date, null, 0);
            }
        }
    }

    constructKey = (groupName: string, numericDate: number): string => {
        return `${CollapsedGroupKeyPrefix}.${groupName}.${numericDate}`;
    }

    getCollapsedSiteGroup = (numericDate: number, groupName: string): Set<string> => {
        const keyName = this.constructKey(groupName, numericDate);
        const localStorageItem = LocalStorage.GetItem(ModuleKey, keyName);
        return localStorageItem == null ? new Set<string>() : new Set<string>(JSON.parse(localStorageItem));
    }

    setCollapsedSiteGroup = (numericDate: number, groupName: string, groupSet: Set<string>): void => {
        const keyName = this.constructKey(groupName, numericDate);
        LocalStorage.SetItem(ModuleKey, keyName, JSON.stringify(Array.from(groupSet.values())));
    }

    handleKeyPress = debounce((searchText: string): void => {
        if (searchText.length >= 3 || searchText.length === 0)
            this.keyPressed(searchText);
    }, 500);

    keyPressed = (searchText: string): void => {
        if (this.state.selectedFlow !== searchText) {
            this.setState({
                selectedFlow: null
            });
        }

        const scheduler = () => this.schedulerRef.current?.instance as Scheduler;
        const scrollLeft = scheduler().scrollLeft;

        this.setState({
            searchText: searchText,
            eventsVersion: 0,
            scrollLeftSaved: scrollLeft
        }, () => {
            this.getExpeditions(this.state.pageLength, this.state.pageNumber, searchText, this.state.filtersSelected, this.state.date, null, 0);
            if (this.isLogisticianUser)
                this.getVehicleTypeCount(this.state.date);
        });
    }

    clearFilter = (): void => {
        if (this.inputSearchTextRef.current.value === this.state.selectedFlow) {
            this.setState({
                selectedFlow: null
            });
        }
        this.inputSearchTextRef.current.value = '';
        this.setState({ searchText: "", eventsVersion: 0 });
        this.getExpeditions(this.state.pageLength, this.state.pageNumber, "", this.state.filtersSelected, this.state.date, null, 0);
        if (this.isLogisticianUser)
            this.getVehicleTypeCount(this.state.date);
    }

    handleDatepickerChange = (event: DatePickerChangeEvent): void => {
        let date: Date = event.target.value;

        //When the popup is visible
        if (event.target.state.show) {
            (this.datePickerRef.current as any).shouldFocusDateInput = false;
            this.handleSearchDatePickerChange(date);
        }
        else {
            if (!date)
                date = new Date(null);

            (this.datePickerRef.current as any).handleBlur = () => {
                this.handleSearchDatePickerChange(date);
            }
        }
    }

    handleSearchDatePickerChange = (date: Date): void => {
        const numericDate = date.toNumericDate();
        if (!Date.equals(date, this.state.date)) {
            this.setState({
                date: date
                , scrollLeftSaved: OperationalMonitoringView.TimelineDefaultScrollLeft
                , collapsedSenderSiteGroup: this.getCollapsedSiteGroup(numericDate, SenderSitesGroupName)
                , collapsedReceiverSiteGroup: this.getCollapsedSiteGroup(numericDate, ReceiverSitesGroupName)
                , collapsedBeneficiariesGroup: this.getCollapsedSiteGroup(numericDate, BeneficiariesGroupName)
            }, () => {

                if (this.isLogisticianUser) {
                    this.getExpeditionsWithSumOverheadExpenses(this.state.pageLength, this.state.pageNumber, this.state.searchText, this.state.filtersSelected, this.state.date, null, 0);
                    this.getVehicleGroupCounters(date);
                } else {
                    this.getExpeditions(this.state.pageLength, this.state.pageNumber, this.state.searchText, this.state.filtersSelected, this.state.date, null, 0);
                }
                if (this.state.isFlowManagementDrawerOpened) {
                    this.searchTransportFlow(this.state.flowSearchtext, this.state.addedDaysNumberChoice, date, this.state.isNegativeRemainingQtyFilterSelected);
                }
                SessionStorage.ActiveStartDate = date.getDayStart();
                SessionStorage.ActiveEndDate = date.getDayEnd();
            });
        }
    }

    getVehicleTypeCount = (date: Date): void => {
        const searchAvailableDailyVehicles: boolean = date > new Date();
        const fromTime = date.stripTime();
        const currentTimeStamp = Date.now();
        TransportIndicatorsApiClient.GetVehicleGroupCounters(fromTime, fromTime, this.props.logisticsUnitIds, searchAvailableDailyVehicles)
            .then(res => {
                const data: VehicleGroupCountersLightModel = res.data;
                if (currentTimeStamp < this.state.lastTimeStampGetVehicleGroupCounters)
                    return;

                if (this._isMounted)
                    this.setState({ vehicleGroupCounters: data, lastTimeStampGetVehicleGroupCounters: currentTimeStamp });
            });
    }

    getVehicleGroupCounters = (date: Date): void => {
        const searchAvailableDailyVehicles: boolean = date > new Date();
        const fromTime = date.stripTime();
        const currentTimeStamp = Date.now();
        TransportIndicatorsApiClient.GetVehicleGroupCounters(fromTime, fromTime, this.props.logisticsUnitIds, searchAvailableDailyVehicles)
            .then(res => {
                if (currentTimeStamp < this.state.lastTimeStampGetVehicleGroupCounters)
                    return;

                if (this._isMounted) {
                    this.setState({ vehicleGroupCounters: res.data, lastTimeStampGetVehicleGroupCounters: currentTimeStamp });
                }
            });
    }

    getVehicleTypes = (): void => {
        OperationalMonitoringApiClient.GetVehicleTypes()
            .then(res => {
                if (this._isMounted) {
                    this.setState({ vehicleTypes: res.data });
                }
            });
    }

    getExpeditions = (pageLength: number, pageNumber: number, searchText: string, searchTextFields: Array<TextSearchField>, date: Date, sorters: SortDescriptor[], scrollTop: number): void => {
        if (date !== null && searchTextFields.length > 0) {
            this.setState({
                loadingDataScheduler: true
            });
            const fromTime = date.stripTime();
            const currentTimeStamp = Date.now();

            const productionForemanUserName = (this.props.role === "PRD") ? this.props.userName : '';
            const dispatcherUserName = (this.props.role === "DIS") ? this.props.userName : '';
            OperationalMonitoringApiClient.GetExpeditions(pageLength, pageNumber, searchText, searchTextFields, fromTime, this.props.logisticsUnitIds, productionForemanUserName, dispatcherUserName)
                .then(res => {
                    if (this._isMounted) {
                        if (currentTimeStamp < this.state.lastTimeStampGetExpeditions)
                            return;

                        this.parseExpeditionsData(res.data, this.state.transportMargins.overheadExpenses, currentTimeStamp, scrollTop, fromTime, sorters);
                    }
                })
                .catch(() => {
                    this.setState({ loadingDataScheduler: false, events: [], resources: [], resourcesWithDriversAndMobilePhoneNumber: [], existsResourcesToReceiveSmsNotification: false, existsEmptyVehicles: false });
                });
        }
    }

    getExpeditionsWithSumOverheadExpenses = (pageLength: number, pageNumber: number, searchText: string, searchTextFields: Array<TextSearchField>, date: Date, sorters: Array<SortDescriptor>, scrollTop: number): void => {
        if (date !== null && searchTextFields.length > 0) {
            this.setState({
                loadingDataScheduler: true
            });
            const fromTime = date.stripTime();
            const currentTimeStamp = Date.now();

            OperationalMonitoringApiClient.GetExpeditionsWithOverheadExpensesSum(pageLength, pageNumber, searchText, searchTextFields, fromTime, this.props.logisticsUnitIds, '', '')
                .then(res => {
                    if (this._isMounted) {
                        if (currentTimeStamp < this.state.lastTimeStampGetExpeditions)
                            return;

                        this.parseExpeditionsData(res[0].data, res[1].data, currentTimeStamp, scrollTop, fromTime, sorters);
                    }
                })
                .catch(() => {
                    this.setState({ loadingDataScheduler: false, events: [], resources: [], resourcesWithDriversAndMobilePhoneNumber: [], existsResourcesToReceiveSmsNotification: false, existsEmptyVehicles: false });
                });
        }
    }

    getSiteLabel = (siteResolutionLabel: string, addressCity: string, siteAgencyLabel: string, siteKind: string): string => {
        switch (siteKind) {
            case "JobSite":
                return this.getSiteLabelWhenJobSite(siteResolutionLabel, addressCity, siteAgencyLabel)
            default:
                return this.getSiteLabelFromSiteResolutionLabelAndCity(siteResolutionLabel, addressCity);
        }
    }

    getSiteLabelWhenJobSite = (siteResolutionLabel: string, addressCity: string, siteAgencyLabel: string): string => {
        if (siteAgencyLabel) {
            return addressCity + ' / ' + siteResolutionLabel + ' / ' + siteAgencyLabel;
        } else {
            return this.getSiteLabelFromSiteResolutionLabelAndCity(siteResolutionLabel, addressCity);
        }
    }

    getSiteLabelFromSiteResolutionLabelAndCity = (siteResolutionLabel: string, addressCity: string): string => {
        if (siteResolutionLabel && addressCity)
            return addressCity + ' / ' + siteResolutionLabel;
        else if (siteResolutionLabel)
            return siteResolutionLabel;
        else if (addressCity)
            return addressCity;

        return ""
    }

    canReceiveSmsNotification = (element: PlanningVehicleTripsLightModelExtended): boolean => {
        return element.driverId !== null && element.driverPhoneNumberIsMobile && element.trips.some(t => t.status !== "Canceled");
    }

    parseExpeditionsData = (pageResultData: PagedResult<PlanningVehicleTripsLightModel>, sumOverheadExpenses: number, currentTimeStamp: number, scrollTop: number, fromTime: Date, sorters: SortDescriptor[]) => {
        const data: Array<PlanningVehicleTripsLightModelExtended> = (pageResultData.pageItems) as Array<PlanningVehicleTripsLightModelExtended>;
        const storedLabelSelection = LocalStorage.GetItem(ModuleKey, LabelSelectionStorageKey);
        this.selectedValue = (storedLabelSelection != null) ? storedLabelSelection : "tripNumber";
        const eventsData: Array<EventModelData> = [];
        data.forEach((planningVehicle: PlanningVehicleTripsLightModelExtended) => {
            this.planningVehicleEnrichment(planningVehicle, eventsData, storedLabelSelection);
        });

        if (currentTimeStamp < this.state.lastTimeStampGetExpeditions)
            return;

        const existsResourcesToReceiveSmsNotification: boolean = data.some(element => this.canReceiveSmsNotification(element));
        const isResourcesContainesEmptyVehicles: boolean = data.filter(r => !r.vehicle && !r.transporterId && !r.driverId && r.trips?.length == 0).length > 0;
        const resourcesWithDriversAndMobilePhoneNumber: PlanningVehicleTripsLightModelExtended[] = data.filter(x => x.driverId !== null && x.driverPhoneNumberIsMobile);

        const newState: OperationalMonitoringViewState = {
            // Increment these to notify BryntumScheduler that it should process events/resources
            ...this.state,
            loadingDataScheduler: false,
            events: eventsData,
            resources: data,
            eventsVersion: this.state.eventsVersion + 1,
            resourcesWithDriversAndMobilePhoneNumber: resourcesWithDriversAndMobilePhoneNumber,
            existsResourcesToReceiveSmsNotification: existsResourcesToReceiveSmsNotification,
            existsEmptyVehicles: isResourcesContainesEmptyVehicles,
            activePage: pageResultData.pageIndex,
            totalItems: pageResultData.totalItemsCount,
            lastTimeStampGetExpeditions: currentTimeStamp,
            isSmsNotificationDialogOpened: this.state.refreshToOpenSmsModal === true ? true : false,
            refreshToOpenSmsModal: false,
            needGrouping: true,
            sorters: sorters
        };

        if (this.isLogisticianUser) {
            const sumGrossMargins = this.sumPlanningVehicleGrossMargin(data);
            const sumPercentCharge = this.computeLoadUtilizationRateAverage(data);
            newState.transportMargins = {
                overheadExpenses: sumOverheadExpenses,
                grossMargin: sumGrossMargins,
                netMargin: sumGrossMargins - sumOverheadExpenses,
                averageLoadUtilizationRate: sumPercentCharge
            };
        }

        this.setState(newState);

        this.setStartEndDateExpeditonScheduler(fromTime);
        this.applyStylesForButtonsTrips(this.selectedValue);

        const scheduler = () => this.schedulerRef.current?.instance as Scheduler;
        if (scrollTop > 0) {
            scheduler().scrollVerticallyTo(scrollTop, { animate: false });
        }

        //Par défaut l'affichage du Gantt débutera à 06h00 (le scroll permettra si besoin d'afficher les créneaux horaires précédents).
        //600 :tickWidth(100) du Scheduler.preset.ViewPreset (customPreset) * 6(affichage débutera à 6h00 du matin)
        const scrollLeft = (this.state.scrollLeftSaved || this.state.scrollLeftSaved == 0) ? this.state.scrollLeftSaved : OperationalMonitoringView.TimelineDefaultScrollLeft;
        scheduler().scrollHorizontallyTo(scrollLeft, { animate: false });
    }

    planningVehicleEnrichment = (planningVehicle: PlanningVehicleTripsLightModelExtended, eventsData: EventModelData[], storedLabelSelection: string): void => {
        planningVehicle.beneficiary = planningVehicle.firstFlowBeneficiaryIsExternal
            ? (planningVehicle.firstFlowBeneficiaryName || planningVehicle.firstFlowBeneficiaryPhoneNumber) ?
                `${planningVehicle.firstFlowBeneficiaryName ?? ''} / ${planningVehicle.firstFlowBeneficiaryPhoneNumber ?? ''}` : ''
            : (planningVehicle.firstFlowBeneficiaryName || planningVehicle.firstFlowBeneficiaryId) ?
                `${planningVehicle.firstFlowBeneficiaryName ?? ''} / ${planningVehicle.firstFlowBeneficiaryId ?? ''}` : '';
        planningVehicle.senderSite = this.getSiteLabel(planningVehicle.firstFlowSenderSiteResolutionLabel, planningVehicle.firstFlowPickupAddressCity, planningVehicle.firstFlowSenderSiteAgencyLabel, planningVehicle.firstFlowSenderSiteKind);
        planningVehicle.receiverSite = this.getSiteLabel(planningVehicle.firstFlowReceiverSiteResolution, planningVehicle.firstFlowDeliveryAddressCity, planningVehicle.firstFlowReceiverSiteAgencyLabel, planningVehicle.firstFlowReceiverSiteKind);
        planningVehicle.driverPhoneNumber = Utilities.formatPhoneNumber(planningVehicle.driverPhoneNumber);
        planningVehicle.driverPhoneNumberIsMobile = Utilities.isValidPhoneNumber(planningVehicle.driverPhoneNumber, true);
        planningVehicle.label = planningVehicle.label ? planningVehicle.label : '';
        planningVehicle.transporter = planningVehicle.transporter ? planningVehicle.transporter : '';
        planningVehicle.driverFullName = planningVehicle.driverFullName ? planningVehicle.driverFullName : '';
        planningVehicle.hasAtLeastOneDeliveryTrip = planningVehicle.trips.length > 0;
        planningVehicle.confirmationStatusOrderValue = PlanningVehicleStatusComponent.getOrderValue(planningVehicle.isCanceled, planningVehicle.cancellationOrderSentDate, planningVehicle.cancellationOrderConfirmationDate, planningVehicle.transporterConfirmationDate, planningVehicle.transporterOrderId, planningVehicle.transporterIsInternal);
        planningVehicle.costsArePublishedOrderValue = getCostsArePublishedOrderValue(planningVehicle.costsArePublished, planningVehicle.costsAreAgreed);
        planningVehicle.grossMargin = (planningVehicle.sellPrice ?? 0) - planningVehicle.totalCosts;
        planningVehicle.trips.forEach((deliveryTrip: TripLightModel) => {
            eventsData.push({
                resourceId: planningVehicle.planningVehicleId,
                name: storedLabelSelection === "city"
                    ? deliveryTrip.pickupCity : (storedLabelSelection === "cch"
                        ? deliveryTrip.beneficiaryName : (storedLabelSelection === "product" ? deliveryTrip.product : deliveryTrip.tripNumber)),
                name2: storedLabelSelection === "city"
                    ? deliveryTrip.deliveryCity : (storedLabelSelection === "cch"
                        ? "" : (storedLabelSelection === "product" ? "" : "")),
                deliveryTripId: deliveryTrip.deliveryTripId,
                transportRequestId: deliveryTrip.transportRequestId,
                logisticsUnitId: deliveryTrip.logisticsUnitId,
                tripNumber: deliveryTrip.tripNumber,
                nameCity: deliveryTrip.deliveryCity,
                pickupCity: deliveryTrip.pickupCity,
                nameCCH: deliveryTrip.beneficiaryName == null ? '' : deliveryTrip.beneficiaryName,
                startDate: deliveryTrip.startTime,
                endDate: deliveryTrip.endTime,
                location: "Home office",
                eventType: "Meeting",
                supplierJobSite: deliveryTrip.pickupFreeFromAddress ?? "",
                customerJobSite: deliveryTrip.deliveryFreeFormAddress ?? "",
                senderSiteLabel: deliveryTrip.senderSiteLabel,
                senderSiteKind: deliveryTrip.senderSiteKind,
                senderSiteId: deliveryTrip.senderSiteId,
                receiverSiteLabel: deliveryTrip.receiverSiteLabel,
                realDepartureTime: deliveryTrip.realDepartureTime,
                realQuantity: deliveryTrip.realQuantity,
                product: deliveryTrip.product,
                quantity: deliveryTrip.quantity,
                unitOfMeasure: deliveryTrip.unitOfMeasure,
                transporterId: planningVehicle.transporterId,
                kindOfValuesAccounted: planningVehicle.kindOfValuesAccounted,
                driverId: planningVehicle.driverId,
                isNightWork: deliveryTrip.isNightWork,
                vehicleTypeId: planningVehicle.vehicleTypeId,
                transportFlowVehicleTypeGroupId: planningVehicle.vehicleTypeGroupId,
                vehicleId: planningVehicle.vehicle,
                planningVehicleId: planningVehicle.planningVehicleId,
                planningVehicleIsCanceled: planningVehicle.isCanceled,
                planningId: planningVehicle.planningId,
                eventStatus: deliveryTrip.status,
                serviceKind: deliveryTrip.serviceKind,
                isPerishableProduct: deliveryTrip.isPerishableProduct,
                transportFlowId: deliveryTrip.transportFlowId,
                businessId: deliveryTrip.businessId,
                transportFlowStatus: deliveryTrip.transportFlowStatus,
                transportFlowBusinessId: deliveryTrip.transportFlowBusinessId,
                beneficiaryPhoneNumber: Utilities.formatPhoneNumber(deliveryTrip.beneficiaryPhoneNumber),
                priority: deliveryTrip.priority,
                priceKind: deliveryTrip.priceKind,
                price: deliveryTrip.price,
                totalCosts: planningVehicle.totalCosts,
                sellPrice: planningVehicle.sellPrice,
                transportersInstructions: deliveryTrip.transportersInstructions,
                driverInstructions: deliveryTrip.driverInstructions,
                draggable: this.isLogisticianUser,
                resizable: this.isLogisticianUser ? resizableAtTheEnd : false,
                licencePlate: planningVehicle.label,
                vehicleNumber: planningVehicle.vehicleNumber,
                rotationNumber: deliveryTrip.rotationNumber,
                transportFlowSellPriceKind: deliveryTrip.transportFlowSellPriceKind,
                transportFlowUnitPrice: deliveryTrip.transportFlowUnitPrice,
                transportFlowNegotiatedPurchaseUnitPrice: deliveryTrip.transportFlowNegotiatedPurchaseUnitPrice,
                transportFlowNegotiatedPurchasePriceKind: deliveryTrip.transportFlowNegotiatedPurchasePriceKind,
                transportFlowNegotiatedPurchasePriceVehicleTypeGroupId: deliveryTrip.transportFlowNegotiatedPurchasePriceVehicleTypeGroupId,
                negotiatedPurchasePriceAppliesOnTrip: planningVehicle.transporterId && (
                    (!planningVehicle.transporterIsInternal && deliveryTrip.negotiatedPurchasePriceAppliesToExternalTransporters)
                    || (planningVehicle.transporterIsInternal && deliveryTrip.negotiatedPurchasePriceAppliesToInternalTransporters)),
                deliveryNoteId: deliveryTrip.deliveryNoteId,
                finishedDateByForeman: deliveryTrip.finishedDateByForeman,
                cancellationReason: deliveryTrip.cancellationReason,
                cancellationRemarks: deliveryTrip.cancellationRemarks,
                isManuallyAttachedToZephyr: deliveryTrip.isManuallyAttachedToZephyr
            });
        });
        planningVehicle.loadUtilizationRate = this.computeLoadUtilizationRate(planningVehicle);
    }

    sumPlanningVehicleGrossMargin = (items: Array<PlanningVehicleTripsLightModelExtended>) => {
        let sumGrossMargin = 0;

        for (let i = 0; i < items.length; i++) {
            sumGrossMargin += items[i].grossMargin;
        }

        return sumGrossMargin;
    }

    computeLoadUtilizationRateAverage = (planningVehicles: Array<PlanningVehicleTripsLightModelExtended>) => {
        let sumOfLoadUtilizationRate = 0;
        let planningVehiclesCount = 0;
        for (const planningVehicle of planningVehicles) {
            if (!planningVehicle.isCanceled) {
                planningVehiclesCount++;
                sumOfLoadUtilizationRate += planningVehicle.loadUtilizationRate;
            }
        }
        return planningVehiclesCount === 0 ? 0 : Math.round(sumOfLoadUtilizationRate / planningVehiclesCount);
    }

    computeLoadUtilizationRate = (planningVehicle: PlanningVehicleTripsLightModelExtended): number | null => {
        if (!planningVehicle || !planningVehicle.trips || planningVehicle.trips.length === 0) {
            return null;
        }
        const validTrips = planningVehicle.trips.filter(trip => trip.status !== DeliveryTripStatus.canceled);

        if (validTrips.length === 0)
            return null;

        if (validTrips.length === 1)
            return 100;

        const mergedValidTrips = this.mergeOverlappingTrips(validTrips);
        const loadedDuration = mergedValidTrips.reduce((sum, trip) => {
            const tripDuration = this.getDurationBetweenTwoDatesMillis(trip.startTime, trip.endTime);
            return sum + tripDuration;
        }, 0);

        const utilizationDuration = this.getDurationBetweenTwoDatesMillis(
            mergedValidTrips[0].startTime,
            mergedValidTrips[mergedValidTrips.length - 1].endTime
        );
        const loadUtilizationRate = (loadedDuration / utilizationDuration) * 100;
        return Math.round(loadUtilizationRate);
    }

    getDurationBetweenTwoDatesMillis = (startDate: Date, endDate: Date) => {
        const result = endDate?.getTime() - startDate?.getTime();
        return result;
    }

    mergeOverlappingTrips = (trips: TripLightModel[]): { startTime: Date, endTime: Date }[] => {
        trips.sort((a, b) => {
            if (a.startTime.getTime() - b.startTime.getTime() !== 0)
                return a.startTime.getTime() - b.startTime.getTime();

            return a.endTime.getTime() - b.endTime.getTime();
        });
        const tripsDates = trips.map(x => ({ startTime: x.startTime, endTime: x.endTime }));
        const mergedTrips = [tripsDates[0]];
        for (let i = 1; i < tripsDates.length; i++) {
            const currentTrip = tripsDates[i];
            const previousTrip = mergedTrips[mergedTrips.length - 1];

            // Tour courant complétement englobé, on l'ignore
            if (currentTrip.startTime < previousTrip.endTime && currentTrip.endTime <= previousTrip.endTime)
                continue;

            // Tour courant à cheval et finis plus tard, on le rallonge précèdent, et on continue
            if (currentTrip.startTime < previousTrip.endTime && currentTrip.endTime > previousTrip.endTime) {
                previousTrip.endTime = currentTrip.endTime;
                continue;
            }

            // Tour courant ne s'intersecte pas 
            mergedTrips.push(currentTrip);
        }
        return mergedTrips;
    }

    calculateAddress = (line1: string, line2: string, city: string): string => {
        let address = line1 !== null && line1 !== "" ? (line2 !== null && line2 !== "" ? line1 + " / " + line2 : line1) : (line2 !== null && line2 !== "" ? line2 : "");
        if (address.length > 45)
            address = address.substring(0, 45);

        address += city === null || city === "" ? "" : (address === null || address === "" ? city : " / " + city);
        return address;
    }

    setStartEndDateExpeditonScheduler = (date: Date): void => {
        if (date !== null) {
            const startDate = date.getDayStart();
            const endDate = date.getDayStart().addDays(2);
            const scheduler = () => this.schedulerRef.current?.instance as Scheduler;
            if (scheduler !== undefined) {
                scheduler().startDate = startDate;
                scheduler().endDate = endDate;
                const filtredDiv: HTMLCollection = document.getElementsByClassName("b-grid-subgrid b-grid-subgrid-locked");
                let isFiltered = false;
                if (this.inputSearchTextRef.current.value.length >= 3) {
                    isFiltered = true;
                }
                Utilities.updateFilteredStyle(filtredDiv, isFiltered);
            }
        }
    }

    applyStylesForButtonsTrips = (label: string): void => {
        const expeditionsDiv = document.getElementsByClassName(this.EXPEDITIONS_CONTENT_DIV)[0];

        switch (label) {

            case "city":
                expeditionsDiv.classList.remove('city-btn-selected');
                expeditionsDiv.classList.remove('cch-btn-selected');
                expeditionsDiv.classList.remove('tripNumber-btn-selected');
                expeditionsDiv.classList.remove('product-btn-selected');

                expeditionsDiv.classList.add('city-btn-selected');
                break;

            case "cch":
                expeditionsDiv.classList.remove('city-btn-selected');
                expeditionsDiv.classList.remove('cch-btn-selected');
                expeditionsDiv.classList.remove('tripNumber-btn-selected');
                expeditionsDiv.classList.remove('product-btn-selected');

                expeditionsDiv.classList.add('cch-btn-selected');
                break;

            case "tripNumber":
                expeditionsDiv.classList.remove('city-btn-selected');
                expeditionsDiv.classList.remove('cch-btn-selected');
                expeditionsDiv.classList.remove('tripNumber-btn-selected');
                expeditionsDiv.classList.remove('product-btn-selected');

                expeditionsDiv.classList.add('tripNumber-btn-selected');
                break;

            case "product":
                expeditionsDiv.classList.remove('city-btn-selected');
                expeditionsDiv.classList.remove('cch-btn-selected');
                expeditionsDiv.classList.remove('tripNumber-btn-selected');
                expeditionsDiv.classList.remove('product-btn-selected');

                expeditionsDiv.classList.add('product-btn-selected');
                break;
        }
    }

    applyStylesForButtonsPrestations = (element: string, selected: boolean): void => {
        const expeditionsDiv = document.getElementsByClassName(this.EXPEDITIONS_CONTENT_DIV)[0];

        //Sortir si le dom n'est pas chargé
        if (!expeditionsDiv)
            return;

        switch (element) {
            case 'prestation-perishable-selected':
                {
                    if (selected && !expeditionsDiv.classList.contains('prestation-perishable-selected'))
                        expeditionsDiv.classList.add('prestation-perishable-selected');

                    if (!selected && expeditionsDiv.classList.contains('prestation-perishable-selected'))
                        expeditionsDiv.classList.remove('prestation-perishable-selected');

                    break;
                }
            case 'prestation-not-perishable-selected':
                {
                    if (selected && !expeditionsDiv.classList.contains('prestation-not-perishable-selected'))
                        expeditionsDiv.classList.add('prestation-not-perishable-selected');

                    if (!selected && expeditionsDiv.classList.contains('prestation-not-perishable-selected'))
                        expeditionsDiv.classList.remove('prestation-not-perishable-selected');
                    break;
                }
            case 'prestation-removal-selected':
                {
                    if (selected && !expeditionsDiv.classList.contains('prestation-removal-selected'))
                        expeditionsDiv.classList.add('prestation-removal-selected');

                    if (!selected && expeditionsDiv.classList.contains('prestation-removal-selected'))
                        expeditionsDiv.classList.remove('prestation-removal-selected');
                    break;
                }
            case 'prestation-jobsite-selected':
                {
                    if (selected && !expeditionsDiv.classList.contains('prestation-jobsite-selected'))
                        expeditionsDiv.classList.add('prestation-jobsite-selected');

                    if (!selected && expeditionsDiv.classList.contains('prestation-jobsite-selected'))
                        expeditionsDiv.classList.remove('prestation-jobsite-selected');
                    break;
                }
            case 'all':
                {
                    expeditionsDiv.classList.remove('prestation-perishable-selected');
                    expeditionsDiv.classList.remove('prestation-not-perishable-selected');
                    expeditionsDiv.classList.remove('prestation-removal-selected');
                    expeditionsDiv.classList.remove('prestation-jobsite-selected');

                    expeditionsDiv.classList.add('prestation-perishable-selected');
                    expeditionsDiv.classList.add('prestation-not-perishable-selected');
                    expeditionsDiv.classList.add('prestation-removal-selected');
                    expeditionsDiv.classList.add('prestation-jobsite-selected');
                    break;
                }
        }
    }

    applyStylesForButtonsCriticity = (element: string, selected: boolean): void => {
        const expeditionsDiv = document.getElementsByClassName(this.EXPEDITIONS_CONTENT_DIV)[0];

        //Sortir si le dom n'est pas chargé
        if (!expeditionsDiv)
            return;

        switch (element) {
            case 'criticity-modulable-selected':
                {
                    if (selected && !expeditionsDiv.classList.contains('criticity-modulable-selected'))
                        expeditionsDiv.classList.add('criticity-modulable-selected');

                    if (!selected && expeditionsDiv.classList.contains('criticity-modulable-selected'))
                        expeditionsDiv.classList.remove('criticity-modulable-selected');

                    break;
                }
            case 'criticity-fix-selected':
                {
                    if (selected && !expeditionsDiv.classList.contains('criticity-fix-selected'))
                        expeditionsDiv.classList.add('criticity-fix-selected');

                    if (!selected && expeditionsDiv.classList.contains('criticity-fix-selected'))
                        expeditionsDiv.classList.remove('criticity-fix-selected');
                    break;
                }
            case 'criticity-day-selected':
                {
                    if (selected && !expeditionsDiv.classList.contains('criticity-day-selected'))
                        expeditionsDiv.classList.add('criticity-day-selected');

                    if (!selected && expeditionsDiv.classList.contains('criticity-day-selected'))
                        expeditionsDiv.classList.remove('criticity-day-selected');
                    break;
                }
            case 'all':
                {
                    expeditionsDiv.classList.remove('criticity-modulable-selected');
                    expeditionsDiv.classList.remove('criticity-fix-selected');
                    expeditionsDiv.classList.remove('criticity-day-selected');

                    expeditionsDiv.classList.add('criticity-modulable-selected');
                    expeditionsDiv.classList.add('criticity-fix-selected');
                    expeditionsDiv.classList.add('criticity-day-selected');
                }
        }
    }

    applyStylesForButtonsStatus = (elementId: string, selected: boolean): void => {
        const expeditionsDiv = document.getElementsByClassName(this.EXPEDITIONS_CONTENT_DIV)[0];

        //Sortir si le dom n'est pas chargé
        if (!expeditionsDiv)
            return;

        switch (elementId) {
            case 'status-needed-selected':
                {
                    if (selected && !expeditionsDiv.classList.contains('status-needed-selected'))
                        expeditionsDiv.classList.add('status-needed-selected');

                    if (!selected && expeditionsDiv.classList.contains('status-needed-selected'))
                        expeditionsDiv.classList.remove('status-needed-selected');
                    break;
                }
            case 'status-planned-selected':
                {
                    if (selected && !expeditionsDiv.classList.contains('status-planned-selected'))
                        expeditionsDiv.classList.add('status-planned-selected');

                    if (!selected && expeditionsDiv.classList.contains('status-planned-selected'))
                        expeditionsDiv.classList.remove('status-planned-selected');
                    break;
                }
            case 'status-confirmed-selected':
                {
                    if (selected && !expeditionsDiv.classList.contains('status-confirmed-selected'))
                        expeditionsDiv.classList.add('status-confirmed-selected');

                    if (!selected && expeditionsDiv.classList.contains('status-confirmed-selected'))
                        expeditionsDiv.classList.remove('status-confirmed-selected');
                    break;
                }

            case 'status-inprogress-selected':
                {
                    if (selected && !expeditionsDiv.classList.contains('status-inprogress-selected'))
                        expeditionsDiv.classList.add('status-inprogress-selected');

                    if (!selected && expeditionsDiv.classList.contains('status-inprogress-selected'))
                        expeditionsDiv.classList.remove('status-inprogress-selected');
                    break;
                }
            case 'status-finished-selected':
                {
                    if (selected && !expeditionsDiv.classList.contains('status-finished-selected'))
                        expeditionsDiv.classList.add('status-finished-selected');

                    if (!selected && expeditionsDiv.classList.contains('status-finished-selected'))
                        expeditionsDiv.classList.remove('status-finished-selected');
                    break;
                }
            case 'status-canceled-selected':
                {
                    if (selected && !expeditionsDiv.classList.contains('status-canceled-selected'))
                        expeditionsDiv.classList.add('status-canceled-selected');

                    if (!selected && expeditionsDiv.classList.contains('status-canceled-selected'))
                        expeditionsDiv.classList.remove('status-canceled-selected');
                    break;
                }
            case 'all':
                {
                    expeditionsDiv.classList.remove('status-needed-selected');
                    expeditionsDiv.classList.remove('status-planned-selected');
                    expeditionsDiv.classList.remove('status-confirmed-selected');
                    expeditionsDiv.classList.remove('status-inprogress-selected');
                    expeditionsDiv.classList.remove('status-finished-selected');
                    expeditionsDiv.classList.remove('status-canceled-selected');

                    expeditionsDiv.classList.add('status-needed-selected');
                    expeditionsDiv.classList.add('status-planned-selected');
                    expeditionsDiv.classList.add('status-confirmed-selected');
                    expeditionsDiv.classList.add('status-inprogress-selected');
                    expeditionsDiv.classList.add('status-finished-selected');
                    expeditionsDiv.classList.add('status-canceled-selected');
                }
        }
    }

    handleChangeLabel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, label: string): void => {
        const events = [...this.state.events];

        LocalStorage.SetItem(ModuleKey, LabelSelectionStorageKey, label);

        this.applyStylesForButtonsTrips(label);

        events.forEach(function (element: EventModelData) {
            if (label === "city") {
                element.name = element.pickupCity;
                element.name2 = element.nameCity;
            } else if (label === "cch") {
                element.name = element.nameCCH;
                element.name2 = "";
            } else if (label === "tripNumber") {
                element.name = element.tripNumber;
                element.name2 = "";
            } else if (label === "product") {
                element.name = element.product;
                element.name2 = "";
            }
        });

        this.setState({
            events: events,
            eventsVersion: this.state.eventsVersion + 1
        });

        this.selectedValue = event.currentTarget.value;
    }

    handleChangeSite = (label: string): void => {
        LocalStorage.SetItem(ModuleKey, GroupingNameStorageKey, label);
        this.groupingName = label;
        this.setState({
            needGrouping: true,
            eventsVersion: this.state.eventsVersion + 1
        });
    }

    handleChangePrestation = (element: string, prestation: string, isPerishableProduct: boolean): void => {
        let selected = false;
        const expeditionsDiv: Element = document.getElementsByClassName(this.EXPEDITIONS_CONTENT_DIV)[0];

        if (element != 'all') {
            if (prestation == 'Delivery') {
                if (isPerishableProduct) {
                    prestation += 'Perissable';
                }
                else {
                    prestation += 'NotPerissable';
                }
            }

            if (!expeditionsDiv.classList.contains(element)) {
                selected = true;
            }

            this.applyStylesForButtonsPrestations(element, selected);
        }
        else {
            this.applyStylesForButtonsPrestations('prestation-perishable-selected', true);
            this.applyStylesForButtonsPrestations('prestation-not-perishable-selected', true);
            this.applyStylesForButtonsPrestations('prestation-removal-selected', true);
            this.applyStylesForButtonsPrestations('prestation-jobsite-selected', true);
        }
    }

    handleChangeCriticity = (element: string): void => {
        let selected = false;
        const expeditionsDiv: Element = document.getElementsByClassName(this.EXPEDITIONS_CONTENT_DIV)[0];

        if (element != 'all') {
            if (!expeditionsDiv.classList.contains(element)) {
                selected = true;
            }
            this.applyStylesForButtonsCriticity(element, selected);
        }
        else {
            this.applyStylesForButtonsCriticity('criticity-modulable-selected', true);
            this.applyStylesForButtonsCriticity('criticity-day-selected', true);
            this.applyStylesForButtonsCriticity('criticity-fix-selected', true);
        }
    }

    handleChangeStatus = (element: string): void => {
        let selected = false;
        const expeditionsDiv: Element = document.getElementsByClassName(this.EXPEDITIONS_CONTENT_DIV)[0];

        if (element != 'all') {
            if (!expeditionsDiv.classList.contains(element)) {
                selected = true;
            }
            this.applyStylesForButtonsStatus(element, selected);
        }
        else {
            this.applyStylesForButtonsStatus('status-needed-selected', true);
            this.applyStylesForButtonsStatus('status-planned-selected', true);
            this.applyStylesForButtonsStatus('status-confirmed-selected', true);
            this.applyStylesForButtonsStatus('status-inprogress-selected', true);
            this.applyStylesForButtonsStatus('status-finished-selected', true);
            this.applyStylesForButtonsStatus('status-canceled-selected', true);
        }
    }

    getSchedulerRelatedActionRefreshData = async (): Promise<[ExpeditionsStateFragment, VehicleGroupCountersStateFragment, TransportFLowsStateFragment]> => {
        return await Promise.all([
            this.getExpeditionsStateFragment(),
            this.getVehicleGroupCountersStateFragment(this.state.date),
            this.getTransportFlowsStateFragment(this.state.flowSearchtext, this.state.addedDaysNumberChoice, this.state.date, this.state.isNegativeRemainingQtyFilterSelected, this.state.isFlowManagementDrawerOpened)
        ]);
    }

    getPriceKind = (priceKind: string): string => {
        if (priceKind === PriceKind.PerTon.toString()) {
            return "Tonne";
        } else if (priceKind === PriceKind.PerHour.toString()) {
            return "Heure";
        } else if (priceKind === PriceKind.PerTurn.toString()) {
            return "Tour";
        }

        return "";
    }

    handleAssignGenericVehicleType = async (vehicleTypeId: string, vehicleTypeGroupId: string): Promise<void> => {
        const planningVehicle: PlanningVehicleTripsLightModelExtended = this.getPlanningVehicle();
        if (!planningVehicle)
            return;

        if (planningVehicle.isCanceled) {
            const message = BusinessErrors.GetError({ type: 'PlanningVehicleError', name: 'ActionCannotBePerformedOnACanceledPlanningVehicle' });
            ToastService.showErrorToast(message);
            return;
        }

        this.setState({
            loadingDataScheduler: true
        })

        return await this.sharedHandleAssignGenericVehicleType(planningVehicle, vehicleTypeId, vehicleTypeGroupId);
    }

    handleRemovePlanningVehicleTransporter = async (planningVehicleId: string): Promise<void> => {
        const planningVehicle: PlanningVehicleTripsLightModelExtended = this.state.resources.find(x => x.planningVehicleId === planningVehicleId);
        await this.sharedHandleAssignGenericVehicleType(planningVehicle, planningVehicle.vehicleTypeId, planningVehicle.vehicleTypeGroupId);
    }

    sharedHandleAssignGenericVehicleType = async (planningVehicle: PlanningVehicleTripsLightModelExtended, vehicleTypeId: string, vehicleTypeGroupId: string): Promise<void> => {
        if (planningVehicle.hasAnyProvisionings) {
            const message = BusinessErrors.GetError({ type: 'PlanningVehicleError', name: 'CannotChangeTransporterVehicleTypeWhenPlanningVehicleHasProvisionings' });
            ToastService.showErrorToast(message);
            return;
        }

        if (!this.checkInProgressOrDeliveredTrips(planningVehicle)) {
            const confirmationMessages: Array<string> = [];

            if (planningVehicle.hasOrderLine == true) {
                confirmationMessages.push("Le camion fait l'objet d'une confirmation ou a été confirmé par le transporteur.");
            }

            if (planningVehicle.hasAtLeastOneDeliveryWithSpecificPrice) {
                confirmationMessages.push("Les tarifs spécifiques seront supprimés.");
            }

            if (confirmationMessages.length > 0) {
                this.setState({
                    showConfirmationPlanningVehicleModal: true,
                    confirmationMessage: [...confirmationMessages, "", "", "Confirmez- vous l'action ?"],
                    actionToBeConfirmed: ConfirmableAction.AssignGenericVehicleType,
                    selectedVehicleTypeId: vehicleTypeId,
                    selectedVehicleTypeGroupId: vehicleTypeGroupId
                });

                return;
            }

            const newState = await this.confirmAssignGenericVehicle(vehicleTypeId, planningVehicle);
            this.updateByVehicleTypeGroupIds(vehicleTypeGroupId, newState);
            this.setState(newState);
        }
    }

    confirmAssignGenericVehicle = async (vehicleTypeId: string, planningVehicle?: PlanningVehicleTripsLightModelExtended): Promise<OperationalMonitoringViewState> => {
        const stateFragment: OperationalMonitoringViewState = null;
        if (!planningVehicle) {
            const vehicle = this.getPlanningVehicle();
            if (!vehicle)
                return stateFragment;

            planningVehicle = vehicle;
        }

        const requestArgs: AssignGenericVehicleTypeRequestArgs = {
            planningId: planningVehicle.planningId,
            planningVehicleId: planningVehicle.planningVehicleId,
            vehicleTypeId: vehicleTypeId,
            hasConfirmedExistingSpecificPrices: planningVehicle.hasAtLeastOneDeliveryWithSpecificPrice,
            hasConfirmedExistingTransporterOrderLine: planningVehicle.hasOrderLine
        };

        return await OperationalMonitoringApiClient.AssignGenericVehicleType(requestArgs)
            .then(async (res) => {
                if (this._isMounted) {
                    return await this.refreshAfterVehicleAssignementAction(res?.data);
                }

                return stateFragment;
            });
    }

    handleAssignTransporterVehicleType = async (vehicleTypeId: string, transporterId: string, transportPurchasePriceDatesLightModel: TransportPurchasePriceDatesLightModel[], vehicleTypeGroupId: string): Promise<void> => {
        const planningVehicle: PlanningVehicleTripsLightModelExtended = this.getPlanningVehicle();
        if (!planningVehicle)
            return;

        if (planningVehicle.isCanceled) {
            const message = BusinessErrors.GetError({ type: 'PlanningVehicleError', name: 'ActionCannotBePerformedOnACanceledPlanningVehicle' });
            ToastService.showErrorToast(message);
            return;
        }

        if (planningVehicle.hasAnyProvisionings && (planningVehicle.transporterId !== transporterId || planningVehicle.vehicleTypeId !== vehicleTypeId)) {
            const message = BusinessErrors.GetError({ type: 'PlanningVehicleError', name: 'CannotChangeTransporterVehicleTypeWhenPlanningVehicleHasProvisionings' });
            ToastService.showErrorToast(message);
            return;
        }

        if (!this.checkInProgressOrDeliveredTrips(planningVehicle)) {
            const confirmationMessages: Array<string> = [];

            if (planningVehicle.hasOrderLine) {
                confirmationMessages.push("Le camion fait l'objet d'une confirmation ou a été confirmé par le transporteur.");
            }

            if (planningVehicle.hasAtLeastOneDeliveryWithSpecificPrice && transporterId !== planningVehicle.transporterId) {
                confirmationMessages.push("Le camion est associé à un Tarif spécifique.");
            }

            if (confirmationMessages.length > 0) {
                this.setState({
                    showConfirmationPlanningVehicleModal: true,
                    confirmationMessage: [...confirmationMessages, "", "", "Confirmez-vous l'action ?"],
                    actionToBeConfirmed: ConfirmableAction.AssignTransporterVehicleType,
                    selectedVehicleTypeId: vehicleTypeId,
                    selectedVehicleTypeGroupId: vehicleTypeGroupId,
                    transporterId: transporterId,
                    transportPuchasesPriceDatesLightModel: transportPurchasePriceDatesLightModel,
                });

                return;
            }

            this.setState({
                loadingDataScheduler: true
            })

            const newState = await this.confirmAssignTransportersVehicleType(vehicleTypeId, transporterId);
            this.updateByVehicleTypeGroupIds(vehicleTypeGroupId, newState);
            this.setState(newState);
        }
    }

    confirmAssignTransportersVehicleType = async (vehicleTypeId: string, transporterId: string, planningVehicle?: PlanningVehicleTripsLightModelExtended): Promise<OperationalMonitoringViewState> => {
        const stateFragment: OperationalMonitoringViewState = null;
        if (!planningVehicle) {
            const vehicle = this.getPlanningVehicle();
            if (!vehicle)
                return stateFragment;

            planningVehicle = vehicle;
        }

        const requestArgs: AssignTransporterVehicleTypeRequestArgs = {
            planningId: planningVehicle.planningId,
            planningVehicleId: planningVehicle.planningVehicleId,
            transporterId: transporterId,
            vehicleTypeId: vehicleTypeId,
            hasConfirmedExistingSpecificPrices: planningVehicle.hasAtLeastOneDeliveryWithSpecificPrice,
            hasConfirmedExistingTransporterOrderLine: planningVehicle.hasOrderLine
        };

        return await OperationalMonitoringApiClient.AssignTransporterVehicleType(requestArgs)
            .then(async (res) => {
                if (this._isMounted) {
                    return await this.refreshAfterVehicleAssignementAction(res?.data);
                }

                return stateFragment;
            });
    }

    handleAssignInternalVehicle = async (transporterId: string, vehicleId: number, vehicleTypeId: string, equipmentId: string, driverId: number, licencePlate: string, internalVehiclesChoicesLightModel: InternalVehiclesChoiceLightModel[], vehicleTypeGroupId: string): Promise<void> => {
        const planningVehicle: PlanningVehicleTripsLightModelExtended = this.getPlanningVehicle();
        if (!planningVehicle)
            return;

        if (planningVehicle.isCanceled) {
            const message = BusinessErrors.GetError({ type: 'PlanningVehicleError', name: 'ActionCannotBePerformedOnACanceledPlanningVehicle' });
            ToastService.showErrorToast(message);
            return;
        }

        if (planningVehicle.hasAnyProvisionings && (planningVehicle.transporterId !== transporterId || planningVehicle.vehicleTypeId !== vehicleTypeId)) {
            const message = BusinessErrors.GetError({ type: 'PlanningVehicleError', name: 'CannotChangeTransporterVehicleTypeWhenPlanningVehicleHasProvisionings' });
            ToastService.showErrorToast(message);
            return;
        }

        if (!this.checkInProgressOrDeliveredTrips(planningVehicle)) {
            const confirmationMessages: Array<string> = [];

            if (planningVehicle.hasOrderLine == true) {
                confirmationMessages.push("Le camion fait l'objet d'une confirmation ou a été confirmé par le transporteur.");
            }

            if (planningVehicle.hasAtLeastOneDeliveryWithSpecificPrice) {
                confirmationMessages.push("Les tarifs spécifiques seront supprimés.");
            }

            if (confirmationMessages.length > 0) {
                this.setState({
                    showConfirmationPlanningVehicleModal: true,
                    actionToBeConfirmed: ConfirmableAction.AssignInternalVehicle,
                    confirmationMessage: [...confirmationMessages, "", "", "Confirmez-vous l'action ?"],
                    vehicleId: vehicleId,
                    transporterId: transporterId,
                    selectedVehicleTypeId: vehicleTypeId,
                    selectedVehicleTypeGroupId: vehicleTypeGroupId,
                    equipmentId: equipmentId,
                    licencePlate: licencePlate,
                    driverId: driverId,
                    internalVehiclesChoicesLightModel: internalVehiclesChoicesLightModel
                });

                return;
            }

            this.setState({
                loadingDataScheduler: true
            })

            const newState = await this.confirmAssignInternalVehicle(transporterId, vehicleId, vehicleTypeId, driverId, licencePlate, planningVehicle);
            this.updateByVehicleTypeGroupIds(vehicleTypeGroupId, newState);
            this.setState(newState);
        }
    }

    confirmAssignInternalVehicle = async (transporterId: string, vehicleId: number, vehicleTypeId: string, driverId: number, licencePlate: string, planningVehicle?: PlanningVehicleTripsLightModelExtended): Promise<OperationalMonitoringViewState> => {
        const stateFragment: OperationalMonitoringViewState = null;
        if (!planningVehicle) {
            const vehicle = this.getPlanningVehicle();
            if (!vehicle)
                return stateFragment;

            planningVehicle = vehicle;
        }

        const requestArgs: AssignInternalVehicleRequestArgs = {
            planningId: planningVehicle.planningId,
            planningVehicleId: planningVehicle.planningVehicleId,
            transporterId: transporterId,
            vehicleId: vehicleId,
            driverId: driverId,
            vehicleTypeId: vehicleTypeId,
            hasConfirmedExistingSpecificPrices: planningVehicle.hasAtLeastOneDeliveryWithSpecificPrice,
            hasConfirmedExistingTransporterOrderLine: planningVehicle.hasOrderLine
        };

        return await OperationalMonitoringApiClient.AssignInternalVehicle(requestArgs)
            .then(async (res) => {
                if (this._isMounted) {
                    return await this.refreshAfterVehicleAssignementAction(res?.data, true, this.state.date, licencePlate);
                }

                return stateFragment;
            });
    }

    handleAssignDailyPlanningVehicle = async (dailyPlanningVehicleId: string, vehicleId: number, licencePlate: string, dailyTransporterId: string, expectedVehicleTypeId: string, vehicleTypeGroupId: string): Promise<void> => {
        const planningVehicle: PlanningVehicleTripsLightModelExtended = this.getPlanningVehicle();
        if (!planningVehicle)
            return;

        if (planningVehicle.isCanceled) {
            const message = BusinessErrors.GetError({ type: 'PlanningVehicleError', name: 'ActionCannotBePerformedOnACanceledPlanningVehicle' });
            ToastService.showErrorToast(message);
            return;
        }

        if (planningVehicle.hasAnyProvisionings && (planningVehicle.transporterId !== dailyTransporterId || planningVehicle.vehicleTypeId !== expectedVehicleTypeId)) {
            const message = BusinessErrors.GetError({ type: 'PlanningVehicleError', name: 'CannotChangeTransporterVehicleTypeWhenPlanningVehicleHasProvisionings' });
            ToastService.showErrorToast(message);
            return;
        }

        if (!this.checkInProgressOrDeliveredTrips(planningVehicle)) {
            const confirmationMessages: Array<string> = [];
            if (planningVehicle.hasOrderLine == true) {
                confirmationMessages.push("Le camion fait l'objet d'une confirmation ou a été confirmé par le transporteur.");
            }

            if (planningVehicle.hasAtLeastOneDeliveryWithSpecificPrice && planningVehicle.transporterId != dailyTransporterId) {
                confirmationMessages.push(dailyTransporterId == null ? "Les tarifs spécifiques seront supprimés." : "Le camion est associé à un Tarif spécifique.");
            }

            if (confirmationMessages.length > 0) {
                this.setState({
                    showConfirmationPlanningVehicleModal: true,
                    confirmationMessage: [...confirmationMessages, "", "", "Confirmez-vous l'action ?"],
                    actionToBeConfirmed: ConfirmableAction.AssignDailyPlanningVehicle,
                    vehicleId: vehicleId,
                    licencePlate: licencePlate,
                    dailyPlanningVehicleId: dailyPlanningVehicleId,
                    selectedVehicleTypeGroupId: vehicleTypeGroupId
                });

                return;
            }

            this.setState({
                loadingDataScheduler: true
            })

            const newState = await this.assignDailyPlanningVehicle(dailyPlanningVehicleId, vehicleId, licencePlate, planningVehicle);
            this.updateByVehicleTypeGroupIds(vehicleTypeGroupId, newState);
            this.setState(newState);
        }
    }

    assignDailyPlanningVehicle = async (dailyPlanningVehicleId: string, dailyVehicleId: number, licencePlate: string, planningVehicle?: PlanningVehicleTripsLightModelExtended): Promise<OperationalMonitoringViewState> => {
        const stateFragment: OperationalMonitoringViewState = null;
        if (!planningVehicle) {
            const vehicle = this.getPlanningVehicle();
            if (!vehicle)
                return stateFragment;

            planningVehicle = vehicle;
        }

        const requestArgs: AssignDailyPlanningVehicleRequestArgs = {
            planningId: planningVehicle.planningId,
            planningVehicleId: planningVehicle.planningVehicleId,
            dailyVehicleId: dailyVehicleId,
            dailyPlanningVehicleId: dailyPlanningVehicleId,
            hasConfirmedExistingSpecificPrices: planningVehicle.hasAtLeastOneDeliveryWithSpecificPrice,
            hasConfirmedExistingTransporterOrderLine: planningVehicle.hasOrderLine
        };

        return await OperationalMonitoringApiClient.AssignDailyPlanningVehicle(requestArgs)
            .then(async (res) => {
                if (this._isMounted) {
                    return await this.refreshAfterVehicleAssignementAction(res?.data, true, this.state.date, licencePlate);
                }

                return stateFragment;
            });
    }

    handleAssignContractedVehicle = async (contractualPurchasePriceId: number, transporterId: string, vehicleTypeGroupId: string): Promise<void> => {
        const planningVehicle: PlanningVehicleTripsLightModelExtended = this.getPlanningVehicle();
        if (!planningVehicle)
            return;

        if (planningVehicle.isCanceled) {
            const message = BusinessErrors.GetError({ type: 'PlanningVehicleError', name: 'ActionCannotBePerformedOnACanceledPlanningVehicle' });
            ToastService.showErrorToast(message);
            return;
        }

        if (planningVehicle.hasAnyProvisionings) {
            const message = BusinessErrors.GetError({ type: 'PlanningVehicleError', name: 'CannotChangeTransporterVehicleTypeWhenPlanningVehicleHasProvisionings' });
            ToastService.showErrorToast(message);
            return;
        }

        if (!this.checkInProgressOrDeliveredTrips(planningVehicle)) {
            const confirmationMessages: Array<string> = [];

            if (planningVehicle.hasOrderLine) {
                confirmationMessages.push("Le camion fait l'objet d'une confirmation ou a été confirmé par le transporteur.");
            }

            if (planningVehicle.hasAtLeastOneDeliveryWithSpecificPrice && transporterId !== planningVehicle.transporterId) {
                confirmationMessages.push("Le camion est associé à un Tarif spécifique.");
            }

            if (confirmationMessages.length > 0) {
                this.setState({
                    showConfirmationPlanningVehicleModal: true,
                    confirmationMessage: [...confirmationMessages, "", "", "Confirmez-vous l'action ?"],
                    actionToBeConfirmed: ConfirmableAction.AssignContractualPricedTransporterVehicle,
                    contractualPurchasePriceId: contractualPurchasePriceId,
                    selectedVehicleTypeGroupId: vehicleTypeGroupId
                });

                return;
            }
        }

        this.setState({
            loadingDataScheduler: true
        })

        const newState = await this.confirmAssignContractualPricedTransporterVehicleType(contractualPurchasePriceId, planningVehicle);
        this.updateByVehicleTypeGroupIds(vehicleTypeGroupId, newState);
        this.setState(newState);
    }

    confirmAssignContractualPricedTransporterVehicleType = async (contractualPurchasePriceId: number, planningVehicle?: PlanningVehicleTripsLightModelExtended): Promise<OperationalMonitoringViewState> => {
        const stateFragment: OperationalMonitoringViewState = null;
        if (!planningVehicle) {
            const vehicle = this.getPlanningVehicle();
            if (!vehicle)
                return stateFragment;

            planningVehicle = vehicle;
        }

        const requestArgs: AssignContractualPricedTransporterVehicleTypeRequestArgs = {
            planningId: planningVehicle.planningId,
            planningVehicleId: planningVehicle.planningVehicleId,
            contractualPurchasePriceId: contractualPurchasePriceId,
            hasConfirmedExistingSpecificPrices: planningVehicle.hasAtLeastOneDeliveryWithSpecificPrice,
            hasConfirmedExistingTransporterOrderLine: planningVehicle.hasOrderLine
        };

        return await OperationalMonitoringApiClient.AssignContractualPricedTransporterVehicleType(requestArgs)
            .then(async (res) => {
                if (this._isMounted) {
                    return await this.refreshAfterVehicleAssignementAction(res?.data);
                }

                return stateFragment;
            });
    }

    handleRemoveContractualPurchasePriceFromPlanningVehicle = async (planningVehicleId: string): Promise<void> => {
        const newState = await this.removeContractualPurchasePriceFromPlanningVehicle(planningVehicleId);
        this.setState(newState);
    }

    removeContractualPurchasePriceFromPlanningVehicle = async (planningVehicleId: string): Promise<OperationalMonitoringViewState> => {
        const stateFragment: OperationalMonitoringViewState = null;

        return await OperationalMonitoringApiClient.RemoveContractualPurchasePriceFromPlanningVehicle(planningVehicleId)
            .then(async (res) => {
                if (this._isMounted) {
                    return await this.refreshAfterVehicleAssignementAction(res?.data);
                }

                return stateFragment;
            });
    }

    refreshAfterVehicleAssignementAction = async (result: WebAppActionResult, includeCustomWarnings?: boolean, date?: Date, licencePlate?: string): Promise<OperationalMonitoringViewState> => {
        const newState: OperationalMonitoringViewState = { ...this.state };
        return await this.getSchedulerRelatedActionRefreshData()
            .then((results) => {
                newState.refreshDailyVehicles = true;
                newState.refreshContractualVehicles = true;
                newState.getUnusedReservationsChanged = !this.state.getUnusedReservationsChanged;
                newState.showConfirmationPlanningVehicleModal = false;
                newState.confirmationMessage = [];
                newState.loadingDataScheduler = false;

                if (includeCustomWarnings) {
                    this.showCustomBusinessMessages(result, date, licencePlate);
                }
                else {
                    this.showBusinessMessages(result);
                }
                this.copyStateFragments(newState, results[0], results[1], results[2]);

                const fromTime = newState.date.stripTime();
                this.updateSchedulerInstanceAfterDataRefresh(fromTime);

                return newState;
            });
    }

    refreshAfterRemovePlanningVehicleAction = async (result: WebAppActionResult, includeCustomWarnings?: boolean, date?: Date): Promise<OperationalMonitoringViewState> => {
        const newState: OperationalMonitoringViewState = { ...this.state };
        return await this.getSchedulerRelatedActionRefreshData()
            .then((results) => {
                newState.refreshDailyVehicles = true;
                newState.refreshContractualVehicles = true;
                newState.getUnusedReservationsChanged = !this.state.getUnusedReservationsChanged;
                newState.showConfirmationPlanningVehicleModal = false;
                newState.confirmationMessage = [];

                newState.loadingDataScheduler = false;

                ToastService.showSuccessToast("Le camion a été supprimé avec les tours associés");

                if (includeCustomWarnings) {
                    this.showCustomBusinessMessages(result, date);
                }
                else {
                    this.showBusinessMessages(result);
                }
                this.copyStateFragments(newState, results[0], results[1], results[2]);

                const fromTime = newState.date.stripTime();
                this.updateSchedulerInstanceAfterDataRefresh(fromTime);

                return newState;
            });
    }

    handleRefreshBeforeOpeningSendingSmsModal = async (): Promise<OperationalMonitoringViewState> => {
        const newState: OperationalMonitoringViewState = { ...this.state };
        return await this.getSchedulerRelatedActionRefreshData()
            .then((results) => {
                this.copyStateFragments(newState, results[0], results[1], results[2]);

                const fromTime = newState.date.stripTime();
                this.updateSchedulerInstanceAfterDataRefresh(fromTime);

                return newState;
            });
    }

    handleRefreshAfterSmsModalsClosed = async (): Promise<OperationalMonitoringViewState> => {
        const newState: OperationalMonitoringViewState = { ...this.state };
        return await this.getSchedulerRelatedActionRefreshData()
            .then((results) => {
                if (newState.isDriverSmsNotificationsDialogOpened) {
                    newState.isDriverSmsNotificationsDialogOpened = false;
                }
                if (newState.isSummaryOfSentSmsMessagesModalOpened) {
                    newState.isSummaryOfSentSmsMessagesModalOpened = false;
                }

                newState.driverSmsPhoneNumberIsMobile = false;

                this.copyStateFragments(newState, results[0], results[1], results[2]);

                const fromTime = newState.date.stripTime();
                this.updateSchedulerInstanceAfterDataRefresh(fromTime);

                return newState;
            });
    }

    handleRefreshAfterResizeTripToNewDeliveryTime = async (result: WebAppActionResult): Promise<void> => {
        const newState = await this.refreshAfterSchedulerTripsAction(result);
        this.setState(newState);
    }

    handleRefreshAfterCreateTripFromFlow = async (result: WebAppActionResult): Promise<void> => {
        const newState = await this.refreshAfterSchedulerTripsAction(result);
        this.setState(newState);
    }

    handleMoveTripsToNewPlanningVehicle = async (planningVehicleId: string): Promise<void> => {
        const scheduler = () => this.schedulerRef.current?.instance as Scheduler;

        const trips = new Array<TripsMoveToNewPlanningVehicleRequestArgsExtended>();
        const deliveryTrips = this.state.deliveryTripsSelected;
        const resourceData = [...this.state.resources];

        //On ne déplace que les tours déplacables
        deliveryTrips.forEach((element: EventModelData) => {
            const eventStatus = element.eventStatus;
            const dataResourceId = planningVehicleId;
            const originalDataResourceId = element.resourceId;

            const oldResource = resourceData.find(x => x.id === originalDataResourceId);
            const newResource = resourceData.find(x => x.id === dataResourceId);

            const trip: TripsMoveToNewPlanningVehicleRequestArgsExtended = {
                transportRequestId: element.transportRequestId,
                deliveryTripId: element.deliveryTripId,
                transporterId: newResource.transporterId,
                vehicleId: newResource.vehicle,
                vehicleTypeId: newResource.vehicleTypeId,
                planningVehicleId: planningVehicleId,
                previousPlanningVehicleId: oldResource.planningVehicleId,
                priceKind: element.priceKind,
                oldTransporterId: element.transporterId,
                transportFlowId: element.transportFlowId
            }
            trips.push(trip);

            //Déplacer le tour sur la nouvelle ressource
            //TODO ODA CMA à revoir l'utilité de ces 3 lignes de tests et documenter si nécéssaire
            //On pense c'est que pour éviter des erreurs si entre temps y'a un refresh du scheduler(A confirmer)
            if (oldResource && oldResource.trips && oldResource.trips.length > 0) {
                const oldValue = oldResource.trips.find(t => t.deliveryTripId === element.deliveryTripId);
                if (oldValue) {
                    remove(oldResource.trips, (s: TripLightModel) => {
                        return s.deliveryTripId === element.deliveryTripId;
                    });

                    if (!newResource.trips)
                        newResource.trips = [];

                    newResource.trips.push(oldValue);
                }
            }

            const tripStatusAfterMove = this.getTripStatusAfterMove(newResource.transporterId, newResource.vehicle, newResource.planningVehicleId, oldResource.planningVehicleId, element.eventStatus);

            element.resourceId = dataResourceId;
            element.endDate = new Date(element.endDate);
            element.startDate = new Date(element.startDate);

            if (this.canMoveDeliveryTripByStatus(eventStatus)) {
                element.eventStatus = tripStatusAfterMove;
            }
        });

        if (trips.length > 0) {
            const group: _.Dictionary<TripsMoveToNewPlanningVehicleRequestArgsExtended[]> = groupBy(trips, x => x.previousPlanningVehicleId);
            const groupedSelectedDeliveryTripsByRessourceId = map(group, (value, key) => ({ previousPlanningVehicleId: key, deliveryTrips: value }));
            let atLeastVehiclePlanningAllTripsWillRemoved = false;
            let hasSpecificPriceDragWithoutTransporter = false;
            let hasSpecificPriceDragWithTransporter = false;
            each(groupedSelectedDeliveryTripsByRessourceId, (item) => {
                item.deliveryTrips.forEach((itm) => {
                    if (itm.priceKind != null && itm.priceKind != "" && itm.transporterId == null) {
                        hasSpecificPriceDragWithoutTransporter = true;
                    }
                    else if (itm.priceKind != null && itm.priceKind != "" && itm.transporterId != itm.oldTransporterId && itm.transporterId != null) {
                        hasSpecificPriceDragWithTransporter = true;
                    }
                });

                const planningVehicle = resourceData.find(x => x.planningVehicleId === item.previousPlanningVehicleId && x.transporterId !== null);
                if (planningVehicle && planningVehicle.trips.length === 0) {
                    atLeastVehiclePlanningAllTripsWillRemoved = true;
                }
            });

            if (hasSpecificPriceDragWithTransporter) {
                this.showConfirmationMoveTripsWithTransporter(trips);
                scheduler().readOnly = false;
                return;
            }

            if (hasSpecificPriceDragWithoutTransporter) {
                this.showConfirmationMoveTripsWithoutTransporter(trips);
                scheduler().readOnly = false;
                return;
            }

            if (atLeastVehiclePlanningAllTripsWillRemoved) {
                this.showConfirmationMoveWhenPlanningVehicleWithTransporter(trips);
                scheduler().readOnly = false;
                return;
            }

            this.setState({
                loadingDataScheduler: true
            });

            const requestArgs = this.createTripsMoveToNewPlanningVehicleRequestArgs(trips);
            const newState = await this.moveTripsToNewPlanningVehicle(requestArgs, false);
            this.setState(newState);
        }

        scheduler().readOnly = false;
    }

    handleConfirmationMoveTrips = async (hasConfirmedSpecificPriceRemoval: boolean): Promise<void> => {
        const requestArgs = this.createTripsMoveToNewPlanningVehicleRequestArgs(this.state.pendingTripsToMoveToNewPlanningVehicle);
        const newState = await this.moveTripsToNewPlanningVehicle(requestArgs, hasConfirmedSpecificPriceRemoval);
        newState.showConfirmationSchedulerTripsAction = false;
        this.setState(newState);
    }

    moveTripsToNewPlanningVehicle = async (requestArgs: TripsMoveToNewPlanningVehicleRequestArgs, hasConfirmedSpecificPriceRemoval: boolean): Promise<OperationalMonitoringViewState> => {
        return await OperationalMonitoringApiClient.MoveTripsToNewPlanningVehicle(requestArgs, hasConfirmedSpecificPriceRemoval)
            .then(async res => {
                return await this.refreshAfterSchedulerTripsAction(res?.data);
            });
    }

    handleConfirmDropTrips = async (hasConfirmedSpecificPriceRemoval: boolean): Promise<void> => {
        const requestArgs = this.createTripDragToNewLoadingTimeRequestArgs(this.state.pendingTripsToDragToNewLoadingTime);
        const newState = await this.dragTripsToNewLoadingTime(requestArgs, hasConfirmedSpecificPriceRemoval);
        newState.showConfirmationSchedulerTripsAction = false;
        this.setState(newState);
    }

    handleTripsDropToNewLoadingTime = async (event: any): Promise<void> => {
        const scheduler = () => this.schedulerRef.current?.instance as Scheduler;

        //command update trip startdate/endate
        const trips = new Array<TripDragToNewLoadingTimeRequestArgsExtended>(),
            records = event.eventRecords;

        const resourceStoreIds = map(this.state.resources, r => r.planningVehicleId);
        const recordsIds = map(records, r => r.resourceId);

        const allResourcesArePlanningVehicles = recordsIds.every(r => resourceStoreIds.findIndex(x => x == r) != -1);
        if (!allResourcesArePlanningVehicles) {
            scheduler().events = this.state.events;
            scheduler().resources = this.state.resources;
            scheduler().readOnly = false;

            ToastService.showErrorToast("Action annulée car le nombre de camions cibles est différent du nombre de camions sources");

            return;
        }

        records.forEach((element) => {
            const eventStatus = element.data.eventStatus,
                dataResourceId = element.data.resourceId,
                originalDataResourceId = element.originalData.resourceId;

            const trip: TripDragToNewLoadingTimeRequestArgsExtended = {
                transportRequestId: element.data.transportRequestId,
                deliveryTripId: element.data.deliveryTripId,
                newLoadingTime: (element.data.startDate as Date).toJsonTimezoned(),
                driverId: this.state.resources.find(x => x.id === element.data.resourceId).driverId,
                driverPhoneNumber: this.state.resources.find(x => x.id === element.data.resourceId).driverPhoneNumber,
                transporterId: this.state.resources.find(x => x.id === element.data.resourceId).transporterId,
                vehicleId: this.state.resources.find(x => x.id === element.data.resourceId).vehicle,
                vehicleTypeId: this.state.resources.find(x => x.id === element.data.resourceId).vehicleTypeId,
                planningVehicleId: dataResourceId,
                previousPlanningVehicleId: element.originalData.planningVehicleId,
                priceKind: element.data.priceKind,
                oldTransporterId: element.data.transporterId,
                transportFlowId: element.data.transportFlowId
            }

            trips.push(trip);

            //Déplacer le tour sur la nouvelle ressource
            const resources = [...this.state.resources];
            const oldResource = resources.find(t => t.id === originalDataResourceId);
            const newResource = resources.find(t => t.id === dataResourceId);
            //TODO ODA CMA à revoir l'utilité de ces 3 lignes de tests et documenter si nécéssaire
            //On pense c'est que pour éviter des erreurs si entre temps y'a un refresh du scheduler(A confirmer)
            if (oldResource && oldResource.trips && oldResource.trips.length > 0) {
                const oldValue = oldResource.trips.find(t => t.deliveryTripId === element.data.deliveryTripId);
                if (oldValue) {
                    remove(oldResource.trips, (s: TripLightModel) => {
                        return s.deliveryTripId === element.data.deliveryTripId;
                    });

                    if (!newResource.trips)
                        newResource.trips = [];

                    newResource.trips.push(oldValue);
                }
            }

            const tripStatusAfterMove = this.getTripStatusAfterMove(trip.transporterId, trip.vehicleId, newResource.planningVehicleId, oldResource.planningVehicleId, element.data.eventStatus);
            element.resourceId = dataResourceId;
            element.endDate = new Date(element.data.endDate);
            element.startDate = new Date(element.data.startDate);

            if (this.canMoveDeliveryTripByStatus(eventStatus))
                element.eventStatus = tripStatusAfterMove;

        });

        if (trips.length > 0) {
            const group: _.Dictionary<TripDragToNewLoadingTimeRequestArgsExtended[]> = groupBy(trips, x => x.previousPlanningVehicleId);
            const groupedSelectedDeliveryTripsByRessourceId = map(group, (value, key) => ({ previousPlanningVehicleId: key, deliveryTrips: value }));
            let atLeastVehiclePlanningAllTripsWillRemoved = false;
            let hasSpecificPriceDragWithoutTransporter = false;
            let hasSpecificPriceDragWithTransporter = false;
            each(groupedSelectedDeliveryTripsByRessourceId, (item) => {
                item.deliveryTrips.forEach((itm) => {
                    if (itm.priceKind != null && itm.priceKind != "" && itm.transporterId == null) {
                        hasSpecificPriceDragWithoutTransporter = true;
                    }
                    else if (itm.priceKind != null && itm.priceKind != "" && itm.transporterId != itm.oldTransporterId && itm.transporterId != null) {
                        hasSpecificPriceDragWithTransporter = true;
                    }
                });

                const planningVehicle = find(this.state.resources, x => x.planningVehicleId === item.previousPlanningVehicleId && x.transporterId !== null);
                if (planningVehicle && planningVehicle.trips.length === 0) {
                    atLeastVehiclePlanningAllTripsWillRemoved = true;
                }
            });

            if (hasSpecificPriceDragWithTransporter) {
                this.showConfirmationDropWithTransporter(trips);
                scheduler().readOnly = false;
                return;
            }

            if (hasSpecificPriceDragWithoutTransporter) {
                this.showConfirmationDropWithoutTransporter(trips);
                scheduler().readOnly = false;
                return;
            }

            if (atLeastVehiclePlanningAllTripsWillRemoved) {
                this.showConfirmationDropWhenPlanningVehicleWithTransporter(trips);
                scheduler().readOnly = false;
                return;
            }

            this.setState({
                loadingDataScheduler: true
            });

            const requestArgs = this.createTripDragToNewLoadingTimeRequestArgs(trips);
            const newState = await this.dragTripsToNewLoadingTime(requestArgs, false);
            this.setState(newState);
        }

        scheduler().readOnly = false;
    }

    dragTripsToNewLoadingTime = async (requestArgs: Array<TripDragToNewLoadingTimeRequestArgs>, hasConfirmedSpecificPriceRemoval: boolean): Promise<OperationalMonitoringViewState> => {
        return await OperationalMonitoringApiClient.DragTripsToNewLoadingTime(requestArgs, hasConfirmedSpecificPriceRemoval)
            .then(async res => {
                return await this.refreshAfterSchedulerTripsAction(res?.data);
            });
    }

    refreshAfterSchedulerTripsAction = async (result: WebAppActionResult): Promise<OperationalMonitoringViewState> => {
        const newState: OperationalMonitoringViewState = { ...this.state };
        return await this.getSchedulerRelatedActionRefreshData()
            .then((results) => {
                newState.deliveryTripsSelected = [];
                newState.loadingDataScheduler = false;

                this.showBusinessMessages(result);
                this.copyStateFragments(newState, results[0], results[1], results[2]);

                const fromTime = newState.date.stripTime();
                this.updateSchedulerInstanceAfterDataRefresh(fromTime);

                return newState;
            });
    }

    refreshAfterCancelPlanningVehicleAction = async (result: WebAppActionResult): Promise<OperationalMonitoringViewState> => {
        const newState: OperationalMonitoringViewState = { ...this.state };

        return await this.getSchedulerRelatedActionRefreshData()
            .then((results) => {
                newState.isCancelPlanningVehicleDialogOpened = false;
                newState.isCancelDeliveryTripsDialogOpened = false;
                newState.existenceOfInProgressOrFinishedTripHasBeenConfirmed = false;
                newState.planningVehicleIdToCancel = null;
                newState.loadingDataScheduler = false;

                this.showBusinessMessages(result);
                this.copyStateFragments(newState, results[0], results[1], results[2]);

                const fromTime = newState.date.stripTime();
                this.updateSchedulerInstanceAfterDataRefresh(fromTime);

                return newState;
            });
    }

    refreshAfterReinstatePlanningVehicleAction = async (result: WebAppActionResult): Promise<OperationalMonitoringViewState> => {
        const newState: OperationalMonitoringViewState = { ...this.state };

        return await this.getSchedulerRelatedActionRefreshData()
            .then((results) => {
                newState.loadingDataScheduler = false;

                this.showBusinessMessages(result);
                this.copyStateFragments(newState, results[0], results[1], results[2]);

                const fromTime = newState.date.stripTime();
                this.updateSchedulerInstanceAfterDataRefresh(fromTime);

                return newState;
            });
    }

    handleSpecificPriceChange = async (deliveryTripId: number, transportRequestId: string, propertyName: string, priceKind: string, price?: number): Promise<void> => {
        if ((priceKind != '' && price != null) || (!priceKind && !price)) {
            const delTripchange: DeliveryTripChangeRequestArgs = {
                deliveryTripId: deliveryTripId,
                transportRequestId: transportRequestId,
                propertyName: propertyName,
                expectedQuantity: null,
                loadingTime: null,
                deliveryTime: null,
                instructions: null,
                price: priceKind ? price : null,
                priceKind: price ? priceKind : null,
                cancellationRemarks: null
            };

            OperationalMonitoringApiClient.UpdateDeliveryTrip(delTripchange)
                .then(async res => {
                    const data = res?.data;
                    const errors = BusinessErrors.Get(data);
                    if (errors.length > 0) {
                        ToastService.showErrorToast("", errors);

                        this.setState({
                            loadingDataScheduler: false
                        });
                        return;
                    }

                    const newState = await this.refreshAfterDeliveryTripPropertyChanged(data);
                    this.setState(newState);
                });
        }
    }

    handlePlannedQuantityChange = async (transportRequestId: string, deliveryTripId: number, quantity: number): Promise<void> => {
        const delTripchange: DeliveryTripChangeRequestArgs = {
            deliveryTripId: deliveryTripId,
            transportRequestId: transportRequestId,
            propertyName: "EXPECTEDQUANTITY",
            expectedQuantity: quantity,
            loadingTime: null,
            deliveryTime: null,
            instructions: null,
            price: null,
            priceKind: null,
            cancellationRemarks: null
        };

        return OperationalMonitoringApiClient.UpdateDeliveryTrip(delTripchange)
            .then(async res => {
                const data = res?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);

                    this.setState({
                        loadingDataScheduler: false
                    });
                    return;
                }

                const newState = await this.refreshAfterDeliveryTripPropertyChanged(data);
                this.setState(newState);
            });
    }

    refreshAfterDeliveryTripPropertyChanged = async (result: WebAppActionResult): Promise<OperationalMonitoringViewState> => {
        const newState: OperationalMonitoringViewState = { ...this.state };
        return await this.getSchedulerRelatedActionRefreshData()
            .then((results) => {
                this.showBusinessWarningsMessages(result);
                this.copyStateFragments(newState, results[0], results[1], results[2]);

                const fromTime = newState.date.stripTime();
                this.updateSchedulerInstanceAfterDataRefresh(fromTime);

                return newState;
            });
    }

    handleConfirmRemoveTrips = async (hideFlow: boolean): Promise<void> => {
        const deliveryTripsSelected: Array<DeliveryTripLongIdentifierRequestArgsExtended> = [];
        this.state.deliveryTripsSelected.forEach(e => {
            deliveryTripsSelected.push({
                transportRequestId: e.transportRequestId,
                deliveryTripId: e.deliveryTripId,
                planningId: e.planningId,
                planningVehicleId: e.planningVehicleId,
                logisticsUnitId: e.logisticsUnitId,
                transportFlowId: e.transportFlowId
            });
        });

        this.setState({
            loadingDataScheduler: true
        });

        const requestArgs: RemoveDeliveryTripsArgs = {
            hideFlow: hideFlow,
            deliveryTripsIdentifiers: deliveryTripsSelected
        };

        await OperationalMonitoringApiClient.RemoveDeliveryTrips(requestArgs)
            .then(async res => {
                const data = res?.data;
                const errors = BusinessErrors.GetEx(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);

                    this.setState({
                        loadingDataScheduler: false,
                        showConfirmationRemoveTripsModal: false,
                        showConfirmationRemoveAllPlanningVehicleTripsModal: false
                    });
                    return;
                }

                const { removedDeliveryTrips, flowsToHide } = data.extraResult;
                const newState = await this.refreshAfterSchedulerTripsDeleted(removedDeliveryTrips);
                this.setState(newState);

                if (flowsToHide && flowsToHide.length > 0) {
                    this.updateHideOrShowFlows(flowsToHide, true);
                }

                const scheduler = () => this.schedulerRef.current?.instance as Scheduler;
                if (scheduler().readOnly)
                    scheduler().readOnly = false;
            });
    }

    refreshAfterSchedulerTripsDeleted = async (removedTripsIds: number[]): Promise<OperationalMonitoringViewState> => {
        const newState: OperationalMonitoringViewState = { ...this.state };
        return await this.getSchedulerRelatedActionRefreshData()
            .then((results) => {
                const eventsData = [...newState.events];
                const resourcesData = [...newState.resources];

                if (removedTripsIds) {
                    removedTripsIds.forEach((tripId: number) => {
                        const planningVehicleId = eventsData.find(x => x.deliveryTripId === tripId).planningVehicleId;
                        //supprimer l'event de la liste des events dans le cas d'event de suppression si le deliveryTripId existe
                        remove(eventsData, (e: EventModelData) => e.deliveryTripId === tripId);

                        //supprimer l'event de la liste des trips des resources
                        const planningVehicle = resourcesData.find(x => x.planningVehicleId === planningVehicleId);
                        remove(planningVehicle.trips, (e: TripLightModel) => e.deliveryTripId === tripId);
                    });
                }

                ToastService.showSuccessToast("Les tours sélectionnés ont été supprimés avec succès");

                const existsEmptyVehicles: boolean = resourcesData.filter(r => !r.vehicle && !r.transporterId && !r.driverId && r.trips?.length == 0).length > 0;

                newState.loadingDataScheduler = false;
                newState.events = eventsData;
                newState.resources = resourcesData;
                newState.eventsVersion = this.state.eventsVersion + 1;
                newState.deliveryTripsSelected = [];
                newState.showConfirmationRemoveTripsModal = false;
                newState.showConfirmationRemoveAllPlanningVehicleTripsModal = false;
                newState.existsEmptyVehicles = existsEmptyVehicles;

                this.copyStateFragments(newState, results[0], results[1], results[2]);

                const fromTime = newState.date.stripTime();
                this.updateSchedulerInstanceAfterDataRefresh(fromTime);

                return newState;
            });
    }

    assignDeliveryTripStatus = async (tripStatus: DeliveryTripStatus, cancellationReason: DeliveryTripCancellationReason, cancellationRemarks: string): Promise<void> => {
        const deliveryTripsSelected: Array<DeliveryTripLongIdentifierRequestArgsExtended> = [];

        this.state.deliveryTripsSelected.forEach(e => {
            deliveryTripsSelected.push({
                transportRequestId: e.transportRequestId,
                deliveryTripId: e.deliveryTripId,
                planningId: e.planningId,
                planningVehicleId: e.planningVehicleId,
                logisticsUnitId: e.logisticsUnitId,
                transportFlowId: e.transportFlowId
            });
        });

        this.setState({
            loadingDataScheduler: true
        });

        const assignDeliveryTripsStatusArgs: AssignDeliveryTripsStatusArgs = {
            deliveryTripStatus: tripStatus,
            deliveryTripCancellationReason: cancellationReason,
            deliveryTripCancellationRemarks: cancellationRemarks,
            deliveryTripsIdentifiers: deliveryTripsSelected
        };

        await OperationalMonitoringApiClient.AssignDeliveryTripStatus(assignDeliveryTripsStatusArgs)
            .then(async res => {
                if (this._isMounted) {
                    const data = res.data;
                    const newState = await this.refreshAfterSchedulerTripsStatusChanged(data);
                    this.setState(newState);
                }
            });
    }

    handleAssignDeliveryTripStatus = async (newStatusSelected: DeliveryTripStatus): Promise<void> => {
        await this.assignDeliveryTripStatus(newStatusSelected, null, null);
    }

    handleSaveCancelDeliveryTripDialog = (cancellationReason: DeliveryTripCancellationReason, cancellationRemarks: string) => {
        this.assignDeliveryTripStatus(DeliveryTripStatus.canceled, cancellationReason, cancellationRemarks);
    }

    handleDeliveryTripsCancellation = () => {
        const allSelectedCancelled = this.state.deliveryTripsSelected.every(
            (deliveryTrip) => deliveryTrip.eventStatus === DeliveryTripStatus.canceled);
        if (allSelectedCancelled)
            return;
        this.setState({
            isCancelDeliveryTripsDialogOpened: true
        });
    }

    refreshAfterSchedulerTripsStatusChanged = async (result: WebAppActionResultEx<number[]>): Promise<OperationalMonitoringViewState> => {
        const newState: OperationalMonitoringViewState = { ...this.state };
        return await this.getSchedulerRelatedActionRefreshData()
            .then((results) => {
                this.showBussinessErrorsMessagesEx(result);
                this.copyStateFragments(newState, results[0], results[1], results[2]);
                newState.loadingDataScheduler = false;
                newState.isCancelDeliveryTripsDialogOpened = false;
                const fromTime = newState.date.stripTime();
                this.updateSchedulerInstanceAfterDataRefresh(fromTime);

                return newState;
            });
    }

    handleCloseTransportFlowFormAfterValidation = async (hasBusinessErrors: boolean, hasBusinessWarnings: boolean, warnings: string[], businessId: string, mode: string): Promise<void> => {
        if (hasBusinessErrors) {
            ToastService.showErrorToast(mode == updateMode ? `La modification du Flux ${businessId} a échoué. Veuillez contacter votre administrateur` : `La création du Flux a échoué. Veuillez contacter votre administrateur`);

            this.setState({
                isTransportFlowFormViewOpened: false
            });
            return;
        }

        const newState = await this.refreshAfterTransportFlowFormValidation(hasBusinessWarnings, warnings, businessId, mode);
        this.setState(newState);
    }

    refreshAfterTransportFlowFormValidation = async (hasBusinessWarnings: boolean, warnings: string[], businessId: string, mode: string): Promise<OperationalMonitoringViewState> => {
        const newState: OperationalMonitoringViewState = { ...this.state };
        return await this.getSchedulerRelatedActionRefreshData()
            .then((results) => {
                if (hasBusinessWarnings) {
                    ToastService.showWarningToast("", warnings);
                }

                ToastService.showSuccessToast(mode == updateMode ? `La modification du Flux ${businessId} s'est déroulée avec succès` : `La création du Flux ${businessId} s'est déroulée avec succès`);

                newState.isTransportFlowFormViewOpened = false;

                this.copyStateFragments(newState, results[0], results[1], results[2]);

                const fromTime = newState.date.stripTime();
                this.updateSchedulerInstanceAfterDataRefresh(fromTime);

                return newState;
            });
    }

    handleDuplicatePlanningVehicle = async (nbVehicles: number): Promise<void> => {
        const planningVehicle = this.getPlanningVehicle();

        const requestArgs: DuplicatePlanningVehicleRequestArgs = {
            planningVehicleId: planningVehicle.planningVehicleId,
            numberOfDuplicates: nbVehicles,
            deliveryTrips: planningVehicle.trips.filter(trip => trip.serviceKind !== TransportServiceKind.jobsiteVehicle).map(trip => {
                return {
                    transportRequestId: trip.transportRequestId,
                    deliveryTripId: trip.deliveryTripId,
                    transportFlowId: trip.transportFlowId
                };
            })
        };

        //TODO HGA : voir si l'usage de once sur l'event handler de click de bouton permet d'éviter ce setState
        this.setState({
            disableDuplicatePlanningVehicles: true
        });

        await OperationalMonitoringApiClient.DuplicatePlanningVehicle(requestArgs)
            .then(async res => {
                const data = res?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    this.setState({
                        isDialogDuplicatePlanningVehicleOpened: false,
                        disableDuplicatePlanningVehicles: false,
                        dialogTitle: ''
                    });
                    return;
                }
                else {
                    ToastService.showSuccessToast(`Numéro(s) camion(s) ajouté(s): ${data.extraResult ? data.extraResult.join(", ") : ""}`);
                }

                const newState = await this.refreshAfterDuplicatePlanningVehicle(data);
                this.setState(newState);
            });
    }

    refreshAfterDuplicatePlanningVehicle = async (result: WebAppActionResult): Promise<OperationalMonitoringViewState> => {
        const newState: OperationalMonitoringViewState = { ...this.state };
        return await this.getSchedulerRelatedActionRefreshData()
            .then((results) => {
                newState.isDialogDuplicatePlanningVehicleOpened = false;
                newState.disableDuplicatePlanningVehicles = false;
                newState.dialogTitle = '';

                this.showBusinessWarningsMessages(result);
                this.copyStateFragments(newState, results[0], results[1], results[2]);

                const fromTime = newState.date.stripTime();
                this.updateSchedulerInstanceAfterDataRefresh(fromTime);

                return newState;
            });
    }

    refreshAfterCreatePlanningVehicles = async (result: WebAppActionResult): Promise<OperationalMonitoringViewState> => {
        const newState: OperationalMonitoringViewState = { ...this.state };
        return await this.getSchedulerRelatedActionRefreshData()
            .then((results) => {
                newState.loadingDataScheduler = false;

                this.showBusinessWarningsMessages(result);
                this.copyStateFragments(newState, results[0], results[1], results[2]);

                const fromTime = newState.date.stripTime();
                this.updateSchedulerInstanceAfterDataRefresh(fromTime);

                return newState;
            });
    }

    refreshAfterRemoveEmptyPlanningVehicles = async (result: WebAppActionResult): Promise<OperationalMonitoringViewState> => {
        const newState: OperationalMonitoringViewState = { ...this.state };
        return await this.getSchedulerRelatedActionRefreshData()
            .then((results) => {
                newState.loadingDataScheduler = false;
                newState.deliveryTripsSelected = [];
                newState.showConfirmationRemoveEmptyPlanningVehiclesModal = false;
                newState.refreshDailyVehicles = true;
                newState.refreshContractualVehicles = true;

                this.showBusinessMessages(result, "Les camions vides ont été supprimés avec succès");
                this.copyStateFragments(newState, results[0], results[1], results[2]);

                const fromTime = newState.date.stripTime();
                this.updateSchedulerInstanceAfterDataRefresh(fromTime);

                return newState;
            });
    }

    refreshAfterRefreshButtonClicked = async (state: OperationalMonitoringViewState): Promise<OperationalMonitoringViewState> => {
        const newState: OperationalMonitoringViewState = { ...state };
        return await this.getSchedulerRelatedActionRefreshData()
            .then((results) => {
                newState.loadingDataScheduler = false;

                this.copyStateFragments(newState, results[0], results[1], results[2]);

                const fromTime = newState.date.stripTime();
                this.updateSchedulerInstanceAfterDataRefresh(fromTime);

                return newState;
            });
    }

    updateSchedulerInstanceAfterDataRefresh = (fromTime: Date): void => {
        const scheduler = () => this.schedulerRef.current?.instance as Scheduler;
        const scrollTop = scheduler()?.scrollTop;

        if (scrollTop > 0) {
            scheduler().scrollVerticallyTo(scrollTop, { animate: false });
        }

        this.setStartEndDateExpeditonScheduler(fromTime);
    }

    showBusinessMessages = (result: WebAppActionResult, successMessage?: string): void => {
        if (result) {
            const errors = BusinessErrors.Get(result);
            if (errors.length > 0) {
                ToastService.showErrorToast("", errors);
            }
            else {
                const warnings = BusinessWarnings.Get(result).filter(Utilities.distinct);
                const hasAnyCommandSucceeded = result.commandResults.some(r => !r.hasBusinessWarnings);

                //Si aucune commande n'est passé, on affiche les warnings en erreurs
                if (!hasAnyCommandSucceeded) {
                    ToastService.showErrorToast("", warnings);
                }
                else if (warnings.length > 0) {
                    ToastService.showWarningToast("", warnings);
                } else if (successMessage) {
                    ToastService.showSuccessToast(successMessage);
                }
            }
        }
    }

    showBusinessWarningsMessages = (result: WebAppActionResult): void => {
        if (result) {
            const warnings = BusinessWarnings.Get(result).filter(Utilities.distinct);
            if (warnings.length > 0) {
                ToastService.showWarningToast("", warnings);
            }
        }
    }

    showBussinessErrorsMessagesEx = (result: WebAppActionResultEx<number[]>): void => {
        if (result) {
            const errors = BusinessErrors.GetEx(result);
            if (errors.length > 0) {
                ToastService.showErrorToast("", errors);
            }
        }
    }

    showCustomBusinessMessages = (result: WebAppActionResult, date?: Date, licencePlate?: string): void => {
        if (result) {
            const errors: string[] = [];
            const commandResult = result.commandResults[0];
            if (commandResult.hasBusinessErrors == true) {
                commandResult.businessErrors.forEach(error => {
                    if (error.name == 'NoPriceIsAvailableForThisTransporter')
                        errors.push("Aucun tarif disponible pour ce transporteur sur l'AL " + commandResult.aggregateId + " à la date " + Date.getDateFromIsoString(date));
                    else if (error.name == 'ThisVehicleIsNotavailableForThisTransporter')
                        errors.push("Ce camion n'est pas disponible pour cette zone logistique pour l'AL " + commandResult.aggregateId + " à la date " + Date.getDateFromIsoString(date));
                    else if (error.name == 'VehicleAlreadyAssociatedOnPlanningDate')
                        errors.push("Le camion " + licencePlate + " est déjà associé");
                    else {
                        const errorText = BusinessErrors.GetError(error);
                        errors.push(errorText);
                    }
                });

                ToastService.showErrorToast("", errors);
            }
            else {
                const warnings = BusinessWarnings.Get(result).filter(Utilities.distinct);
                const hasAnyCommandSucceeded = result.commandResults.some(r => !r.hasBusinessWarnings);

                //Si aucune commande n'est passé, on affiche les warnings en erreurs
                if (!hasAnyCommandSucceeded) {
                    ToastService.showErrorToast("", warnings);

                }
                else if (warnings.length > 0) {
                    ToastService.showWarningToast("", warnings);
                }
            }
        }
    }

    getExpeditionsWithOverheadExpensesSumStateFragment = async (): Promise<ExpeditionsStateFragment> => {
        if (this.state.date !== null && this.state.filtersSelected.length > 0) {
            let stateFragment: ExpeditionsStateFragment = null;
            const currentTimeStamp = Date.now();
            const fromTime = this.state.date.stripTime();
            const productionForemanUserName = (this.props.role === "PRD") ? this.props.userName : '';
            const dispatcherUserName = (this.props.role === "DIS") ? this.props.userName : '';

            return await OperationalMonitoringApiClient.GetExpeditionsWithOverheadExpensesSum(this.state.pageLength, this.state.pageNumber, this.state.searchText, this.state.filtersSelected, fromTime, this.props.logisticsUnitIds, productionForemanUserName, dispatcherUserName)
                .then((res) => {
                    if (this._isMounted) {
                        if (currentTimeStamp < this.state.lastTimeStampGetExpeditions)
                            return stateFragment;

                        stateFragment = stateFragment ?? this.initStateFragment(this.createDefaultExpeditionsStateFragment, this.state);
                        this.fillExpeditionsStateFragment(res[0].data, res[1].data, currentTimeStamp, stateFragment);
                        return stateFragment;
                    }

                    return stateFragment;
                })
                .catch(() => {
                    return stateFragment;
                });
        }

        return null;
    }

    getExpeditionsStateFragment = async (): Promise<ExpeditionsStateFragment> => {
        if (this.state.date !== null && this.state.filtersSelected.length > 0) {
            let stateFragment: ExpeditionsStateFragment = null;
            const currentTimeStamp = Date.now();
            const fromTime = this.state.date.stripTime();
            const productionForemanUserName = (this.props.role === "PRD") ? this.props.userName : '';
            const dispatcherUserName = (this.props.role === "DIS") ? this.props.userName : '';

            return await OperationalMonitoringApiClient.GetExpeditions(this.state.pageLength, this.state.pageNumber, this.state.searchText, this.state.filtersSelected, fromTime, this.props.logisticsUnitIds, productionForemanUserName, dispatcherUserName)
                .then((res) => {
                    if (this._isMounted) {
                        if (currentTimeStamp < this.state.lastTimeStampGetExpeditions)
                            return stateFragment;

                        stateFragment = stateFragment ?? this.initStateFragment(this.createDefaultExpeditionsStateFragment, this.state);
                        this.fillExpeditionsStateFragment(res.data, this.state.transportMargins.overheadExpenses, currentTimeStamp, stateFragment);
                        return stateFragment;
                    }

                    return stateFragment;
                })
                .catch(() => {
                    return stateFragment;
                });
        }

        return null;
    }

    fillExpeditionsStateFragment = (dataResults: PagedResult<PlanningVehicleTripsLightModel>, sumOverheadExpenses: number, currentTimeStamp: number, stateFragment: ExpeditionsStateFragment): void => {
        const data: Array<PlanningVehicleTripsLightModelExtended> = (dataResults.pageItems) as Array<PlanningVehicleTripsLightModelExtended>;
        const storedLabelSelection = LocalStorage.GetItem(ModuleKey, LabelSelectionStorageKey);
        this.selectedValue = (storedLabelSelection != null) ? storedLabelSelection : "tripNumber";
        const eventsData: Array<EventModelData> = [];
        data.forEach((planningVehicle: PlanningVehicleTripsLightModelExtended) => {
            this.planningVehicleEnrichment(planningVehicle, eventsData, storedLabelSelection);
        });

        if (currentTimeStamp < this.state.lastTimeStampGetExpeditions)
            return null;

        const existsResourcesToReceiveSmsNotification: boolean = data.some((element) => this.canReceiveSmsNotification(element));
        const isResourcesContainesEmptyVehicles: boolean = data.filter(r => !r.vehicle && !r.transporterId && !r.driverId && r.trips?.length == 0).length > 0;
        const resourcesWithDriversAndMobilePhoneNumber: PlanningVehicleTripsLightModelExtended[] = data.filter(x => x.driverId !== null && x.driverPhoneNumberIsMobile);

        stateFragment.events = eventsData;
        stateFragment.resources = data;
        stateFragment.eventsVersion = this.state.eventsVersion + 1;
        stateFragment.resourcesWithDriversAndMobilePhoneNumber = resourcesWithDriversAndMobilePhoneNumber;
        stateFragment.existsResourcesToReceiveSmsNotification = existsResourcesToReceiveSmsNotification;
        stateFragment.existsEmptyVehicles = isResourcesContainesEmptyVehicles;
        stateFragment.activePage = dataResults.pageIndex;
        stateFragment.totalItems = dataResults.totalItemsCount;
        stateFragment.lastTimeStampGetExpeditions = currentTimeStamp;
        stateFragment.isSmsNotificationDialogOpened = this.state.refreshToOpenSmsModal === true ? true : false;
        stateFragment.refreshToOpenSmsModal = false;
        stateFragment.needGrouping = true;

        if (this.isLogisticianUser) {
            const sumGrossMargins = this.sumPlanningVehicleGrossMargin(data);
            const sumPercentCharge = this.computeLoadUtilizationRateAverage(data);
            stateFragment.transportMargins = {
                overheadExpenses: sumOverheadExpenses,
                grossMargin: sumGrossMargins,
                netMargin: sumGrossMargins - sumOverheadExpenses,
                averageLoadUtilizationRate: sumPercentCharge
            };
        }

        const scheduler = () => this.schedulerRef.current?.instance as Scheduler;
        const sorters: Array<SortDescriptor> = scheduler().resourceStore.sorters as Array<SortDescriptor>;
        const storedGroupingName = LocalStorage.GetItem(ModuleKey, GroupingNameStorageKey);
        this.groupingName = (storedGroupingName != null) ? storedGroupingName : "default";
        const grouping = LocalStorage.GetItem(ModuleKey, GroupingNameStorageKey);
        if (this.groupingName !== grouping) {
            LocalStorage.SetItem(ModuleKey, GroupingNameStorageKey, this.groupingName);
            stateFragment.sorters = sorters;
        }
        stateFragment.scrollLeftSaved = scheduler().scrollLeft ?? OperationalMonitoringView.TimelineDefaultScrollLeft;
    }

    createDefaultExpeditionsStateFragment = (): ExpeditionsStateFragment => {
        return {
            events: [],
            resources: [],
            eventsVersion: 0,
            resourcesWithDriversAndMobilePhoneNumber: [],
            existsResourcesToReceiveSmsNotification: false,
            existsEmptyVehicles: false,
            activePage: 1,
            totalItems: 1,
            lastTimeStampGetExpeditions: Date.now(),
            isSmsNotificationDialogOpened: false,
            refreshToOpenSmsModal: false,
            needGrouping: true,
            transportMargins: {
                overheadExpenses: 0,
                grossMargin: 0,
                netMargin: 0,
                averageLoadUtilizationRate: 0
            },
            deliveryTripsSelected: [],
            sorters: [],
            scrollLeftSaved: OperationalMonitoringView.TimelineDefaultScrollLeft
        }
    }

    getVehicleGroupCountersStateFragment = async (date: Date): Promise<VehicleGroupCountersStateFragment> => {
        let stateFragment: VehicleGroupCountersStateFragment = null;
        if (!this.isLogisticianUser)
            return new Promise((resolve) => {
                resolve(stateFragment);
            });

        const searchAvailableDailyVehicles: boolean = date > new Date();
        const fromTime = date.stripTime();
        const currentTimeStamp = Date.now();

        return await TransportIndicatorsApiClient.GetVehicleGroupCounters(fromTime, fromTime, this.props.logisticsUnitIds, searchAvailableDailyVehicles)
            .then(res => {
                if (currentTimeStamp < this.state.lastTimeStampGetVehicleGroupCounters)
                    return stateFragment;

                if (this._isMounted) {
                    stateFragment = stateFragment ?? this.initStateFragment(this.createDefaultVehicleGroupCountersStateFragment, this.state);
                    stateFragment.vehicleGroupCounters = res.data;
                    stateFragment.lastTimeStampGetVehicleGroupCounters = currentTimeStamp;
                    return stateFragment;
                }

                return stateFragment;
            });
    }

    createDefaultVehicleGroupCountersStateFragment = (): VehicleGroupCountersStateFragment => {
        return {
            vehicleGroupCounters: { missingX4Count: 0, missingX6Count: 0, missingX8Count: 0, missingSemiCount: 0, missingOthersTypeCount: 0, x4Count: 0, x6Count: 0, x8Count: 0, semiCount: 0, othersTypeCount: 0 },
            lastTimeStampGetVehicleGroupCounters: Date.now(),
        }
    }

    createDefaultVehicleTypesStateFragment = (): VehicleTypesStateFragment => {
        return {
            vehicleTypes: []
        }
    }

    getTransportFlowsStateFragment = async (searchText: string, addedDaysNumber: number, date: Date, isNegativeRemainingQtyFilterSelected: boolean, isFlowManagementDrawerOpened?: boolean): Promise<TransportFLowsStateFragment> => {
        let stateFragment: TransportFLowsStateFragment = null;
        if (this.state.isFlowManagementDrawerOpened) {
            return await OperationalMonitoringApiClient.SearchTransportFlow(searchText, date, addedDaysNumber, this.props.logisticsUnitIds)
                .then((res) => {
                    if (this._isMounted) {
                        const allFlows = res.data as Array<TransportFlowLightModelExtended>;
                        let filtredFlows = res.data as Array<TransportFlowLightModelExtended>;
                        let isNegativeFilterActived = false;

                        if (isNegativeRemainingQtyFilterSelected == true) {
                            filtredFlows = allFlows.filter(e => { return e.remainingQuantityToBePlanned <= 0 });
                            isNegativeFilterActived = true
                        }
                        else {
                            filtredFlows = allFlows.filter(e => { return e.remainingQuantityToBePlanned > 0 });
                        }

                        filtredFlows.forEach(f => {
                            f.arrivalTimeOnlyAtReceiver = Date.getTimeFromIsoString(f.arrivalTimeAtReceiverSite);
                            f.tripsNumber = 1;
                            f.tripsNumberIsForced = false;
                        });

                        filtredFlows = orderBy(filtredFlows, o => [o.remainingDays, o.arrivalTimeOnlyAtReceiver, o.remainingQuantityToBePlanned, o.remainingQuantityToBeDelivered], ['asc', 'asc', 'desc', 'desc']);

                        const hasNegativeFlows = allFlows.some(x => x.remainingQuantityToBePlanned <= 0);

                        stateFragment = stateFragment ?? this.initStateFragment(this.createDefaultTransportFLowsStateFragment, this.state);
                        if (isFlowManagementDrawerOpened) {
                            stateFragment.isFlowManagementDrawerOpened = true;
                            stateFragment.transportFlowList = filtredFlows;
                            stateFragment.isNegativeFilterActived = isNegativeFilterActived;
                            stateFragment.hasNegativeFlows = hasNegativeFlows;
                            stateFragment.isNegativeRemainingQtyFilterSelected = isNegativeRemainingQtyFilterSelected;
                        } else {
                            stateFragment.transportFlowList = filtredFlows;
                            stateFragment.hasNegativeFlows = hasNegativeFlows;
                            stateFragment.isNegativeRemainingQtyFilterSelected = isNegativeRemainingQtyFilterSelected;
                        }

                        return stateFragment;
                    }

                    return stateFragment;
                });
        }
        return stateFragment;
    }

    createDefaultTransportFLowsStateFragment = (): TransportFLowsStateFragment => {
        return {
            isFlowManagementDrawerOpened: false,
            transportFlowList: [],
            isNegativeFilterActived: false,
            hasNegativeFlows: false,
            isNegativeRemainingQtyFilterSelected: false
        }
    }

    initStateFragment = <T,>(fragmentConstructor: () => T, source: T): T => {
        const stateFragment = fragmentConstructor();
        const properties = Object.keys(fragmentConstructor);
        properties.forEach(property => {
            stateFragment[property] = source[property];
        });
        return stateFragment;
    }

    copyStateFragment = <T extends IStateFragment,>(stateFragment: IStateFragment, state: T): void => {
        Object.assign(state, stateFragment);
    }

    copyStateFragments = <T extends IStateFragment,>(newState: T, ...params: IStateFragment[]): void => {
        if (params == null)
            return;

        for (let i = 0; i < params.length; i++) {
            const fragment = params[i];
            if (fragment == null)
                continue;

            this.copyStateFragment(fragment, newState);
        }
    }

    checkInProgressOrDeliveredTrips = (planningVehicle: PlanningVehicleTripsLightModelExtended): boolean => {
        let selectedEvents: Array<EventModelData> = [];
        if (planningVehicle) {
            selectedEvents = this.state.events.filter(x => x.resourceId === planningVehicle.planningVehicleId);
        }

        let isInProgressFinishedStatus = false;
        if (selectedEvents !== undefined && selectedEvents.length !== 0) {
            selectedEvents.forEach((element: EventModelData) => {
                if (element.eventStatus === "InProgress" || element.eventStatus === "Finished")
                    isInProgressFinishedStatus = true;
            });
        }

        if (isInProgressFinishedStatus) {
            ToastService.showErrorToast("Le camion est associé à des tours au statut EN COURS ou TERMINÉ.");
        }

        return isInProgressFinishedStatus;
    }

    getPlanningVehicle = (): PlanningVehicleTripsLightModelExtended => {
        const scheduler = () => this.schedulerRef.current?.instance as Scheduler;
        const schedulerSelectedVahicleId = scheduler()?.selectedRecord?.id as string;

        const selectedVehicle = this.state.selectedVehicle;
        const selectedVehiclePlanningVehicleId = selectedVehicle.planningVehicleId;

        if (schedulerSelectedVahicleId === selectedVehiclePlanningVehicleId) {
            return selectedVehicle ? this.state.resources.find(x => x.planningVehicleId === selectedVehiclePlanningVehicleId) : null;
        }
        else {
            return schedulerSelectedVahicleId ? this.state.resources.find(x => x.planningVehicleId === schedulerSelectedVahicleId) : null
        }
    }

    legacyHandleRefresh = (scrollTop = 0): void => {
        //Sauvegarder la position du scroll pour la prendre en compte lors du refresh
        const scheduler = () => this.schedulerRef.current?.instance as Scheduler;
        const scrollLeft = scheduler().scrollLeft;

        const sorters: Array<SortDescriptor> = scheduler().resourceStore.sorters as Array<SortDescriptor>;

        this.setState({
            scrollLeftSaved: scrollLeft
        }, () => {
            this.getExpeditions(this.state.pageLength, this.state.pageNumber, this.state.searchText, this.state.filtersSelected, this.state.date, sorters, scrollTop);
            if (this.isLogisticianUser) {
                this.getVehicleGroupCounters(this.state.date);
                this.searchTransportFlow(this.state.flowSearchtext, this.state.addedDaysNumberChoice, this.state.date, this.state.isNegativeRemainingQtyFilterSelected, this.state.isFlowManagementDrawerOpened);
            }
        });
    }

    handleRefreshAfterAttachZephyrFlow = async (state: OperationalMonitoringViewState) => {
        this.setState({
            loadingDataScheduler: true,
            isAttachZephyrFlowManuallyOpened: false
        });

        state.isAttachZephyrFlowManuallyOpened = false;

        const newState = await this.refreshAfterRefreshButtonClicked(state);
        this.setState(newState);
    }

    handleRefreshButtonClicked = async (state: OperationalMonitoringViewState): Promise<void> => {
        this.setState({
            loadingDataScheduler: true
        });

        const newState = await this.refreshAfterRefreshButtonClicked(state);
        this.setState(newState);
    }

    onResizeTripToNewDeliveryTime = async (transportRequestId: string, deliveryTripId: number, newDeliveryTime: Date): Promise<void> => {
        //command update trip startdate / endate
        const tripDragResizeToNewLoadingDeliveryTime: TripResizeToNewDeliveryTimeRequestArgs = {
            transportRequestId: transportRequestId,
            deliveryTripId: deliveryTripId,
            newDeliveryTime: newDeliveryTime
        }

        this.setState({
            loadingDataScheduler: true
        });

        await OperationalMonitoringApiClient.ResizeTripToNewDeliveryTime(tripDragResizeToNewLoadingDeliveryTime)
            .then(async (json) => {
                const data = json?.data;
                await this.handleRefreshAfterResizeTripToNewDeliveryTime(data);

                const scheduler = () => this.schedulerRef.current?.instance as Scheduler;
                scheduler().readOnly = false;
            });
    }

    createTripFromFlow = async (vehiclePlanningId: string, transportFlowId: string, transportRequestId: string, deliveryTime: Date, tripsNumber: number, tripsNumberIsForced: boolean): Promise<void> => {
        this.setState({
            loadingDataScheduler: true
        });

        await OperationalMonitoringApiClient.CreateTripFromFlow(
            vehiclePlanningId,
            transportFlowId,
            transportRequestId,
            deliveryTime,
            tripsNumber,
            tripsNumberIsForced
        ).then(async (json) => {
            await this.handleRefreshAfterCreateTripFromFlow(json?.data);
        });
    }

    handleVehiclesSelectorOpen = (): void => {
        this.setState({
            isVehiclesSelectorOpen: true
        });
    }

    handleDrawerLeftClose = (): void => {
        this.setState({
            isVehiclesSelectorOpen: false
        });
    }

    handleFlowDrawerOpen = (): void => {
        this.searchTransportFlow("", 0, this.state.date, this.state.isNegativeRemainingQtyFilterSelected, true);
    }

    updateHideOrShowFlows = (transportFlowIds: Array<string>, hidden: boolean): void => {
        if (!transportFlowIds || transportFlowIds.length == 0)
            return;

        const transportFlows = [...this.state.transportFlowList];
        transportFlowIds.forEach(transportFlowId => {
            const indexElemOfList = transportFlows ? transportFlows.findIndex(f => {
                return f.transportFlowId === transportFlowId;
            }) : -1;

            if (indexElemOfList !== -1) {
                transportFlows[indexElemOfList].isHidden = hidden;
            }
        });

        this.setState({
            transportFlowList: transportFlows
        });
    }

    handleFlowHiddenStateChange = (transportFlowId: string, hidden: boolean): void => {
        OperationalMonitoringApiClient.UpdateIsHiddenForCreateTripFromFlow(transportFlowId, hidden)
            .then(() => {
                this.updateHideOrShowFlows([transportFlowId], hidden);
            });
    }

    handleUpdateTripsNumberTransportFlow = (transportFlowId: string, tripsNumber: number): void => {
        const transportFlowListData = [...this.state.transportFlowList];

        const transportFlow = transportFlowListData.find(t => t.transportFlowId === transportFlowId);
        transportFlow.tripsNumber = tripsNumber;

        this.setState({
            transportFlowList: transportFlowListData
        });
    }

    handleUpdateTripsNumberIsForcedTransportFlow = (transportFlowId: string, tripsNumberIsForced: boolean): void => {
        const transportFlowListData = [...this.state.transportFlowList];

        const transportFlow = transportFlowListData.find(t => t.transportFlowId === transportFlowId);
        transportFlow.tripsNumberIsForced = tripsNumberIsForced;

        this.setState({
            transportFlowList: transportFlowListData
        });
    }

    searchTransportFlow = (searchText: string, addedDaysNumber: number, date: Date, isNegativeRemainingQtyFilterSelected: boolean, isFlowManagementDrawerOpened?: boolean): void => {
        OperationalMonitoringApiClient.SearchTransportFlow(searchText, date, addedDaysNumber, this.props.logisticsUnitIds)
            .then((res) => {
                const allFlows = res.data as Array<TransportFlowLightModelExtended>;
                let filtredFlows = res.data as Array<TransportFlowLightModelExtended>;
                let isNegativeFilterActived = false;

                if (isNegativeRemainingQtyFilterSelected == true) {
                    filtredFlows = allFlows.filter(e => { return e.remainingQuantityToBePlanned <= 0 });
                    isNegativeFilterActived = true
                }
                else {
                    filtredFlows = allFlows.filter(e => { return e.remainingQuantityToBePlanned > 0 });
                }

                filtredFlows.forEach(f => {
                    f.arrivalTimeOnlyAtReceiver = Date.getTimeFromIsoString(f.arrivalTimeAtReceiverSite);
                    f.tripsNumber = 1;
                    f.tripsNumberIsForced = false;
                });

                filtredFlows = orderBy(filtredFlows, o => [o.remainingDays, o.arrivalTimeOnlyAtReceiver, o.remainingQuantityToBePlanned, o.remainingQuantityToBeDelivered], ['asc', 'asc', 'desc', 'desc']);

                const hasNegativeFlows = allFlows.some(x => x.remainingQuantityToBePlanned <= 0);
                if (isFlowManagementDrawerOpened) {
                    this.setState({
                        isFlowManagementDrawerOpened: true,
                        transportFlowList: filtredFlows,
                        isNegativeFilterActived: isNegativeFilterActived,
                        hasNegativeFlows: hasNegativeFlows,
                        isNegativeRemainingQtyFilterSelected: isNegativeRemainingQtyFilterSelected
                    });
                } else {
                    this.setState({
                        transportFlowList: filtredFlows,
                        hasNegativeFlows: hasNegativeFlows,
                        isNegativeRemainingQtyFilterSelected: isNegativeRemainingQtyFilterSelected
                    });
                }

                const scheduler = () => this.schedulerRef.current?.instance as Scheduler;
                scheduler().readOnly = false;
            });
    }

    handleFlowDrawerClose = (): void => {
        if (this.inputSearchTextRef.current.value === this.state.selectedFlow) {
            this.setState({
                selectedFlow: null
            });
            this.handleKeyPress("");
            this.inputSearchTextRef.current.value = '';
        }
        this.setState({
            isFlowManagementDrawerOpened: false,
            isNegativeRemainingQtyFilterSelected: false
        });
    }

    clearSearchFlowText = (): void => {
        this.inputSearchFlowRef.current.value = "";
        this.searchTransportFlow("", this.state.addedDaysNumberChoice, this.state.date, this.state.isNegativeRemainingQtyFilterSelected);
        this.setState({
            flowSearchtext: "",
            isNegativeRemainingQtyFilterSelected: false
        });
    }

    handleSearchFlowKeyPress = debounce((flowSearchText: string): void => {
        if (flowSearchText.length >= 3 || flowSearchText.length === 0) {
            this.flowKeyPress(flowSearchText);
            this.searchTransportFlow(flowSearchText, this.state.addedDaysNumberChoice, this.state.date, this.state.isNegativeRemainingQtyFilterSelected);
        }
    }, 500);

    flowKeyPress = (flowSearchText: string): void => {
        this.setState({
            flowSearchtext: flowSearchText
        });
    }

    handlePeriodChoiceChange = (addedDaysNumber: number): void => {
        this.setState({
            addedDaysNumberChoice: addedDaysNumber
        });
        this.searchTransportFlow(this.state.flowSearchtext, addedDaysNumber, this.state.date, this.state.isNegativeRemainingQtyFilterSelected);
    }

    rowGridSelectionHandler = (selectedVehicle: PlanningVehicleTripsLightModelExtended): void => {
        const newState = { ...this.state };

        this.updateByVehicleTypeGroupIds(selectedVehicle?.vehicleTypeGroupId, newState);
        newState.selectedVehicle = selectedVehicle;
        this.setState(newState);
    }

    updateByVehicleTypeGroupIds = (vehicleTypeGroupId: string, newState: OperationalMonitoringViewState): void => {
        let newByVehicleTypeGroupIdsFilterValue: string[] = [];
        const { byVehicleTypeGroupIdsFilterValue } = this.state;

        if (vehicleTypeGroupId) {
            newByVehicleTypeGroupIdsFilterValue.push(vehicleTypeGroupId);
        }
        else {
            newByVehicleTypeGroupIdsFilterValue = null;
        }

        const hasVehicleTypeGroupChanged = (byVehicleTypeGroupIdsFilterValue && byVehicleTypeGroupIdsFilterValue[0] !== vehicleTypeGroupId) || (!byVehicleTypeGroupIdsFilterValue && vehicleTypeGroupId);
        if (hasVehicleTypeGroupChanged) {
            newState.byVehicleTypeGroupIdsFilterValue = newByVehicleTypeGroupIdsFilterValue;
            newState.byVehicleTypeGroupIdsFilterChanged = !this.state.byVehicleTypeGroupIdsFilterChanged;
        }
    }

    eventSelectionHandler = (dataSelected: Array<EventModelData>): void => {
        this.setState({
            deliveryTripsSelected: dataSelected
        });
    }

    refreshMenuItemRemoveTrips = (item: any, hideFlows: boolean, eventData: EventModelData, state: OperationalMonitoringViewState): any => {
        let dataSelected = state.deliveryTripsSelected;
        if (dataSelected.length <= 1) {
            dataSelected = [eventData];
        }

        let title = '';
        if (dataSelected.length == 0) {
            item.disabled = true;
            title = 'Aucun tour sélectionné';
        } else {
            const resourceIds = dataSelected.map(x => x.resourceId);
            if (this.state.resources.some(x => resourceIds.includes(x.planningVehicleId) && x.isCanceled)) {
                item.disabled = true;
                title = BusinessErrors.GetError("CannotRemoveTripOnACanceledPlanningVehicleOrAsExpectedStatusShouldBeTransportNeededOrTransportPlanned");
            }
            else {
                const blockingTrips = dataSelected.filter(e => { return e.eventStatus == 'Finished' || e.eventStatus == 'InProgress' || e.eventStatus == 'TransportConfirmed' });

                if (blockingTrips.length > 0) {
                    item.disabled = true;
                    title = BusinessErrors.GetError('CannotRemoveTripAsExpectedStatusShouldBeTransportNeededOrTransportPlanned');
                }
                else {
                    item.disabled = false;
                }
            }
        }

        if (hideFlows === true) {
            item.html = renderToString(<div id="remove-trips-and-hide-flow" title={title}>
                <FontAwesomeIcon icon={faEyeSlash} style={{ marginRight: '10px', marginTop: '2px' }} />
                Supprimer tour(s) sélectionné(s) et masquer flux
            </div>);
        } else {
            item.html = `<div id="remove-trips" title='${title}'>
                            Supprimer tour(s) sélectionné(s)
                        </div>`;
        }

        return item;
    }

    refreshMenuItemEditFlow = (item, data: EventModelData): any => {
        let title = '';
        if (data?.transportFlowStatus == 'Finished') {
            item.disabled = true;
            title = BusinessErrors.GetError('CannotEditFinishedFlow');
        } else {
            item.disabled = false;
        }
        item.html =
            `<div id="edit-flow" title='${title}'>
            Modifier le Flux
        </div>`;

        return item;
    }

    refreshMenuItemUpdateSpecificPriceFromDeliveryTrips = (item: any, eventData: EventModelData, state: OperationalMonitoringViewState): any => {
        let dataSelected = state.deliveryTripsSelected;
        if (dataSelected.length <= 1) {
            dataSelected = [eventData];
        }

        const firstTrip = dataSelected[0];
        let title = '';
        if (dataSelected.length == 0) {
            item.disabled = true;
            title = 'Aucun tour sélectionné';
        }
        else if (!firstTrip.transporterId || firstTrip.planningVehicleIsCanceled || !this.isLogisticianUser || uniq(dataSelected.map(x => x.resourceId)).length > 1) {
            item.disabled = true;
            title = BusinessErrors.GetError('UpdateSpecificPriceFromDeliveryTripsMustBeOneVehicleNotCanceledWithTransporter');
        }
        item.html = `<div id="update-specific-price" title='${title}'>
                Modifier tarification spécifique d'achat du/des tour(s) sélectionné(s)
            </div>`;

        return item;
    }

    refreshMenuItemDuplicateFlow = (item): any => {
        item.disabled = false;
        item.html =
            `<div id="duplicate-flow" title='Dupliquer le Flux'>
            Dupliquer le Flux du trajet
        </div>`;

        return item;
    }

    refreshMenuItemRemovePlanningVehicle = (item: any, data: PlanningVehicleTripsLightModelExtended): any => {
        let title = '';
        const blockingTrips = data.trips.filter(e => { return e.status == 'Finished' || e.status == 'InProgress' || e.status == 'TransportConfirmed' });
        const foreignLogisticsUnitsTrips = data.trips.filter(e => { return this.props.logisticsUnitIds.indexOf(e.logisticsUnitId) == -1 });

        item.disabled = false;

        if (data.isCanceled) {
            item.disabled = true;
        }

        //si c'est un camion externe et confirmé
        if (data.vehicle && data.transporterId && data.transporterConfirmationDate) {
            item.disabled = true;
            title = BusinessErrors.GetError('PlanningVehicleHasAnExternalClientConfirmed');
        }
        //S'il existe au moins un trajet avec le statut 'Finished' ou 'InProgress' ou 'Confirmé'
        if (blockingTrips?.length > 0) {
            item.disabled = true;
            title = (title ? title + "&#013;" : "") + BusinessErrors.GetError('PlanningVehicleHasFinishedOrInProgressOrConfirmedTrip');
        }

        if (foreignLogisticsUnitsTrips?.length > 0) {
            item.disabled = true;
            title = (title ? title + "&#013;" : "") + BusinessErrors.GetError('PlanningVehicleHasTripsFromForeignLogisticsUnits');
        }

        if (data.hasAnyProvisionings && data.hasAnyTransportFlowsWithPreInvoices) {
            item.disabled = true;
            title = (title ? title + "&#013;" : "") + BusinessErrors.GetError({ type: 'PlanningVehicleError', name: 'PlanningVehicleHasProvisionings' }) + "\n" + BusinessErrors.GetError({ type: 'PlanningVehicleError', name: 'PlanningVehicleHasTransportFlowsWithPreInvoices' });
        }

        else if (data.hasAnyProvisionings && !data.hasAnyTransportFlowsWithPreInvoices) {
            item.disabled = true;
            title = (title ? title + "&#013;" : "") + BusinessErrors.GetError({ type: 'PlanningVehicleError', name: 'PlanningVehicleHasProvisionings' });
        }

        else if (!data.hasAnyProvisionings && data.hasAnyTransportFlowsWithPreInvoices) {
            item.disabled = true;
            title = (title ? title + "&#013;" : "") + BusinessErrors.GetError({ type: 'PlanningVehicleError', name: 'PlanningVehicleHasTransportFlowsWithPreInvoices' });
        }

        item.html = `<div id="remove-planning" title="${title}" data-html="true">
            Supprimer camion et tour(s) associé(s)
            </div>`;
        return item;
    }

    refreshMenuItemDuplicatePlanningVehicle = (item: any, data: PlanningVehicleTripsLightModelExtended): any => {
        let title = '';

        if (data.isCanceled) {
            item.disabled = true;
        }
        //si le regroupement n'est pas sur le site d'arrivée, on désactive la duplication
        else if (this.groupingName !== ReceiverSitesGroupName) {
            item.disabled = true;
            title = "Action possible uniquement lorsque l'écran est regroupé par Site d'arrivée.";
        }
        item.html = `<div id="duplicate-planning-vehicle" title="${title}">
            Dupliquer ce type de véhicule et tour(s) associé(s)
            </div>`;
        return item;
    }

    refreshMenuItemRemovePlanningVehicleTransporter = (item: any, data: PlanningVehicleTripsLightModelExtended): any => {
        let title = '';
        const blockingTrips = data.trips.filter(e => { return e.status == 'Finished' || e.status == 'InProgress' });

        if (data.isCanceled) {
            item.disabled = true;
        }
        else if (!data.transporterId) {
            item.disabled = true;
            title = "Impossible car le camion n'est associé à aucun transporteur.";
        }
        else if (data.hasAnyProvisionings && blockingTrips?.length == 0) {
            item.disabled = true;
            title = "Impossible de retirer le transporteur d'un camion faisant partie d'un LOT de provisions";
        }
        else if (!data.hasAnyProvisionings && blockingTrips?.length > 0) {
            item.disabled = true;
            title = "Impossible de retirer le transporteur d'un camion avec des tours 'EN COURS' ou 'TERMINE'";
        }
        else if (data.hasAnyProvisionings && blockingTrips?.length > 0) {
            item.disabled = true;
            title = "Impossible de retirer le transporteur d'un camion avec des tours 'EN COURS' ou 'TERMINE'" + '\n' + "Impossible de retirer le transporteur d'un camion faisant partie d'un LOT de provisions";
        }
        else {
            item.disabled = false;
        }

        item.html = `<div id="remove-planning-vehicle-transporter" title="${title}" data-html="true">
            Retirer le transporteur du camion
            </div>`;

        return item;
    }

    refreshMenuItemCancelPlanningVehicle = (item: any) => {
        item.html = `<div id="cancel-planning-vehicle" data-html="true">
            Annuler camion et tour(s) associé(s)
            </div>`;

        return item;
    }

    refreshMenuItemReinstatePlanningVehicle = (item: any): any => {
        item.html = `<div id="reinstate-planning-vehicle" data-html="true">
            Rétablir camion et tour(s) associé(s)
            </div>`;

        return item;
    }

    refreshMenuItemRemoveContractualPurchasePriceFromPlanningVehicle = (item: any, data: PlanningVehicleTripsLightModelExtended): any => {
        let title = '';
        const isContractualTransporterVehicleType = data.contractNumber !== null;

        if (data.isCanceled) {
            item.disabled = true;
        }
        else if (!isContractualTransporterVehicleType) {
            item.disabled = true;
            title = "Aucun tarif PAC associé au camion";
        }
        else {
            item.disabled = false;
        }

        item.html = `<div id="remove-contracted-transporter-vehicle-type" title="${title}" data-html="true">
            Ne plus considérer le camion comme un PAC
            </div>`;

        return item;
    }

    refreshMenuItemMoveSelectedTrips = (item: any, data: PlanningVehicleTripsLightModelExtended): any => {
        const { deliveryTripsSelected } = this.state;
        let title = '';

        if (data.isCanceled) {
            item.disabled = true;
        }
        else if (deliveryTripsSelected.length == 0) {
            item.disabled = true;
            title = 'Déplacement impossible car aucun tour(s) séléctionné(s)';
        }
        else if (uniq(deliveryTripsSelected.map(x => x.planningVehicleId)).length > 1) {
            title = 'Déplacement impossible car les tours séléctionnés appartiennent à plusieurs camions';
            item.disabled = true;
        }
        //vérifier s'il n'existe pas de tour à déplacer sur le même camion
        else if (deliveryTripsSelected.every(x => x.resourceId == data.id)) {
            title = 'Déplacement impossible car un ou plusieurs tours à déplacer appartiennent au même camion';
            item.disabled = true;
        }
        else {
            item.disabled = false;
        }

        item.html = `<div id="move-deliveryTrips-planning" title="${title}">
            Déplacer tour(s) sélectionné(s)
            </div>`;
        return item;
    }

    refreshMenuItemEditTripStatus = (item: any, eventData: EventModelData, state: OperationalMonitoringViewState): any => {
        let dataSelected = state.deliveryTripsSelected;
        if (dataSelected.length <= 1) {
            dataSelected = [eventData];
        }

        let title = '';
        if (dataSelected.length == 0) {
            item.disabled = true;
            title = 'Aucun tour sélectionné';
        }
        else if (dataSelected.some(x => x.planningVehicleIsCanceled)) {
            item.disabled = true;
            title = "Changement de statut impossible car il existe au moins un tour faisant partie d'un camion annulé";
        }
        item.html =
            `<div id="edit-trips" title="${title}">
            Changer statut tour(s) sélectionné(s)
        </div>`;
        return item;
    }

    refreshAttachZephyrReturnManually = (item: any, eventData: EventModelData, state: OperationalMonitoringViewState): any => {
        let dataSelected = state.deliveryTripsSelected;
        if (dataSelected.length <= 1) {
            dataSelected = [eventData];
        }

        var isVisible = dataSelected.length == 1 && dataSelected[0].serviceKind !== TransportServiceKind.jobsiteVehicle;
        item.hidden = !isVisible;
        return item;
    }

    //SYNC avec TransportRequestAggregate.MoveDeliveryTrip
    canMoveDeliveryTripByStatus = (status: string): boolean => {
        return (status === "TransportNeeded" || status === "TransportPlanned" || status === "Canceled");
    }

    //SYNC avec TransportRequestAggregate.MoveDeliveryTrip
    getTripStatusAfterMove = (transporterId: string, vehicleId: number, newPlanningVehicleId: string, oldPlanningVehicleId: string, existingTripStatus: string): string => {
        if (newPlanningVehicleId === oldPlanningVehicleId && existingTripStatus === "Canceled")
            return existingTripStatus;

        return (transporterId != null || vehicleId != null) ? "TransportPlanned" : "TransportNeeded";
    }

    showEventDetailPopup = (id: string, data: EventModelData, isPlanningVehicleCanceled: boolean, availablePurchasePrices: PurchasePriceLightModel[]) => {
        const popup = new Popup({
            autoClose: false,
            autoShow: false,
            draggable: true,
            floating: true,
            centered: true,
            //c'est juste un workaround pour que le déplacement de la popup fontionne du premier coup 
            //https://www.bryntum.com/forum/viewtopic.php?f=44&t=12653&p=66587#p66587
            //@ts-ignore
            focusable: true,
            cls: 'scheduler-popup-w',
            html: `<div class="b-sch-event-tooltip" id="${id}"></div>`
        });

        // https://www.bryntum.com/products/scheduler/docs/api/Core/widget/Popup
        // On souhaite éviter que la popup se ferme quand on click en dehors de la page
        // mais qu'elle se ferme si on click en dehors de la popup mais à l'intérieur de la page.
        // Or le comportement en 'autoClose' (true par défaut) la popup se ferme quand elle perd le focus
        // et on ne peut pas distinguer la source de la perte du focus.
        // Alors on met 'autoClose: false' et on intercepte le click sur le document et s'il est en dehors de la popup on la ferme.
        function buildDocClickHandler(targetPopup: Popup) {
            return (docClickEv) => {

                // il faut calculer le click en dehors de la popup par coordonnées 
                // car calculer si le click d'un élément appartient à la popup peut échouer
                // quand l'élément sur lequel on click est détaché du dom (par exemple l'édition des quantités).
                const popupDims = targetPopup.element.getBoundingClientRect();
                const { clickX, clickY } = { clickX: docClickEv.clientX, clickY: docClickEv.clientY };

                const clickInsidePopup =
                    clickX >= popupDims.left &&
                    clickX <= popupDims.right &&
                    clickY <= popupDims.bottom &&
                    clickY >= popupDims.top;

                if (!clickInsidePopup) {
                    targetPopup.close();
                }
            };
        }

        const docPopupClickHandler = buildDocClickHandler(popup);

        popup.on({
            beforeShow: () => {
                document.addEventListener('click', docPopupClickHandler);
            },
            show: () => {
                const divEvent = document.getElementById(id);
                ReactDOM.render(<TripDetailPopupComponent
                    eventData={data}
                    availablePurchasePrices={availablePurchasePrices}
                    isPlanningVehicleCanceled={isPlanningVehicleCanceled}
                    handleDriverInstructionsChange={this.handleDriverInstructionsChange}
                    handleTransporterInstructionsChange={this.handleTransporterInstructionsChange}
                    handlePlannedQuantityChange={this.handlePlannedQuantityChange}
                    handleSpecificPriceChange={this.handleSpecificPriceChange}
                    popup={popup}
                    role={this.props.role}
                    handleCancellationRemarksChange={this.handleCancellationRemarksChange} />, divEvent);
            },
            hide: () => {
                document.removeEventListener('click', docPopupClickHandler);
            },
            destroy: () => {
                document.removeEventListener('click', docPopupClickHandler);
            }
        });

        popup.show();
    };

    showEventDetail = debounce((event: any, eventRecord: any): void => {
        if (event && eventRecord) {
            //supprimer les anciens popup créé et non néttoyées
            const schedulersPopups = document.getElementsByClassName("scheduler-popup-w");
            for (let i = 0; i < schedulersPopups.length; i++) {
                schedulersPopups[i].remove();
            }

            const dataRecord: EventModelData = eventRecord.data;
            const isPlanningVehicleCanceled = event?.resourceRecord?.isCanceled;

            if (dataRecord && dataRecord.transporterId && this.isLogisticianUser) {
                OperationalMonitoringApiClient.GetAvailablePurchasePrices(this.state.pageLength, this.state.pageNumber, dataRecord.planningVehicleId, this.state.date, this.props.logisticsUnitIds)
                    .then(res => {
                        this.availablePurchasePrices = res.data;
                        this.showEventDetailPopup(`event-id-${event.eventRecord.data.tripNumber}`,
                            event.eventRecord?.data,
                            isPlanningVehicleCanceled,
                            this.availablePurchasePrices);

                    })
                    .catch(() => {
                        this.setState({ loadingDataScheduler: false, events: [], resources: [], resourcesWithDriversAndMobilePhoneNumber: [], existsResourcesToReceiveSmsNotification: false, existsEmptyVehicles: false });
                    });
            } else {
                this.showEventDetailPopup(`event-id-${event.eventRecord.data.tripNumber}`,
                    event.eventRecord?.data,
                    isPlanningVehicleCanceled,
                    null);
            }
        }
    }, 200);

    handleCancellationRemarksChange = (transportRequestId: string, deliveryTripId: number, cancellationRemarks: string): void => {
        const delTripchange: DeliveryTripChangeRequestArgs = {
            deliveryTripId: deliveryTripId,
            transportRequestId: transportRequestId,
            propertyName: "CANCELLATIONREMARKS",
            expectedQuantity: null,
            loadingTime: null,
            deliveryTime: null,
            instructions: null,
            price: null,
            priceKind: null,
            cancellationRemarks: cancellationRemarks
        };

        OperationalMonitoringApiClient.UpdateDeliveryTrip(delTripchange)
            .then(async res => {
                const data = res?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);

                    this.setState({
                        loadingDataScheduler: false
                    });
                    return;
                }

                const newState = await this.refreshAfterDeliveryTripPropertyChanged(data);
                this.setState(newState);
            });
    }

    handleDriverInstructionsChange = (transportRequestId: string, deliveryTripId: number, driverInstructions: string): void => {
        const delTripchange: DeliveryTripChangeRequestArgs = {
            deliveryTripId: deliveryTripId,
            transportRequestId: transportRequestId,
            propertyName: "INSTRUCTIONS",
            expectedQuantity: null,
            loadingTime: null,
            deliveryTime: null,
            instructions: driverInstructions,
            price: null,
            priceKind: null,
            cancellationRemarks: null
        };

        OperationalMonitoringApiClient.UpdateDeliveryTrip(delTripchange)
            .then(json => {
                const data = json?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);

                    this.setState({
                        loadingDataScheduler: false
                    });
                    return;
                } else {
                    //Mettre à jour le commentaire
                    const events = [...this.state.events];
                    const index = events.findIndex(x => x.deliveryTripId === deliveryTripId);
                    events[index].driverInstructions = driverInstructions;

                    this.setState({
                        events: events,
                        eventsVersion: this.state.eventsVersion + 1
                    });
                }
            });
    }

    handleTransporterInstructionsChange = (transportRequestId: string, transportFlowId: string, transporterInstructions: string): void => {
        OperationalMonitoringApiClient.UpdateTransporterInstructions(transportRequestId, transportFlowId, transporterInstructions)
            .then(json => {
                const data = json?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);

                    this.setState({
                        loadingDataScheduler: false
                    });
                    return;
                } else {
                    //Mettre à jour le commentaire
                    const events = [...this.state.events];
                    events.filter(x => x.transportFlowId === transportFlowId).forEach(dt => {
                        dt.transportersInstructions = transporterInstructions;
                    });

                    this.setState({
                        events: events,
                        eventsVersion: this.state.eventsVersion + 1
                    });
                }
            });
    }

    handleCreatePlanningVehicles = async (logisticsUnitId: string, vehicleTypeId: string, isNightWork: boolean, numberOfVehicles: number): Promise<void> => {
        this.setState({
            loadingDataScheduler: true,
            isDialogCreatePlanningVehicleOpened: false,
            disableCreatePlanningVehicles: true
        });

        OperationalMonitoringApiClient.CreatePlanningVehicles(this.state.date, logisticsUnitId, vehicleTypeId, isNightWork, numberOfVehicles)
            .then(async (res) => {
                const data = res?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);
                    this.setState({ loadingDataScheduler: false });
                    return;
                }

                const newState = await this.refreshAfterCreatePlanningVehicles(data);
                this.setState(newState);
            });
    }

    handleRemovePlanningVehicle = async (planningVehicleId: string, planningId: number, hasOrderLine: boolean, hasAtLeastOneDeliveryTrip: boolean): Promise<void> => {
        const message: string[] = [];
        if (hasOrderLine) {
            message.push("Le camion fait l'objet d'une demande de confirmation.");
        }
        if (hasAtLeastOneDeliveryTrip) {
            message.push("Au moins une livraison est associée au camion.");
        }
        if (message.length > 0) {
            message.push("Confirmez-vous l'action ?");
            this.setState({
                showConfirmationPlanningVehicleModal: true,
                confirmationMessage: message,
                planningVehicleIdToRemove: planningVehicleId,
                planningIdToRemove: planningId,
                actionToBeConfirmed: ConfirmableAction.RemovePlanningVehicle,
                planningVehicleHasOrderLine: hasOrderLine,
                planningVehicleHasAtLeastOneDeliveryTrip: hasAtLeastOneDeliveryTrip
            });
        } else {
            const newState = await this.confirmRemovePlanningVehicle(planningVehicleId, planningId, false, false);
            this.updateByVehicleTypeGroupIds(this.state.selectedVehicleTypeGroupId, newState);
            this.setState(newState);
        }
    }

    handleConfirmPlanningVehicle = async (action: string): Promise<void> => {
        switch (action) {
            case ConfirmableAction.RemovePlanningVehicle:
                {
                    const newState = await this.confirmRemovePlanningVehicle(this.state.planningVehicleIdToRemove, this.state.planningIdToRemove, this.state.planningVehicleHasOrderLine, this.state.planningVehicleHasAtLeastOneDeliveryTrip);
                    this.updateByVehicleTypeGroupIds(this.state.selectedVehicleTypeGroupId, newState);
                    this.setState(newState);
                    break;
                }
            case ConfirmableAction.AssignGenericVehicleType:
                {
                    const newState = await this.confirmAssignGenericVehicle(this.state.selectedVehicleTypeId);
                    this.updateByVehicleTypeGroupIds(this.state.selectedVehicleTypeGroupId, newState);
                    this.setState(newState);
                    break;
                }
            case ConfirmableAction.AssignTransporterVehicleType:
                {
                    const newState = await this.confirmAssignTransportersVehicleType(this.state.selectedVehicleTypeId, this.state.transporterId);
                    this.updateByVehicleTypeGroupIds(this.state.selectedVehicleTypeGroupId, newState);
                    this.setState(newState);
                    break;
                }
            case ConfirmableAction.AssignInternalVehicle:
                {
                    const newState = await this.confirmAssignInternalVehicle(this.state.transporterId, this.state.vehicleId, this.state.selectedVehicleTypeId, this.state.driverId, this.state.licencePlate);
                    this.updateByVehicleTypeGroupIds(this.state.selectedVehicleTypeGroupId, newState);
                    this.setState(newState);
                    break;
                }
            case ConfirmableAction.AssignDailyPlanningVehicle:
                {
                    const newState = await this.assignDailyPlanningVehicle(this.state.dailyPlanningVehicleId, this.state.vehicleId, this.state.licencePlate);
                    this.updateByVehicleTypeGroupIds(this.state.selectedVehicleTypeGroupId, newState);
                    this.setState(newState);
                    break;
                }
            case ConfirmableAction.AssignContractualPricedTransporterVehicle:
                {
                    const newState = await this.confirmAssignContractualPricedTransporterVehicleType(this.state.contractualPurchasePriceId);
                    this.updateByVehicleTypeGroupIds(this.state.selectedVehicleTypeGroupId, newState);
                    this.setState(newState);
                    break;
                }
        }
    }

    confirmRemovePlanningVehicle = async (planningVehicleId: string, planningId: number, hasOrderLineConfirmed: boolean, hasAtLeastOneDeliveryTripConfirmed: boolean): Promise<OperationalMonitoringViewState> => {
        const stateFragment: OperationalMonitoringViewState = null;

        return await OperationalMonitoringApiClient.RemovePlanningVehicle(planningVehicleId, planningId, hasOrderLineConfirmed, hasAtLeastOneDeliveryTripConfirmed, this.props.logisticsUnitIds)
            .then(async (res) => {
                if (this._isMounted) {
                    const data = res?.data;
                    return await this.refreshAfterRemovePlanningVehicleAction(data, true, this.state.date);
                }
                return stateFragment;
            });
    }

    removeDeliveryTrips = (hideFlow: boolean): void => {
        const group = groupBy(this.state.deliveryTripsSelected, x => x.resourceId);
        const groupedSelectedDeliveryTripsByRessourceId = map(group, (value, key) => ({ resourceId: key, deliveryTrips: value }));
        let atLeastVehiclePlanningAllTripsWillRemoved = false;

        each(groupedSelectedDeliveryTripsByRessourceId, (item) => {
            const planningVehicle = this.state.resources.find(x => x.planningVehicleId === item.resourceId && x.transporterId !== null);
            if (planningVehicle && item.deliveryTrips.length === planningVehicle.trips.length) {
                atLeastVehiclePlanningAllTripsWillRemoved = true;
            }
        });

        const removeTripsMode = hideFlow === true ? actionType.deleteTripsHideFlow : actionType.deleteTrips;
        if (atLeastVehiclePlanningAllTripsWillRemoved) {
            this.setState({ showConfirmationRemoveAllPlanningVehicleTripsModal: true, removeTripsMode: removeTripsMode });
        } else {
            this.setState({ showConfirmationRemoveTripsModal: true, removeTripsMode: removeTripsMode });
        }
    };

    attachZephyrReturnManually = () => {
        this.setState({
            isAttachZephyrFlowManuallyOpened: true
        });
    }
    showConfirmationDropWithTransporter = (tripsToDrag: Array<TripDragToNewLoadingTimeRequestArgsExtended>): void => {
        this.showConfirmationDrop(tripsToDrag, ["Un des TOURS sélectionnés est associé à un tarif spécifique.", "Confirmez- vous l'action ?"]);
    }

    showConfirmationDropWithoutTransporter = (tripsToDrag: Array<TripDragToNewLoadingTimeRequestArgsExtended>): void => {
        this.showConfirmationDrop(tripsToDrag, ["Les tarifs spécifiques seront supprimés.", "Confirmez- vous l'action ?"]);
    }

    showConfirmationDropWhenPlanningVehicleWithTransporter = (tripsToDrag: Array<TripDragToNewLoadingTimeRequestArgsExtended>): void => {
        this.showConfirmationDrop(tripsToDrag, ["Au moins un camion est associé à un transporteur.", "Confirmez- vous l'action ?"]);
    }

    showConfirmationDrop = (tripsToDrag: Array<TripDragToNewLoadingTimeRequestArgsExtended>, messages: Array<string>): void => {
        this.setState({ showConfirmationSchedulerTripsAction: true, confirmationMessage: messages, pendingTripsToDragToNewLoadingTime: tripsToDrag, removeTripsMode: actionType.dropRemove });
    }

    showConfirmationMoveTripsWithTransporter = (moveTrips: Array<TripsMoveToNewPlanningVehicleRequestArgsExtended>): void => {
        this.showConfirmationMove(moveTrips, ["Un des TOURS sélectionnés est associé à un tarif spécifique.", "Confirmez- vous l'action ?"]);
    }

    showConfirmationMoveTripsWithoutTransporter = (moveTrips: Array<TripsMoveToNewPlanningVehicleRequestArgsExtended>): void => {
        this.showConfirmationMove(moveTrips, ["Les tarifs spécifiques seront supprimés.", "Confirmez- vous l'action ?"]);
    }

    showConfirmationMoveWhenPlanningVehicleWithTransporter = (moveTrips: Array<TripsMoveToNewPlanningVehicleRequestArgsExtended>): void => {
        this.showConfirmationMove(moveTrips, ["Au moins un camion est associé à un transporteur.", "Confirmez- vous l'action ?"]);
    }

    showConfirmationMove = (moveTrips: Array<TripsMoveToNewPlanningVehicleRequestArgsExtended>, messages: Array<string>): void => {
        this.setState({ showConfirmationSchedulerTripsAction: true, confirmationMessage: messages, pendingTripsToMoveToNewPlanningVehicle: moveTrips, removeTripsMode: actionType.moveTripRemove });
    }

    removeEmptyPlanningVehicles = (): void => {
        this.setState({ showConfirmationRemoveEmptyPlanningVehiclesModal: true })
    };

    handleHideConfirmationUpdatingTrips = (): void => {
        const newState: OperationalMonitoringViewState = { ...this.state };
        newState.showConfirmationSchedulerTripsAction = false;
        this.setState(newState);

        const scheduler = () => this.schedulerRef.current?.instance as Scheduler;
        scheduler().events = this.state.events;
        scheduler().resources = this.state.resources;
    }

    handleHideModal = (): void => {
        this.setState({
            showConfirmationRemoveTripsModal: false,
            showConfirmationRemoveEmptyPlanningVehiclesModal: false,
            showConfirmationPlanningVehicleModal: false,
            showConfirmationRemoveAllPlanningVehicleTripsModal: false,
            showConfirmationSchedulerTripsAction: false
        });

        if (this.state.removeTripsMode == actionType.dropRemove || this.state.removeTripsMode == actionType.moveTripRemove
            || this.state.action == actionType.dropWithSpecificPrice || this.state.action == actionType.moveTripsWithSpecificPrice) {
            this.legacyHandleRefresh(0);
        }
    }

    createTripDragToNewLoadingTimeRequestArgs = (tripsToDrag: TripDragToNewLoadingTimeRequestArgs[]): TripDragToNewLoadingTimeRequestArgs[] => {
        const requestArgs: TripDragToNewLoadingTimeRequestArgs[] = [];
        tripsToDrag.forEach(x => {
            requestArgs.push({
                deliveryTripId: x.deliveryTripId,
                newLoadingTime: x.newLoadingTime,
                planningVehicleId: x.planningVehicleId,
                transportRequestId: x.transportRequestId,
                transportFlowId: x.transportFlowId
            });
        });

        return requestArgs;
    }

    createTripsMoveToNewPlanningVehicleRequestArgs = (tripsToMove: TripsMoveToNewPlanningVehicleRequestArgsExtended[]): TripsMoveToNewPlanningVehicleRequestArgs => {
        const deliveryTrips: TripMoveToNewPlanningVehicleRequestArgs[] = [];

        tripsToMove.forEach(x => {
            deliveryTrips.push({
                deliveryTripId: x.deliveryTripId,
                transportRequestId: x.transportRequestId,
                transportFlowId: x.transportFlowId
            });
        });

        const requestArgs: TripsMoveToNewPlanningVehicleRequestArgs = {
            planningVehicleId: tripsToMove[0].planningVehicleId,
            deliveryTrips: tripsToMove
        };

        return requestArgs;
    }

    handleConfirmRemoveEmptyPlanningVehicles = async (): Promise<void> => {
        const { resources, date } = this.state;
        const planningVehiclesToRemove: Array<PlanningVehicleTripsLightModelExtended> = resources.filter(r => !r.vehicle && !r.transporterId && !r.driverId && r.trips?.length == 0);

        if (planningVehiclesToRemove.length > 0) {
            this.setState({
                loadingDataScheduler: true,
                showConfirmationRemoveEmptyPlanningVehiclesModal: false
            });

            OperationalMonitoringApiClient.RemoveEmptyPlanningVehicles(date, map(planningVehiclesToRemove, r => r.planningVehicleId))
                .then(async (res) => {
                    const data = res?.data;
                    const newState = await this.refreshAfterRemoveEmptyPlanningVehicles(data);
                    this.setState(newState);
                });
        }
    }

    handleClickOpenCreatePlanningVehicle = (dialogType: string): void => {
        this.setState({
            isDialogCreatePlanningVehicleOpened: true,
            dialogTitle: dialogType,
            disableCreatePlanningVehicles: false
        });
    }

    handleClickOpenDuplicatePlanningVehicle = (dialogType: string): void => {
        this.setState({
            isDialogDuplicatePlanningVehicleOpened: true,
            dialogTitle: dialogType,
            disableDuplicatePlanningVehicles: false
        });
    }

    getAvailablePurchasePricesWithDeliveryTripsHasSpecificOrNegotiatedPriceOverriden = async (planningVehicleId: string, deliveryTripIds: Array<number>): Promise<[AxiosResponse<Array<PurchasePriceLightModel>>, AxiosResponse<boolean>]> => {
        return await Promise.all([
            OperationalMonitoringApiClient.GetAvailablePurchasePrices(this.state.pageLength, this.state.pageNumber, planningVehicleId, this.state.date, this.props.logisticsUnitIds),
            OperationalMonitoringApiClient.ExistsOverridenSpecificOrNegociatedPrices(deliveryTripIds),
        ]);
    }

    handleClickOpenUpdateSpecificPrice = (): void => {
        const dataSelected = this.state.deliveryTripsSelected;

        const dataRecord: EventModelData = dataSelected[0];

        const deliveryTripIds = dataSelected.map(x => x.deliveryTripId);
        if (dataRecord && dataRecord.transporterId && this.isLogisticianUser) {
            this.getAvailablePurchasePricesWithDeliveryTripsHasSpecificOrNegotiatedPriceOverriden(dataRecord.planningVehicleId, deliveryTripIds)
                .then(res => {
                    this.availablePurchasePrices = res[0].data;

                    this.setState({
                        isDialogUpdateSpecificPriceOpened: true,
                        deliveryTripsSelectedToUpdateSpecificPricesHasSpecificOrNegotiatedPriceOverriden: res[1].data,
                        dialogTitle: "Modifier la tarification spécifique d'achat"
                    });
                });
        }
        else {
            this.availablePurchasePrices = [];
            OperationalMonitoringApiClient.ExistsOverridenSpecificOrNegociatedPrices(deliveryTripIds)
                .then(res => {
                    this.setState({
                        isDialogUpdateSpecificPriceOpened: true,
                        deliveryTripsSelectedToUpdateSpecificPricesHasSpecificOrNegotiatedPriceOverriden: res.data,
                        dialogTitle: "Modifier la tarification spécifique d'achat"
                    });
                });
        }
    }

    handleClickCloseCreatePlanningVehicle = (): void => {
        this.setState({ isDialogCreatePlanningVehicleOpened: false });
    }

    handleClickCloseDuplicatePlanningVehicle = (): void => {
        this.setState({ isDialogDuplicatePlanningVehicleOpened: false });
    }

    handleClickCloseUpdateSpecificPrice = (): void => {
        this.setState({
            isDialogUpdateSpecificPriceOpened: false,
            isConfirmValidationExistingSpecificOrNegotiatedPriceDialogOpened: false,
            deliveryTripsSelectedToUpdateSpecificPricesHasSpecificOrNegotiatedPriceOverriden: false,
            specificPriceToUpdate: 0,
            specificPriceKindToUpdate: ""
        });
    }

    handleCloseTransportFlowForm = (): void => {
        this.setState({
            isTransportFlowFormViewOpened: false
        });
    }

    editFlow = (transportFlowId: string, flowBusinessId: string): void => {
        this.setState({
            isTransportFlowFormViewOpened: true,
            flowDialogMode: updateMode,
            flowDialogTransportFlowId: transportFlowId,
            transportFlowBusinessId: flowBusinessId
        });
    }

    duplicateFlow = (transportFlowId: string): void => {
        this.setState({
            isTransportFlowFormViewOpened: true,
            flowDialogMode: duplicateMode,
            flowDialogTransportFlowId: transportFlowId
        });
    }

    createFlow = (): void => {
        this.setState({
            isTransportFlowFormViewOpened: true,
            flowDialogMode: creationMode
        });
    }

    searchVehiclesTripsByTransportFlowId = (businessId: string): void => {
        if (businessId === this.state.selectedFlow) {
            this.setState({
                selectedFlow: null
            });
            this.inputSearchTextRef.current.value = '';
            this.handleKeyPress('');
        } else {
            this.setState({
                selectedFlow: businessId
            });
            this.inputSearchTextRef.current.value = businessId;
            this.handleKeyPress(businessId);
        }
    }

    handleSearchTransportFlow = (isNegativeRemainingQtyFilterSelected: boolean): void => {
        this.setState({ isNegativeRemainingQtyFilterSelected: isNegativeRemainingQtyFilterSelected })
        this.searchTransportFlow(this.state.flowSearchtext, this.state.addedDaysNumberChoice, this.state.date, isNegativeRemainingQtyFilterSelected, true);
    }

    handleOpenFilter = (): void => {
        this.setState({
            isFilterSelected: !this.state.isFilterSelected
        });
    }

    handleChangeRefreshVehicles = (): void => {
        this.setState({
            refreshDailyVehicles: false,
            refreshContractualVehicles: false
        });
    }

    //Fermer les sections sauvegardées lors du chargement des events
    handleEventsVersionChanged = () => {
        if (this.state.needGrouping) {
            const { collapsedSenderSiteGroup, collapsedReceiverSiteGroup, collapsedBeneficiariesGroup, sorters } = this.state;

            const scheduler = () => this.schedulerRef.current?.instance as Scheduler;
            const groupingField = (scheduler().resourceStore as any)?.groupInfo?.field;
            switch (this.groupingName) {
                case DefaultGroupName:
                    {
                        if (groupingField) {
                            scheduler().resourceStore.clearGroupers();
                            (scheduler().resourceStore as any).groupInfo = undefined;
                            //désactiver le grouping avant le tri principale car il existe un tri derrière le grouping qui sera supprimé en le désactivant
                            let sorterItems: SortDescriptor[] = sorters;
                            if (!sorterItems || sorterItems.length == 0) {
                                sorterItems = [{ field: 'vehicleNumber', ascending: true }];
                            }
                            scheduler().resourceStore.sort(sorterItems);
                        }
                        break;
                    }
                case SenderSitesGroupName:
                    {
                        if (groupingField !== "senderSite") {
                            let sorterItems: SortDescriptor[] = sorters;
                            if (!sorterItems || sorters.length == 0) {
                                sorterItems = [{ field: 'senderSite', ascending: true }];
                            }
                            scheduler().resourceStore.sort(sorterItems);
                            scheduler().resourceStore.group('senderSite', true);
                        }
                        break;
                    }
                case ReceiverSitesGroupName:
                    {
                        if (groupingField !== "receiverSite") {
                            let sorterItems: SortDescriptor[] = sorters;
                            if (!sorterItems || sorterItems.length == 0) {
                                sorterItems = [{ field: 'receiverSite', ascending: true }];
                            }
                            scheduler().resourceStore.sort(sorterItems);
                            scheduler().resourceStore.group('receiverSite', true);
                        }
                        break;
                    }
                case BeneficiariesGroupName:
                    {
                        if (groupingField !== "beneficiary") {
                            let sorterItems: SortDescriptor[] = sorters;
                            if (!sorterItems || sorterItems.length == 0) {
                                sorterItems = [{ field: 'beneficiary', ascending: true }];
                            }
                            scheduler().resourceStore.sort(sorterItems);
                            scheduler().resourceStore.group('beneficiary', true);
                        }
                        break;
                    }
            }

            if (this.groupingName != DefaultGroupName) {
                const collapsedSiteGroupDate = this.groupingName === SenderSitesGroupName
                    ? collapsedSenderSiteGroup
                    : this.groupingName === ReceiverSitesGroupName
                        ? collapsedReceiverSiteGroup
                        : collapsedBeneficiariesGroup;
                scheduler().features.group.expandAll();
                scheduler().resourceStore.allRecords.forEach((record: any) => {
                    if (record.meta.specialRow) {
                        const rowGroupName = record.meta.groupRowFor;
                        if (collapsedSiteGroupDate.has(rowGroupName)) {
                            scheduler().features.group.toggleCollapse(record, true);
                        }
                    }
                });
            }

            this.setState({
                needGrouping: false
            });
        }
    }

    //Sauvegarder la section dans le localStorage à chaque fermeture ou ouverture dans le cas d'un groupement seulement(Site de départ et site d'arrivée)
    handleCellClick = (record: any): void => {
        //Si c'est la ligne de groupement
        if (record.meta.specialRow) {
            const { date } = this.state;
            const currentNumericDate = date.stripTime().toNumericDate();
            const rowGroupName = record.meta.groupRowFor;
            const collapsed = !record.meta.collapsed;
            this.handleCollapse(collapsed, currentNumericDate, rowGroupName);
        }
    }

    handleCollapse = (collapsed: boolean, currentNumericDate: number, rowGroupName: string): void => {
        const { collapsedSenderSiteGroup, collapsedReceiverSiteGroup } = this.state;
        const collapsedSiteGroup = this.groupingName == SenderSitesGroupName
            ? collapsedSenderSiteGroup : collapsedReceiverSiteGroup;

        if (collapsed) {
            collapsedSiteGroup.add(rowGroupName);
        }
        else {
            collapsedSiteGroup.delete(rowGroupName);
        }

        this.setCollapsedSiteGroup(currentNumericDate, this.groupingName, collapsedSiteGroup);
    }

    getCustomBusinessErrors = (data: WebAppActionResult, date?: Date, licencePlate?: string): string[] => {
        const businessErrors: string[] = [];
        if (data.commandResults[0].hasBusinessErrors == true) {
            data.commandResults[0].businessErrors.forEach(error => {
                if (error.name == 'NoPriceIsAvailableForThisTransporter')
                    businessErrors.push("Aucun tarif disponible pour ce transporteur sur l'AL " + data.commandResults[0].aggregateId + " à la date " + Date.getDateFromIsoString(date));
                else if (error.name == 'ThisVehicleIsNotavailableForThisTransporter')
                    businessErrors.push("Ce camion n'est pas disponible pour cette zone logistique pour l'AL " + data.commandResults[0].aggregateId + " à la date " + Date.getDateFromIsoString(date));
                else if (error.name == 'VehicleAlreadyAssociatedOnPlanningDate')
                    businessErrors.push("Le camion " + licencePlate + " est déjà associé");
                else {
                    const errorText = BusinessErrors.GetError(error);
                    businessErrors.push(errorText);
                }
            });
        }
        return businessErrors;
    }

    onReorderHandler = (columns: Array<ColumnConfig>): void => {
        const newColumns = columns.map(x => x.field);
        const mapColumns: Array<OrderedField> = [];
        newColumns.forEach((column, index) => {
            mapColumns.push({
                field: column,
                orderIndex: index
            });
        });

        LocalStorage.SetItem(ModuleKey, PlanningVehiclesGridOrderIndexes, JSON.stringify(mapColumns));
    }

    onResizeHandler = (newWidth: number): void => {
        LocalStorage.SetItem(ModuleKey, PlanningVehiclesGridWidth, String(newWidth));
    }

    handleChangeFilterSelected = (filtersSelected: Array<TextSearchField>) => {
        //vérifier s'il y'a eu du changement sur la liste des filtres
        const difference: Array<TextSearchField> = this.state.filtersSelected
            .filter(x => !filtersSelected.includes(x))
            .concat(filtersSelected.filter(x => !this.state.filtersSelected.includes(x)));

        this.setState({
            lastTimeStampFilteredSelected: difference.length > 0 ? Date.now() : this.state.lastTimeStampFilteredSelected,
            filtersSelected: filtersSelected
        });
    }

    handleOpenSendingSmsModal = async (): Promise<void> => {
        this.setState({
            refreshToOpenSmsModal: true
        });

        const newState = await this.handleRefreshBeforeOpeningSendingSmsModal();
        this.setState(newState);
    }

    handleCloseSmsNotificationsDialog = (): void => {
        this.setState({
            isSmsNotificationDialogOpened: false
        });
    }

    sendSmsNotifications = async (selectedNotifications: DriverNotificationModel[]): Promise<void> => {
        this.setState({
            isSummaryOfSentSmsMessagesModalOpened: true,
            sendingSmsLoading: true,
            numberOfFailedSendingSms: 0,
            numberOfSuccessfullySendingSms: 0,
            isSmsNotificationDialogOpened: false
        });

        const orderedSelectedNotifications = orderBy(selectedNotifications, x => [x.driverId, x.firstTripStartTime]);
        const config = SettingsProvider.Get();
        let finished = false;
        let indexStart = 0;
        const nbElemAll: number = orderedSelectedNotifications.length;
        const smsNotificationSendingBatchSize: number = config.smsNotificationSendingBatchSize;
        let indexEnd: number = nbElemAll > smsNotificationSendingBatchSize ? smsNotificationSendingBatchSize : nbElemAll;

        let numberOfFailedSendingSms = 0;
        let numberOfSuccessfullySendingSms = 0;

        while (!finished) {
            if (indexStart >= nbElemAll) {
                finished = true;

                this.setState({
                    sendingSmsLoading: false
                });
            } else {
                const tSmsNotifications = orderedSelectedNotifications.slice(indexStart, indexEnd);
                const notificationsRequestArgsItems: SendSmsNotificationsRequestArgsItem[] = [];
                tSmsNotifications.forEach((notification: DriverNotificationModel) => {
                    notificationsRequestArgsItems.push({
                        driverId: notification.driverId,
                        driverName: notification.driverName,
                        driverPhoneNumber: notification.driverPhoneNumber,
                        planningDateAsNumeric: notification.planningDateAsNumeric,
                        planningVehicleId: notification.planningVehicleId,
                        transportFlowId: notification.transportFlowId,
                        smsContent: notification.smsContent,
                        firstTripStartTime: notification.firstTripStartTime
                    });
                });

                const requestArgs: SendSmsNotificationsRequestArgs = {
                    smsNotificationsRequests: notificationsRequestArgsItems
                }

                await OperationalMonitoringApiClient.SendPlanningBriefingsSmsToDrivers(requestArgs)
                    .then((res) => {
                        numberOfFailedSendingSms += res.data.commandResults.filter(x => x.hasBusinessErrors).length;
                        numberOfSuccessfullySendingSms += res.data.commandResults.filter(x => !x.hasBusinessErrors).length;

                        this.setState({
                            numberOfFailedSendingSms: numberOfFailedSendingSms,
                            numberOfSuccessfullySendingSms: numberOfSuccessfullySendingSms,
                        });
                    })
                    .catch(() => {
                        numberOfFailedSendingSms += tSmsNotifications.length;
                        numberOfSuccessfullySendingSms += 0;

                        this.setState({
                            numberOfFailedSendingSms: numberOfFailedSendingSms,
                            numberOfSuccessfullySendingSms: numberOfSuccessfullySendingSms,
                        });
                    })
                    .finally(() => {
                        indexStart = indexStart + smsNotificationSendingBatchSize;
                        indexEnd = indexStart + smsNotificationSendingBatchSize;
                    });
            }
        }
    }

    getEventBodyTemplate = (eventRecord: any): string => {
        const data: EventModelData = eventRecord.data;
        const flowStatusClass = data.serviceKind != 'Delivery' ? data.serviceKind : (data.isPerishableProduct ? 'Delivery_Perishable' : 'Delivery_Not_Perishable');
        const eventStatusClass = `status-${data.eventStatus}`;
        const priorityStatusClass = `criticity-${data.priority}`;

        let hasNegotiatedOrSpecificPrice = false;
        let classTripPriceKind = "";
        let classTripPriceKindFont = "";
        let priceKind = "";

        if (data.priceKind && data.price !== null && data.transporterId != null) {
            hasNegotiatedOrSpecificPrice = true;
            classTripPriceKind = "specific-price-kind";
            classTripPriceKindFont = "specific-price-kind-font";
            priceKind = data.priceKind;
        }
        else if (data.transportFlowNegotiatedPurchasePriceKind && data.transportFlowNegotiatedPurchaseUnitPrice && data.transportFlowNegotiatedPurchasePriceVehicleTypeGroupId === data.transportFlowVehicleTypeGroupId
            && data.negotiatedPurchasePriceAppliesOnTrip) {
            hasNegotiatedOrSpecificPrice = true;
            classTripPriceKind = "negotiated-price-kind";
            classTripPriceKindFont = "negotiated-price-kind-font";
            priceKind = data.transportFlowNegotiatedPurchasePriceKind;
        }

        return `
                    <div class="event-sch ${flowStatusClass} ${eventStatusClass} ${priorityStatusClass}">
                        <div class="trip-sch ${data.eventStatus}"></div>
                        <div class="flow-sch">
                            ${data.name ? `<div> ${data.name}</div>` : ''}
                            ${data.name2 ? `<div> ${data.name2}</div>` : ''}
                        </div>
                        ${hasNegotiatedOrSpecificPrice ? `<div  class=${classTripPriceKind}>
                            <div id="trip-price-${data.deliveryTripId}">
                                <div class=${classTripPriceKindFont}>
                                    ${priceKind === PriceKind.PerHour ? `<div class=${classTripPriceKindFont}>H</div>` : ''}
                                    ${priceKind === PriceKind.PerTon ? `<div class=${classTripPriceKindFont}>T</div>` : ''}
                                    ${priceKind === PriceKind.PerTurn ? `<div class=${classTripPriceKindFont}>Tr</div>` : ''}
                                    </div>
                                </div>
                            </div>` : ''}
                        <div class="event-trip-status" id="event-trip-${data.deliveryTripId}"></div>
                    </div>`
    }

    handleHideSummaryOfSentSmsMessagesModal = async (): Promise<void> => {
        const newState = await this.handleRefreshAfterSmsModalsClosed();
        this.setState(newState);
    }

    getDriverSmsNotifications = (planningVehicleId: string, driverId: number, driverName: string, planningDateAsNumeric: number, driverPhoneNumber: string, driverPhoneNumberIsMobile: boolean): void => {
        const sendDriverFreeSmsRequestArgs: SendDriverFreeSmsRequestArgs = {
            driverId: driverId,
            driverName: driverName,
            planningDateAsNumeric: planningDateAsNumeric,
            driverPhoneNumber: driverPhoneNumber,
            planningVehicleId: planningVehicleId,
            smsContent: ''
        };

        OperationalMonitoringApiClient.GetDriverSmsNotifications(planningVehicleId, driverId)
            .then((res) => {
                this.setState({
                    isDriverSmsNotificationsDialogOpened: true,
                    driverSmsPhoneNumberIsMobile: driverPhoneNumberIsMobile,
                    selectedDriverName: driverName,
                    driverSmsNotifications: orderBy(res.data, o => [o.sendingDate], ['desc']),
                    sendDriverFreeSmsRequestArgs: sendDriverFreeSmsRequestArgs
                });
            });
    }

    handleCloseDriverSmsNotificationsDialog = async (): Promise<void> => {
        const newState = await this.handleRefreshAfterSmsModalsClosed();
        this.setState(newState);
    }

    handleCloseCancelPlanningVehicleDialog = (): void => {
        this.setState({
            isCancelPlanningVehicleDialogOpened: false
        });
    }

    handleCloseCancelDeliveryTripDialog = (): void => {
        this.setState({
            isCancelDeliveryTripsDialogOpened: false
        });
    }

    handleCloseAttachZephyrFlowManuallyDialog = (): void => {
        this.setState({
            isAttachZephyrFlowManuallyOpened: false
        });
    }

    handleHideConfirmationCancelingPlanningVehicle = (): void => {
        this.setState({
            showConfirmationCancelingPlanningVehicleAction: false,
            planningVehicleIdToCancel: null
        });
    }

    handleCancelReinstatePlanningVehicle = (planningVehicleId: string, isCanceled: boolean, trips: TripLightModel[]): void => {
        if (!isCanceled) {
            this.cancelPlanningVehicle(planningVehicleId, trips);
        }
        else {
            this.reinstatePlanningVehicle(planningVehicleId);
        }
    }

    cancelPlanningVehicle = (planningVehicleId: string, trips: TripLightModel[]): void => {
        let isInProgressFinishedStatus = false;
        for (let i = 0; i < trips.length; i++) {
            if (trips[i].status === "InProgress" || trips[i].status === "Finished") {
                isInProgressFinishedStatus = true;
                break;
            }
        }

        if (isInProgressFinishedStatus) {
            this.setState({
                showConfirmationCancelingPlanningVehicleAction: true,
                planningVehicleIdToCancel: planningVehicleId
            });

            return;
        }

        this.setState({
            isCancelPlanningVehicleDialogOpened: true,
            planningVehicleIdToCancel: planningVehicleId
        });
    }

    handleSaveCancelPlanningVehicleDialog = (cancellationReason: CancellationReason, cancellationRemarks: string, purchaseIsCanceled: boolean, saleIsCanceled: boolean): void => {
        const requestArgs: CancelPlanningVehicleRequestArgs = {
            planningVehicleId: this.state.planningVehicleIdToCancel,
            cancellationReason: cancellationReason,
            cancellationRemarks: cancellationRemarks,
            purchaseIsCanceled: purchaseIsCanceled,
            saleIsCanceled: saleIsCanceled,
            logisticsUnits: this.props.logisticsUnitIds,
            existenceOfInProgressOrFinishedTripHasBeenConfirmed: this.state.existenceOfInProgressOrFinishedTripHasBeenConfirmed
        };

        this.setState({
            loadingDataScheduler: true
        });

        OperationalMonitoringApiClient.CancelPlanningVehicle(requestArgs)
            .then(async (res) => {
                const newState = await this.refreshAfterCancelPlanningVehicleAction(res.data);
                this.setState(newState);
            });
    }

    handleRemoveSpecificPriceFromDeliveryTrips = (): void => {
        const trips = this.state.deliveryTripsSelected;

        const firstTrip = trips[0];
        const delTripchange: RemoveSpecificPriceFromDeliveryTripsRequestArgs = {
            planningVehicleId: firstTrip.planningVehicleId,
            transporterId: firstTrip.transporterId,
            deliveryTrips: trips.map(x => ({ transportRequestId: x.transportRequestId, deliveryTripId: x.deliveryTripId }))
        };

        OperationalMonitoringApiClient.RemoveSpecificPriceFromDeliveryTrips(delTripchange)
            .then(async res => {
                const data = res?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);

                    this.setState({
                        loadingDataScheduler: false,
                        isDialogUpdateSpecificPriceOpened: false,
                        dialogTitle: "",
                        deliveryTripsSelected: []
                    });
                    return;
                }
                this.setState({
                    loadingDataScheduler: true
                });

                const newState = await this.refreshAfterSchedulerTripsAction(data);
                newState.isDialogUpdateSpecificPriceOpened = false;
                newState.dialogTitle = "";
                this.setState(newState);
            });
    }

    handleConfirmValidationExistingSpecificOrNegotiatedPrice = (): void => {
        this.UpdateSpecificPriceOfDeliveryTrips(this.state.specificPriceToUpdate, this.state.specificPriceKindToUpdate, true);
    }

    handleClickCloseConfirmValidationExistingSpecificOrNegotiatedPrice = (): void => {
        this.setState({
            isConfirmValidationExistingSpecificOrNegotiatedPriceDialogOpened: false
        });
    }

    UpdateSpecificPriceOfDeliveryTrips = (specificPrice: number, specificPriceKind: string, hasConfirmedExistingSpecificOrNegotiatedPriceOverriden: boolean): void => {
        const trips = this.state.deliveryTripsSelected;

        const firstTrip = trips[0];
        const delTripchange: UpdateSpecificPriceOfDeliveryTripsRequestArgs = {
            planningVehicleId: firstTrip.planningVehicleId,
            transporterId: firstTrip.transporterId,
            specificPrice: specificPrice,
            specificPriceKind: specificPriceKind,
            hasConfirmedExistingSpecificOrNegotiatedPriceOverriden: hasConfirmedExistingSpecificOrNegotiatedPriceOverriden,
            deliveryTrips: trips.map(x => ({ transportRequestId: x.transportRequestId, deliveryTripId: x.deliveryTripId }))
        };

        this.setState({
            actionUpdateSpecificPricesPending: true
        });

        OperationalMonitoringApiClient.UpdateSpecificPriceOfDeliveryTrips(delTripchange)
            .then(async res => {
                const data = res?.data;
                const errors = BusinessErrors.Get(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);

                    this.setState({
                        loadingDataScheduler: false,
                        isDialogUpdateSpecificPriceOpened: false,
                        deliveryTripsSelectedToUpdateSpecificPricesHasSpecificOrNegotiatedPriceOverriden: false,
                        isConfirmValidationExistingSpecificOrNegotiatedPriceDialogOpened: false,
                        actionUpdateSpecificPricesPending: false,
                        dialogTitle: ""
                    });
                    return;
                }
                this.setState({
                    loadingDataScheduler: true,
                    actionUpdateSpecificPricesPending: false
                });

                const newState = await this.refreshAfterSchedulerTripsAction(data);
                newState.isDialogUpdateSpecificPriceOpened = false;
                newState.deliveryTripsSelectedToUpdateSpecificPricesHasSpecificOrNegotiatedPriceOverriden = false;
                newState.isConfirmValidationExistingSpecificOrNegotiatedPriceDialogOpened = false;
                newState.actionUpdateSpecificPricesPending = false;
                newState.specificPriceToUpdate = 0;
                newState.specificPriceKindToUpdate = "";
                newState.dialogTitle = "";
                this.setState(newState);
            });
    }

    handleUpdateSpecificPriceOfDeliveryTrips = (specificPrice: number, specificPriceKind: string): void => {
        if (this.state.deliveryTripsSelectedToUpdateSpecificPricesHasSpecificOrNegotiatedPriceOverriden) {
            this.setState({
                isConfirmValidationExistingSpecificOrNegotiatedPriceDialogOpened: true,
                specificPriceToUpdate: specificPrice,
                specificPriceKindToUpdate: specificPriceKind
            });
        }
        else {
            this.UpdateSpecificPriceOfDeliveryTrips(specificPrice, specificPriceKind, false);
        }
    }

    reinstatePlanningVehicle = (planningVehicleId: string): void => {
        const requestArgs: ReinstatePlanningVehicleRequestArgs = {
            planningVehicleId: planningVehicleId,
            logisticsUnits: this.props.logisticsUnitIds
        };

        this.setState({
            loadingDataScheduler: true
        });

        OperationalMonitoringApiClient.ReinstatePlanningVehicle(requestArgs)
            .then(async (res) => {
                const newState = await this.refreshAfterCancelPlanningVehicleAction(res.data);
                this.setState(newState);
            });
    }

    handleConfirmCancelingPlanningVehicle = (): void => {
        this.setState({
            isCancelPlanningVehicleDialogOpened: true,
            existenceOfInProgressOrFinishedTripHasBeenConfirmed: true,
            showConfirmationCancelingPlanningVehicleAction: false
        });
    }

    displayError = (message: string): void => {
        ToastService.showErrorToast(message);
    }

    isTomorrow = (date: Date): boolean => {
        return date.getDate() == new Date().addDays(1).getDate();
    }

    render() {
        const searchTextValue = this.inputSearchTextRef.current === null || this.inputSearchTextRef.current === undefined ? '' : this.inputSearchTextRef.current.value;
        const inputSearchFlowValue = this.inputSearchFlowRef.current === null || this.inputSearchFlowRef.current === undefined ? '' : this.inputSearchFlowRef.current.value;
        const { vehicleGroupCounters: vehicleTypeCount, transportMargins, dialogTitle, isFilterSelected, existsResourcesToReceiveSmsNotification, existsEmptyVehicles, resourcesWithDriversAndMobilePhoneNumber, resources, events, eventsVersion } = this.state;
        const isSmsButtonEnabled: boolean = existsResourcesToReceiveSmsNotification && !this.state.loadingDataScheduler && this.state.date.getDayStart() >= new Date().getDayStart();

        const contents: JSX.Element =
            <div className="scheduler-expedition h-100">
                <ExpeditionScheduler schedulerRef={this.schedulerRef}
                    columns={this.planningVehiclesGridColumns}
                    rowHeight={45}
                    barMargin={0}
                    role={this.props.role}
                    resources={resources}
                    events={events}
                    eventsVersion={eventsVersion}
                    subGridConfigs={this.subGridConfigs}
                    eventBodyTemplate={data => this.getEventBodyTemplate(data)}
                    handleRemovePlanningVehicle={this.handleRemovePlanningVehicle}
                    handleClickOpenDuplicatePlanningVehicle={this.handleClickOpenDuplicatePlanningVehicle}
                    handleMoveTripsToNewPlanningVehicle={this.handleMoveTripsToNewPlanningVehicle}
                    handleRemovePlanningVehicleTransporter={this.handleRemovePlanningVehicleTransporter}
                    handleRemoveContractualPurchasePriceFromPlanningVehicle={this.handleRemoveContractualPurchasePriceFromPlanningVehicle}
                    handleCancelReinstatePlanningVehicle={this.handleCancelReinstatePlanningVehicle}
                    editFlow={this.editFlow}
                    duplicateFlow={this.duplicateFlow}
                    handleAssignDeliveryTripStatus={this.handleAssignDeliveryTripStatus}
                    handleDeliveryTripsCancellation={this.handleDeliveryTripsCancellation}
                    handleUpdateSpecificPriceOfDeliveryTrips={this.handleClickOpenUpdateSpecificPrice}
                    removeSelectedTrips={this.removeDeliveryTrips}
                    attachZephyrReturnManually={this.attachZephyrReturnManually}
                    refreshMenuItemEditFlow={this.refreshMenuItemEditFlow}
                    refreshMenuItemUpdateSpecificPriceFromDeliveryTrips={(item, eventData) => this.refreshMenuItemUpdateSpecificPriceFromDeliveryTrips(item, eventData, this.state)}
                    refreshMenuItemDuplicateFlow={this.refreshMenuItemDuplicateFlow}
                    refreshMenuItemRemoveTrips={(item, hideFlow, eventData) => this.refreshMenuItemRemoveTrips(item, hideFlow, eventData, this.state)}
                    refreshMenuItemEditTripStatus={(item: any, eventData) => this.refreshMenuItemEditTripStatus(item, eventData, this.state)}
                    refreshMenuItemRemovePlanningVehicle={this.refreshMenuItemRemovePlanningVehicle}
                    refreshMenuItemDuplicatePlanningVehicle={this.refreshMenuItemDuplicatePlanningVehicle}
                    refreshMenuItemRemovePlanningVehicleTransporter={this.refreshMenuItemRemovePlanningVehicleTransporter}
                    refreshMenuItemCancelPlanningVehicle={this.refreshMenuItemCancelPlanningVehicle}
                    refreshMenuItemReinstatePlanningVehicle={this.refreshMenuItemReinstatePlanningVehicle}
                    refreshMenuItemRemoveContractualPurchasePriceFromPlanningVehicle={this.refreshMenuItemRemoveContractualPurchasePriceFromPlanningVehicle}
                    refreshMenuItemMoveSelectedTrips={this.refreshMenuItemMoveSelectedTrips}
                    refreshAttachZephyrReturnManually={(item, eventData) => this.refreshAttachZephyrReturnManually(item, eventData, this.state)}
                    eventSelectionHandler={this.eventSelectionHandler}
                    rowGridSelectionHandler={this.rowGridSelectionHandler}
                    showEventDetail={this.showEventDetail}
                    handleTripsDropToNewLoadingTime={this.handleTripsDropToNewLoadingTime}
                    handleCellClick={this.handleCellClick}
                    onResizeTripToNewDeliveryTime={this.onResizeTripToNewDeliveryTime}
                    createTripFromFlow={this.createTripFromFlow}
                    onReorderPlanningVehiclesGridColumnHandler={this.onReorderHandler}
                    onResizePlanningVehiclesGridColumnHandler={this.onResizeHandler}
                    handleEventsVersionChanged={this.handleEventsVersionChanged}
                    displayError={this.displayError}
                />
            </div>

        const config = SettingsProvider.Get();
        const smsNotificationMaxContentLength = config.smsNotificationMaxContentLength;

        return (
            <>
                <Box display="flex" flexWrap="nowrap" flexDirection="column" minHeight="600px" height="100%" className={clsx({
                    ['expeditions-content-shiftLeft']: this.state.isVehiclesSelectorOpen,
                    ['expeditions-content-shiftRight']: this.state.isFlowManagementDrawerOpened
                })}>
                    <Box pb={2} height="100%" className='expeditions-content'>
                        <Box display="flex" flexWrap="nowrap" flexDirection="column" className="parent-card-dc" height="100%" >
                            <Box className="div-linear-progress">
                                {this.state.loadingDataScheduler ? <LinearProgress className="loader-linear-progress" /> : <div className="empty-div" />}
                            </Box>
                            <Box display="flex" flexWrap="wrap" flexDirection="row" alignItems="center" pb={1}>
                                <Box width='55%' height="100%" pt={1} flexWrap='wrap' display='flex' flexDirection='row'>
                                    <Box display="flex" flexDirection="column">
                                        <Box display="flex" minWidth='150px' pb='2px' flexWrap="wrap" flexDirection="row">
                                            {
                                                !this.state.isVehiclesSelectorOpen && this.isLogisticianUser ? (
                                                    <Box display="flex" flexWrap="nowrap" flexDirection="row">
                                                        <Avatar className="btn-track">
                                                            <IconButton size="small" className="btn-icon" onClick={this.handleVehiclesSelectorOpen}>
                                                                <FontAwesomeIcon icon={faTruck} />
                                                            </IconButton>
                                                        </Avatar>
                                                    </Box>
                                                ) : ''
                                            }
                                            <Box display="flex" flexDirection="row" pl={2}>
                                                <LocalizationProvider language="fr-FR">
                                                    <IntlProvider locale="fr" >
                                                        <DatePicker defaultValue={this.defaultValue} ref={this.datePickerRef} onChange={this.handleDatepickerChange} className={this.isTomorrow(this.state.date) ? '' : 'highlighted'} />
                                                    </IntlProvider>
                                                </LocalizationProvider>
                                            </Box>
                                            <Box minWidth='350px' display="flex" flexDirection="row" justifyContent="flex-end" pl={2} pt={0.5} pb={0.5}>
                                                <Input disableUnderline className={`input-search-vehicles ${searchTextValue.length > 2 ? ' search-text-active' : ''}`} inputRef={this.inputSearchTextRef}
                                                    endAdornment={<>
                                                        <InputAdornment position="end" classes={
                                                            {
                                                                root: 'input-icon-close-root'
                                                            }
                                                        }>
                                                            <FontAwesomeIcon icon={faTimes} onClick={this.clearFilter} />
                                                        </InputAdornment>
                                                        <InputAdornment position="end" classes={
                                                            {
                                                                root: 'input-icon-search-root'
                                                            }
                                                        }>
                                                            <FontAwesomeIcon icon={faSearch} className="icon-search" />
                                                        </InputAdornment>
                                                    </>}
                                                    placeholder="Rechercher un chantier, un Flux, un véhicule, etc." onChange={(e) => this.handleKeyPress(e.target.value)} />
                                            </Box>
                                            <Box pl={2} minWidth='250px' pt={0.5} pb={0.5}>
                                                <TextSearchFieldsSelectorComponent<TextSearchField> selectedAll={true} options={this.filterOptions} onChange={this.handleChangeFilterSelected} placeHolder='Rechercher dans:' noOptionsMessage='Pas de filtres disponibles.' />
                                            </Box>
                                        </Box>
                                        <Box display="flex" flexDirection="row" pl={2}>
                                            <Box>
                                                <Avatar className="btn-actions">
                                                    <IconButton size="small" onClick={() => this.handleRefreshButtonClicked(this.state)} id="btn-refresh-trip" className="btn-icon">
                                                        <FontAwesomeIcon icon={faSync} />
                                                    </IconButton>
                                                </Avatar>
                                            </Box>
                                            {this.isLogisticianUser &&
                                                <>
                                                    <Box pl={1}>
                                                        <Tooltip title="Ajouter des camions" placement="bottom">
                                                            <Avatar className="btn-actions">
                                                                <IconButton size="small" className="btn-icon" aria-label="Add" onClick={() => this.handleClickOpenCreatePlanningVehicle('Ajouter des camions')}>
                                                                    <FontAwesomeIcon icon={faPlusCircle} />
                                                                </IconButton>
                                                            </Avatar>
                                                        </Tooltip>
                                                    </Box>
                                                    <Box pl={1}>
                                                        <Tooltip title="Supprimer tous les camions vides" placement="bottom">
                                                            <Avatar className="btn-actions">
                                                                <IconButton size="small" className="btn-icon" aria-label="Add"
                                                                    disabled={!existsEmptyVehicles} onClick={this.removeEmptyPlanningVehicles}>
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </IconButton>
                                                            </Avatar>
                                                        </Tooltip>
                                                    </Box>
                                                    <Box pl={1}>
                                                        <Tooltip title="Envoi de sms aux chauffeurs internes et externes" placement="bottom">
                                                            <Avatar className="btn-actions">
                                                                <IconButton size="small" className="btn-icon" aria-label="SendSms"
                                                                    disabled={!isSmsButtonEnabled} onClick={this.handleOpenSendingSmsModal}>
                                                                    <FontAwesomeIcon size="lg" icon={faSms} />
                                                                </IconButton>
                                                            </Avatar>
                                                        </Tooltip>
                                                    </Box>
                                                </>
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                                {this.isLogisticianUser &&
                                    <Box minWidth='200px' width={this.state.isFlowManagementDrawerOpened ? '45%' : '40%'} height="100%" pt={1} pr={1}>
                                        <Box width="100%" display="flex" flexWrap="wrap" flexDirection="row" alignItems="center" justifyContent="flex-end" pl={2}>
                                            <Box minWidth='200px'>
                                                <TransportMarginsComponent transportMargins={transportMargins} />
                                            </Box>
                                            <Box minWidth='250px'>
                                                <VehicleGroupCountersComponent {...this.props} vehicleGroupCounters={vehicleTypeCount} showDailyCounters={this.state.date > new Date()}
                                                    lastTimeStampGetVehicleGroupCounters={this.state.lastTimeStampGetVehicleGroupCounters ? new Date(this.state.lastTimeStampGetVehicleGroupCounters) : null} />
                                            </Box>
                                        </Box>
                                    </Box>
                                }
                                {
                                    !this.state.isFlowManagementDrawerOpened && this.isLogisticianUser ? (
                                        <Box width="5%" height="100%" display="flex" flexDirection="row" justifyContent="flex-end" pl={2} pt={1}>
                                            <Box display="flex" flexWrap="nowrap" flexDirection="row">
                                                <Tooltip title="Gestion des Flux" placement="bottom">
                                                    <Avatar className="btn-flow">
                                                        <IconButton size="small" className="btn-icon" onClick={this.handleFlowDrawerOpen}>
                                                            <FontAwesomeIcon icon={faArrowCircleRight} />
                                                        </IconButton>
                                                    </Avatar>
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    ) : ''
                                }
                            </Box>
                            <Divider className="divider-filter" />
                            <Box display="flex" flexDirection="column" pt={2} pb={2} className="vehicle-management-buttons">
                                <Box display="flex" flexWrap="wrap" flexDirection="row" alignItems="center">
                                    <Box display="flex" flexDirection="column" pl={2}>
                                        <div className="filter-title">
                                            Infos tours
                                        </div>
                                        <Box display="flex" flexDirection="row">
                                            <Button id="tripNumberBtn" onClick={(event) => this.handleChangeLabel(event, "tripNumber")}>
                                                # N&#xB0;
                                            </Button>
                                            <Button id="cityBtn" onClick={(event) => this.handleChangeLabel(event, "city")}>
                                                Villes
                                            </Button>
                                            <Button id="cchBtn" onClick={(event) => this.handleChangeLabel(event, "cch")}>
                                                Bénéficiaire
                                            </Button>
                                            <Button id="productBtn" onClick={(event) => this.handleChangeLabel(event, "product")}>
                                                Chargement
                                            </Button>
                                        </Box>
                                    </Box>
                                    <Box display="flex" flexDirection="column" pl={5}>
                                        <div className="filter-title">
                                            Regrouper
                                        </div>
                                        <Box display="flex" flexDirection="row">
                                            <Button className={this.groupingName === DefaultGroupName ? "selected" : ""} id="defaultBtn" onClick={() => this.handleChangeSite(DefaultGroupName)}>
                                                Par défaut
                                            </Button>
                                            <Button className={this.groupingName === SenderSitesGroupName ? "selected" : ""} id="senderBtn" onClick={() => this.handleChangeSite(SenderSitesGroupName)}>
                                                Site de départ
                                            </Button>
                                            <Button className={this.groupingName === ReceiverSitesGroupName ? "selected" : ""} id="receiverBtn" onClick={() => this.handleChangeSite(ReceiverSitesGroupName)}>
                                                Site d'arrivée
                                            </Button>
                                            <Button className={this.groupingName === BeneficiariesGroupName ? "selected" : ""} id="beneficiaryBtn" onClick={() => this.handleChangeSite(BeneficiariesGroupName)}>
                                                Bénéficiaire
                                            </Button>

                                            <Box display="flex" flexDirection="row" height="100%" alignItems="center" className={clsx("div-open-filter", {
                                                ['filter-selected']: isFilterSelected,
                                                ['filter-not-selected']: !isFilterSelected
                                            })} onClick={() => this.handleOpenFilter()}>
                                                <FontAwesomeIcon className='icon-open-filter' icon={faFilter} />
                                                {!isFilterSelected ? "Afficher filtres" : "Masquer filtres"}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                {isFilterSelected &&
                                    <Box display="flex" flexDirection="row" flexWrap="wrap" pt={2}>
                                        <Box display="flex" flexDirection="column" pl={4}>
                                            <Box display="flex" flexDirection="row">
                                                <Box className="filter-title" width='50%'>
                                                    Prestation
                                                </Box>
                                                <Box className="div-filter-select-all" width='50%'>
                                                    <span className="filter-select-all" onClick={() => this.handleChangePrestation('all', null, null)}>Tout sélectionner</span>
                                                </Box>
                                            </Box>
                                            <Box display="flex" flexDirection="row">
                                                <Button id="perissableBtn" onClick={() => this.handleChangePrestation('prestation-perishable-selected', 'Delivery', true)}>
                                                    Périssable
                                                </Button>
                                                <Button id="notPerissableBtn" onClick={() => this.handleChangePrestation('prestation-not-perishable-selected', 'Delivery', false)}>
                                                    Non périssable
                                                </Button>
                                                <Button id="removalBtn" onClick={() => this.handleChangePrestation('prestation-removal-selected', 'Removal', false)}>
                                                    Enlèvement
                                                </Button>
                                                <Button id="jobSiteBtn" onClick={() => this.handleChangePrestation('prestation-jobsite-selected', 'JobsiteVehicle', null)}>
                                                    Camion chantier
                                                </Button>
                                            </Box>
                                        </Box>
                                        <Box display="flex" flexDirection="column" pl={4}>
                                            <Box display="flex" flexDirection="row">
                                                <Box className="filter-title" width='50%'>
                                                    Criticité Flux
                                                </Box>
                                                <Box className="div-filter-select-all" width='50%'>
                                                    <span className="filter-select-all" onClick={() => this.handleChangeCriticity('all')}>Tout sélectionner</span>
                                                </Box>
                                            </Box>
                                            <Box display="flex" flexDirection="row">
                                                <Button id="modulableBtn" onClick={() => this.handleChangeCriticity('criticity-modulable-selected')}>
                                                    Modulable
                                                </Button>
                                                <Button id="dayBtn" onClick={() => this.handleChangeCriticity('criticity-day-selected')}>
                                                    Journée
                                                </Button>
                                                <Button id="fixBtn" onClick={() => this.handleChangeCriticity('criticity-fix-selected')}>
                                                    Fixe
                                                </Button>
                                            </Box>
                                        </Box>
                                        <Box display="flex" flexDirection="column" pl={4}>
                                            <Box display="flex" flexDirection="row">
                                                <Box className="filter-title" width='50%'>
                                                    Statut tours
                                                </Box>
                                                <Box className="div-filter-select-all" width='50%'>
                                                    <span className="filter-select-all" onClick={() => this.handleChangeStatus('all')}>Tout sélectionner</span>
                                                </Box>
                                            </Box>
                                            <Box display="flex" flexDirection="row" height="100%">
                                                <Button id="genericBtn" onClick={() => this.handleChangeStatus('status-needed-selected')}>
                                                    <Box className="filter-button" display="flex" flexDirection="row" alignItems="center">
                                                        <div className="trip-sch trip-generic"></div>
                                                        Générique
                                                    </Box>
                                                </Button>
                                                <Button id="plannedBtn" onClick={() => this.handleChangeStatus('status-planned-selected')}>
                                                    <Box className="filter-button" display="flex" flexDirection="row" alignItems="center">
                                                        <div className="trip-sch trip-planned"></div>
                                                        Planifié
                                                    </Box>
                                                </Button>
                                                <Button id="confirmedBtn" onClick={() => this.handleChangeStatus('status-confirmed-selected')}>
                                                    <Box className="filter-button" display="flex" flexDirection="row" alignItems="center">
                                                        <div className="trip-sch trip-confirmed"></div>
                                                        Confirmé
                                                    </Box>
                                                </Button>
                                                <Button id="pendingBtn" onClick={() => this.handleChangeStatus('status-inprogress-selected')}>
                                                    <Box className="filter-button" display="flex" flexDirection="row" alignItems="center">
                                                        <div className="trip-sch trip-inProgress"></div>
                                                        En cours
                                                    </Box>
                                                </Button>
                                                <Button id="finishedBtn" onClick={() => this.handleChangeStatus('status-finished-selected')}>
                                                    <Box className="filter-button" display="flex" flexDirection="row" alignItems="center">
                                                        <div className="trip-sch trip-finished"></div>
                                                        Terminé
                                                    </Box>
                                                </Button>

                                                <Button id="canceledBtn" onClick={() => this.handleChangeStatus('status-canceled-selected')}>
                                                    <Box className="filter-button" display="flex" flexDirection="row" alignItems="center">
                                                        <div className="trip-sch trip-canceled"></div>
                                                        Annulé
                                                    </Box>
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                }
                            </Box>
                            <Divider className="divider-filter" />
                            {contents}
                        </Box>
                    </Box>
                </Box>
                {this.isLogisticianUser
                    && (
                        <>
                            <VehiclesSelectorDrawerView userName={this.props.userName} isVehiclesSelectorOpen={this.state.isVehiclesSelectorOpen}
                                planningDate={this.state.date} refreshDailyVehicles={this.state.refreshDailyVehicles} refreshContractualVehicles={this.state.refreshContractualVehicles}
                                logisticsUnitIds={this.props.logisticsUnitIds} byVehicleTypeGroupIdsFilterValue={this.state.byVehicleTypeGroupIdsFilterValue} byVehicleTypeGroupIdsFilterChanged={this.state.byVehicleTypeGroupIdsFilterChanged}
                                getUnusedReservationsChanged={this.state.getUnusedReservationsChanged}
                                handleDrawerLeftClose={this.handleDrawerLeftClose} handleAssignGenericVehicleType={this.handleAssignGenericVehicleType} handleChangeRefreshVehicles={this.handleChangeRefreshVehicles}
                                handleAssignTransporterVehicleType={this.handleAssignTransporterVehicleType} handleAssignInternalVehicle={this.handleAssignInternalVehicle} handleAssignDailyPlanningVehicle={this.handleAssignDailyPlanningVehicle}
                                handleAssignContractedVehicle={this.handleAssignContractedVehicle}
                            />
                            <FlowManagementDrawerComponent handleFlowManagementDrawerClose={this.handleFlowDrawerClose} isFlowManagementDrawerOpened={this.state.isFlowManagementDrawerOpened}
                                inputSearchFlowValue={inputSearchFlowValue} inputSearchFlowRef={this.inputSearchFlowRef} addedDaysNumberChoice={this.state.addedDaysNumberChoice}
                                clearSearchFlowText={this.clearSearchFlowText} handleSearchFlowKeyPress={this.handleSearchFlowKeyPress}
                                transportFlowList={this.state.transportFlowList} handlePeriodChoiceChange={this.handlePeriodChoiceChange}
                                handleOpenPopupCreateFlow={() => this.createFlow()} searchVehiclesTripsByTransportFlowId={this.searchVehiclesTripsByTransportFlowId}
                                searchTransportFlow={this.handleSearchTransportFlow} selectedFlow={this.state.selectedFlow}
                                handleHideFlowChange={this.handleFlowHiddenStateChange} editFlow={this.editFlow}
                                handleUpdateTripsNumberTransportFlow={this.handleUpdateTripsNumberTransportFlow}
                                handleUpdateTripsNumberIsForcedTransportFlow={this.handleUpdateTripsNumberIsForcedTransportFlow}
                                isNegativeFilterActived={this.state.isNegativeFilterActived} hasNegativeFlows={this.state.hasNegativeFlows}
                                isNegativeRemainingQtyFilterSelected={this.state.isNegativeRemainingQtyFilterSelected}
                            />
                        </>)}
                <SimpleDialog isOpen={this.state.isDialogCreatePlanningVehicleOpened}
                    onClose={() => this.handleClickCloseCreatePlanningVehicle()}
                    dialogTitle={dialogTitle}
                    classNameVal='manage-vehicle'
                    component={<AddVehicleComponent onClose={() => this.handleClickCloseCreatePlanningVehicle()}
                        vehicleTypes={this.state.vehicleTypes}
                        chosenLogisticsUnits={this.props.logisticsUnits ? this.props.logisticsUnits.filter(t => t.checked) : null}
                        handleCreatePlanningVehicles={this.handleCreatePlanningVehicles}
                        disableCreatePlanningVehicles={this.state.disableCreatePlanningVehicles}
                    />}
                />
                <SimpleDialog isOpen={this.state.isDialogDuplicatePlanningVehicleOpened}
                    onClose={() => this.handleClickCloseDuplicatePlanningVehicle()}
                    dialogTitle={dialogTitle}
                    classNameVal='manage-vehicle'
                    component={<DuplicateVehicleComponent onClose={() => this.handleClickCloseDuplicatePlanningVehicle()}
                        handleDuplicatePlanningVehicle={this.handleDuplicatePlanningVehicle}
                        disableDuplicatePlanningVehicles={this.state.disableDuplicatePlanningVehicles}
                    />}
                />
                <SimpleDialog isOpen={this.state.isDialogUpdateSpecificPriceOpened}
                    onClose={() => this.handleClickCloseUpdateSpecificPrice()}
                    dialogTitle={dialogTitle}
                    classNameVal='update-specificPrice-deliveryTrips'
                    component={<UpdateSpecificPriceOfDeliveryTripsComponent
                        actionPending={this.state.actionUpdateSpecificPricesPending}
                        availablePurchasePrices={this.availablePurchasePrices}
                        deliveryTrips={this.state.deliveryTripsSelected}
                        getPriceKind={this.getPriceKind}
                        onClose={() => this.handleClickCloseUpdateSpecificPrice()}
                        handleRemoveSpecificPriceFromDeliveryTrips={this.handleRemoveSpecificPriceFromDeliveryTrips}
                        handleUpdateSpecificPriceOfDeliveryTrips={this.handleUpdateSpecificPriceOfDeliveryTrips}
                    />}
                />
                <Modal show={this.state.showConfirmationRemoveTripsModal} onHide={this.handleHideModal} className='mt-5'>
                    <Modal.Header closeButton>
                        <Modal.Title>ATTENTION</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.removeTripsMode === actionType.deleteTripsHideFlow
                            ? "Confirmez-vous vouloir supprimer le(s) tour(s) et masquer le(s) flux sélectionné(s) ?"
                            : "Merci de confirmer la suppression des tours sélectionnés"}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="secondary" onClick={this.handleHideModal}>
                            NON
                        </Button>
                        <Button className="primary" onClick={() => this.state.removeTripsMode === actionType.deleteTripsHideFlow
                            ? this.handleConfirmRemoveTrips(true) : this.handleConfirmRemoveTrips(false)}>
                            OUI
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showConfirmationRemoveEmptyPlanningVehiclesModal} onHide={this.handleHideModal} className='mt-5'>
                    <Modal.Header closeButton>
                        <Modal.Title>ATTENTION</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Confirmez-vous la suppression de tous les camions vides ?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="secondary" onClick={this.handleHideModal}>
                            ANNULER
                        </Button>
                        <Button className="primary" onClick={this.handleConfirmRemoveEmptyPlanningVehicles}>
                            CONFIRMER
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showConfirmationPlanningVehicleModal} onHide={this.handleHideModal} className='mt-5'>
                    <Modal.Header closeButton>
                        <Modal.Title>ATTENTION</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.confirmationMessage.map((v, index) => {
                            return (<Box key={index} pb={1}>{v}</Box>)
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="secondary" onClick={this.handleHideModal}>
                            ANNULER
                        </Button>
                        <Button className="primary" onClick={async () => await this.handleConfirmPlanningVehicle(this.state.actionToBeConfirmed)}>
                            CONFIRMER
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showConfirmationRemoveAllPlanningVehicleTripsModal} onHide={this.handleHideModal} className='mt-5'>
                    <Modal.Header closeButton>
                        <Modal.Title>ATTENTION</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Au moins un camion est associé à un transporteur.<br />
                        Confirmez-vous l'action ?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="secondary" onClick={this.handleHideModal}>
                            ANNULER
                        </Button>
                        <Button className="primary" onClick={this.state.removeTripsMode === actionType.deleteTrips ? () => this.handleConfirmRemoveTrips(false)
                            : this.state.removeTripsMode === actionType.deleteTripsHideFlow ? () => this.handleConfirmRemoveTrips(true)
                                : this.state.removeTripsMode === actionType.moveTripRemove ? () => this.handleConfirmationMoveTrips(false)
                                    : () => this.handleConfirmDropTrips(false)}>
                            CONFIRMER
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showConfirmationSchedulerTripsAction} onHide={this.handleHideConfirmationUpdatingTrips} className='mt-5'>
                    <Modal.Header closeButton>
                        <Modal.Title>ATTENTION</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.confirmationMessage.map((v, index) => {
                            return (<div key={index}>{v}</div>)
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="secondary" onClick={this.handleHideConfirmationUpdatingTrips}>
                            ANNULER
                        </Button>
                        <Button className="primary" onClick={() => this.state.removeTripsMode == actionType.dropRemove ? this.handleConfirmDropTrips(true) :
                            (this.state.removeTripsMode == actionType.moveTripRemove ? this.handleConfirmationMoveTrips(true) : {})}>
                            CONFIRMER
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showConfirmationCancelingPlanningVehicleAction} onHide={this.handleHideConfirmationCancelingPlanningVehicle} className='mt-5'>
                    <Modal.Header closeButton>
                        <Modal.Title>ATTENTION</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Le camion possède au moins un tour au statut "En cours" ou "Terminé".
                        <br />
                        Confirmer-vous l'action ?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="secondary" onClick={this.handleHideConfirmationCancelingPlanningVehicle}>
                            ANNULER
                        </Button>
                        <Button className="primary" onClick={this.handleConfirmCancelingPlanningVehicle}>
                            CONFIRMER
                        </Button>
                    </Modal.Footer>
                </Modal>
                <SimpleDialog isOpen={this.state.isTransportFlowFormViewOpened}
                    onClose={this.handleCloseTransportFlowForm}
                    dialogTitleComponent={<TransportFlowHeaderComponent mode={this.state.flowDialogMode} flowBusinessId={this.state.transportFlowBusinessId} />}
                    closeIcon={true}
                    classNameVal="transport-flow-dialog"
                    headerBorder={true}
                    component={
                        <TransportFlowForm logisticsUnits={this.props.logisticsUnits}
                            chosenLogisticsUnitIds={this.props.logisticsUnitIds}
                            onClose={this.handleCloseTransportFlowForm}
                            onCloseAfterValidation={this.handleCloseTransportFlowFormAfterValidation}
                            mode={this.state.flowDialogMode}
                            transportFlowId={this.state.flowDialogTransportFlowId}
                            flowBusinessId={this.state.transportFlowBusinessId}
                        />
                    }
                />
                <SimpleDialog isOpen={this.state.isSmsNotificationDialogOpened}
                    onClose={this.handleCloseSmsNotificationsDialog}
                    dialogTitle="Envoi sms aux chauffeurs internes et externes"
                    closeIcon={true}
                    classNameVal="sms-notifications-dialog"
                    headerBorder={true}
                    component={
                        <SmsNotificationsDialogComponent
                            resources={resourcesWithDriversAndMobilePhoneNumber}
                            planningDate={this.state.date}
                            userDisplayName={this.props.userDisplayName}
                            userPhoneNumber={this.props.userPhoneNumber}
                            smsNotificationMaxContentLength={smsNotificationMaxContentLength}
                            sendSmsNotifications={this.sendSmsNotifications}
                        />
                    }
                />
                <Modal show={this.state.isSummaryOfSentSmsMessagesModalOpened} onHide={this.handleHideSummaryOfSentSmsMessagesModal} className='mt-5'
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <Box display="flex" alignItems='center' flexDirection="column">
                            <Box p={1}>
                                <ScaleLoaderComponent loading={this.state.sendingSmsLoading} />
                            </Box>
                            <div>
                                Envoi des SMS en cours:
                            </div>
                            <div>
                                <p>{`${this.state.numberOfSuccessfullySendingSms} message(s) ont bien été envoyés.`}</p>
                                <p>{`${this.state.numberOfFailedSendingSms} message(s) n'ont pas été envoyés.`}</p>
                            </div>
                        </Box>
                    </Modal.Body>
                </Modal>
                <SimpleDialog isOpen={this.state.isDriverSmsNotificationsDialogOpened}
                    onClose={this.handleCloseDriverSmsNotificationsDialog}
                    dialogTitle={`SMS envoyé(s) au chauffeur : ${this.state.selectedDriverName}`}
                    closeIcon={true}
                    classNameVal="driver-sms-notifications-dialog"
                    headerBorder={true}
                    component={
                        <DriverSmsNotificationsDialogComponent
                            driverPhoneNumberIsMobile={this.state.driverSmsPhoneNumberIsMobile}
                            driverSmsNotifications={this.state.driverSmsNotifications}
                            sendDriverFreeSmsRequestArgs={this.state.sendDriverFreeSmsRequestArgs}
                            smsNotificationMaxContentLength={smsNotificationMaxContentLength}
                            getDriverSmsNotifications={this.getDriverSmsNotifications}
                        />
                    }
                />
                <SimpleDialog isOpen={this.state.isCancelPlanningVehicleDialogOpened}
                    onClose={this.handleCloseCancelPlanningVehicleDialog}
                    dialogTitle="Préciser le motif d'annulation"
                    classNameVal="cancel-planning-vehicle-dialog"
                    headerBorder={true}
                    component={
                        <CancelPlanningVehicleDialogComponent
                            handleCloseCancelPlanningVehicleDialog={this.handleCloseCancelPlanningVehicleDialog}
                            handleSaveCancelPlanningVehicleDialog={this.handleSaveCancelPlanningVehicleDialog}
                        />
                    }
                />
                <SimpleDialog isOpen={this.state.isCancelDeliveryTripsDialogOpened}
                    onClose={this.handleCloseCancelDeliveryTripDialog}
                    dialogTitle="Préciser le motif d'annulation"
                    classNameVal="cancel-planning-vehicle-dialog"
                    headerBorder={true}
                    component={
                        <CancelDeliveryTripDialogComponent
                            handleCloseCancelDeliveryTripDialog={this.handleCloseCancelDeliveryTripDialog}
                            handleSaveCancelDeliveryTripDialog={this.handleSaveCancelDeliveryTripDialog}
                        />
                    }
                />
                <SimpleDialog isOpen={this.state.isAttachZephyrFlowManuallyOpened}
                    onClose={this.handleCloseAttachZephyrFlowManuallyDialog}
                    closeIcon={true}
                    dialogTitle="Rattacher un retour Zephyr manuellement"
                    classNameVal="attach-zephyr-flow-manually"
                    headerBorder={true}
                    component={
                        this.state.deliveryTripsSelected[0] &&
                        <AttachZephyrFlowManuallyComponent
                            licencePlate={this.state.deliveryTripsSelected[0].licencePlate}
                            onClose={this.handleCloseAttachZephyrFlowManuallyDialog}
                            businessId={this.state.deliveryTripsSelected[0].businessId}
                            refresh={() => this.handleRefreshAfterAttachZephyrFlow(this.state)}
                        />
                    }
                />
                {this.state.isConfirmValidationExistingSpecificOrNegotiatedPriceDialogOpened &&
                    <Dialog
                        open={this.state.isConfirmValidationExistingSpecificOrNegotiatedPriceDialogOpened}
                        onClose={() => this.handleClickCloseConfirmValidationExistingSpecificOrNegotiatedPrice()}
                        aria-describedby="alert-dialog-description"
                        className="confirm-validation-dialog"
                    >
                        <DialogTitle>ATTENTION</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Au moins un tour sélectionné est déjà associé à un tarif achat négocié ou spécifique pour lequel il existe au moins une surcharge dans l'écran Achats. En cas de confirmation, ces surcharges ne seront plus prises en compte.
                                <br />
                                Confirmez-vous l'action ?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleClickCloseConfirmValidationExistingSpecificOrNegotiatedPrice()} color="primary">
                                ANNULER
                            </Button>
                            <Button onClick={this.handleConfirmValidationExistingSpecificOrNegotiatedPrice} color="primary">
                                CONFIRMER
                            </Button>
                        </DialogActions>
                    </Dialog>}
            </>
        );
    }
}